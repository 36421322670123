export const CHAT_EVENT = {
    WIDGET_ADDED: "widget_added",
    WIDGET_COUNT_CHANGED: "widget_count_changed",
    CHAT_MESSAGE: "chat_message",
    FOCUS_WIDGET: "focus_widget",
    MINIMIZE_WIDGETS: "minimize_widgets",
    OPEN_EXIT_INTENT_DIALOG: "open_exit_intent_dialog",
    OPEN_TEXT_INPUT: "open_text_input",
    SET_MESSAGE_TEXT: "set_message_text",
    DICTATION_EVENT: "dictation_event",
    OPEN_ONBOARDING_DIALOG: "open_onboarding_dialog",
    OPEN_MIC_TEST_DIALOG: "open_mic_test_dialog",
    OPEN_SESSION_SUMMARY: "open_session_summary",
    MARK_ONBOARDING_TASK: "mark_onboarding_task",
    LEAVING_CHAT: "leaving_chat",
    WRAP_UP_CHAT: "wrap_up_chat",
    OPEN_THOUGHT_PROCESS_DIALOG: "open_thought_process_dialog",
    FOCUS_CHAT_MESSAGE: "focus_chat_message",
    FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK: "focus_role_play_message_feedback",
    EXPAND_SIDEBAR_WIDGET: "expand_sidebar_widget",
    INFERENCE_MADE: "inference_made",
    OPEN_CHAT_ACTION_ITEMS_EMAIL_DIALOG: "open_chat_action_item_email_dialog",
};
