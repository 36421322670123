<template>
    <svg viewBox="0 0 1884 1836" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_112)">
            <ellipse cx="255.99" cy="294.199" rx="255.99" ry="294.199" transform="matrix(0.833212 -0.552954 0.553298 0.832984 699.853 632.102)" fill="#EAEE26" fill-opacity="0.9" />
        </g>
        <g filter="url(#filter1_f_1002_112)">
            <ellipse
                cx="356.249"
                cy="341.771"
                rx="356.249"
                ry="341.771"
                transform="matrix(-0.365893 0.930657 -0.930768 -0.36561 1307.92 598.91)"
                :fill="`url(#paint0_linear_1002_112-${uniqueId})`"
                fill-opacity="0.8"
            />
        </g>
        <g filter="url(#filter2_f_1002_112)">
            <ellipse cx="317.544" cy="341.79" rx="317.544" ry="341.79" transform="matrix(0.0801423 0.996783 -0.996789 0.0800711 1257.18 573.9)" fill="#F3499B" fill-opacity="0.8" />
        </g>
        <defs>
            <filter id="filter0_f_1002_112" x="369.505" y="14.466" width="1412.84" height="1442.3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="219.047" result="effect1_foregroundBlur_1002_112" />
            </filter>
            <filter id="filter1_f_1002_112" x="77.4944" y="12.9992" width="1563.93" height="1585" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="219.047" result="effect1_foregroundBlur_1002_112" />
            </filter>
            <filter id="filter2_f_1002_112" x="0.28125" y="0.0678711" width="1883.32" height="1835.44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1002_112" />
            </filter>
            <linearGradient :id="`paint0_linear_1002_112-${uniqueId}`" x1="440.153" y1="137.261" x2="201.775" y2="632.99" gradientUnits="userSpaceOnUse">
                <stop offset="0.175832" stop-color="#FF5C00" stop-opacity="0" />
                <stop offset="0.921875" stop-color="#FF5C00" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
