<template>
    <div class="flex flex-col mt-6 items-center h-screen gap-6 antialiased">
        <div class="max-w-36">
            <OrganizationLogo />
        </div>
        <h3 class="font-semibold text-2xl tracking-tighter">{{ errorType }}</h3>
        <div class="flex flex-col items-center text-center rounded-xl py-10 px-6 max-w-80 sm:max-w-96 gap-6 border-2 border-[#F5F5F5] shadow-lg bg-white">
            <div class="w-32 h-32">
                <CoachingModeMarble />
            </div>
            <h3 class="font-semibold text-2xl tracking-tighter">Something went wrong</h3>
            <p class="text-base font-medium text-[#8C8C8C] tracking-tight">{{ errorMessage }}</p>
            <BaseButton class="w-4/5" theme="primary" @click="openUrl(homeUrl)">Go to Home</BaseButton>
        </div>
        <span>
            <PrivacyDialog />
        </span>
    </div>
    <Bubbles direction="bottom" direction-md="top" />
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import Bubbles from "~vue/components/Bubbles.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import OrganizationLogo from "~vue/components/OrganizationLogo.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import { isInIFrame, openUrl } from "~vue/utils";
import { computed } from "vue";

defineProps({
    errorType: { type: String, default: "Server Error" },
    errorMessage: { type: String, default: "Sorry! I ran into a server error and need to reboot. Please try again" },
});

const homeUrl = computed(() => {
    return isInIFrame() ? "/?client=teams" : "/";
});
</script>
