<template>
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.5 1H1.75V19.75H20.5V21H0.5V1ZM19.0208 4.89128C19.2879 5.10986 19.3273 5.50362 19.1087 5.77077L13.4837 12.6458C13.3719 12.7824 13.2075 12.8654 13.0311 12.8742C12.8548 12.883 12.6829 12.8168 12.5581 12.6919L9.32526 9.45914L4.75546 15.7426C4.55244 16.0218 4.16155 16.0835 3.88239 15.8805C3.60323 15.6774 3.54152 15.2866 3.74454 15.0074L8.74454 8.13239C8.85205 7.98457 9.01888 7.89121 9.20111 7.87691C9.38334 7.86262 9.56269 7.92881 9.69194 8.05806L12.9536 11.3197L18.1413 4.97923C18.3599 4.71207 18.7536 4.6727 19.0208 4.89128Z"
            fill="#262626"
            stroke="#262626"
            stroke-width="0.666667"
        />
    </svg>
</template>
