<template>
    <dialog v-show="isOpen" ref="dialogElement" class="thought-process-dialog" @cancel="close">
        <div class="overflow-hidden grow flex flex-col gap-6 md:gap-10 items-center">
            <div class="overflow-hidden flex flex-col gap-6 items-center">
                <div class="shrink-0">
                    <CoachingModeMarble :is-loading="true" size="big" />
                </div>

                <div class="shrink-0 text-2xl font-semibold text-[#262626] tracking-[-0.96px] text-center w-52 mx-auto">A peek into my thought process</div>
                <div v-if="hasError" class="shrink-0 tracking-[-0.64px] text-base font-medium text-[#8C8C8C]">
                    Sorry, I'm having trouble providing an explanation. Please try again later.
                </div>
                <div v-else-if="isLoading" class="shrink-0 flex justify-center">
                    <LoadingSpinner class="h-8 text-[#2C2C2C]" />
                </div>
                <div
                    v-else-if="!!explanation"
                    class="grow overflow-y-auto flex flex-col gap-4 *:whitespace-pre-line *:text-base *:font-medium *:text-[#8C8C8C] *:leading-normal *:tracking-[-0.64px]"
                    v-html="explanation"
                ></div>
            </div>
            <div class="shrink-0 inline-block mx-auto">
                <button
                    type="button"
                    class="transition-colors rounded-[20px] px-9 py-3 leading-normal font-semibold tracking-tighter text-base w-40 ring-2 ring-inset bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2]"
                    @click="close"
                >
                    Dismiss
                </button>
            </div>
        </div>
    </dialog>
</template>

<script setup>
import { ACTION, lineIsAction } from "~vue/chatActions";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { CHAT_EVENT } from "~vue/events";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, defineProps, inject, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

const props = defineProps({
    messages: Array([]),
});

const { emitter, $sendEvent } = inject("globalProperties");

const messageId = ref(null);
const isLoading = ref(false);
const hasError = ref(false);
const dialogElement = useTemplateRef("dialogElement");
const isOpen = ref(false);

watch(isOpen, (value) => {
    if (value) {
        dialogElement.value.showModal();
    } else {
        dialogElement.value.close();
    }
});

function open(msgId) {
    messageId.value = msgId;
    isOpen.value = true;
}

function close() {
    messageId.value = null;
    isOpen.value = false;
}

onMounted(() => {
    emitter.on(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, open);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, open);
});

const message = computed(() => {
    if (!messageId.value) {
        return null;
    }
    const result = props.messages.find((m) => m.chat_message_id === messageId.value);
    return result;
});

const explanation = computed(() => {
    if (!message.value) {
        return null;
    }
    const explanationLine = message.value.lines.find((line) => lineIsAction(line) && line.action_name === ACTION.LLM_EXPLANATION);
    if (!explanationLine) {
        return null;
    }
    const content = explanationLine.action_params?.content ?? "";
    return DOMPurify.sanitize(marked.parse(content, { headerIds: false, mangle: false }));
});

watch(message, (newMessage) => {
    if (!newMessage) {
        return;
    }
    const explanationLine = newMessage.lines.find((line) => lineIsAction(line) && line.action_name === ACTION.LLM_EXPLANATION);
    if (explanationLine) {
        isLoading.value = false;
    } else {
        hasError.value = false;
        isLoading.value = true;
        const payload = {
            chat_message_id: newMessage.chat_message_id,
        };
        $sendEvent("explain_llm_response", payload).catch((error) => {
            console.error("Failed to send explain_llm_response event", error);
            hasError.value = true;
        });
    }
});
</script>

<style scoped>
.thought-process-dialog {
    @apply top-auto bottom-0 md:top-0 rounded-tl-3xl md:max-h-[75vh] rounded-tr-3xl md:rounded-3xl bg-white p-6 md:p-10 mx-auto w-full max-w-full md:max-w-screen-md w-full flex flex-col items-center;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.thought-process-dialog::backdrop {
    background: rgba(23, 55, 100, 0.4);
    backdrop-filter: blur(4px);
}
</style>
