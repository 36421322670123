import { getCookie } from "../../utils";

/**
 * Our user interaction logging function that curently sends data to amplitude.
 *
 * @param {string} eventType name of the event being logged
 * @param {Object} data any additional data to be passed along with the event logging
 * @param {string} eventSlug slug appended to events endpoint
 */
export const logUserInteraction = (eventType, extraData, eventSlug) => {
    const logUrl = !!eventSlug ? `/events/ux/${eventSlug}` : "/events/ux/";
    const data = {
        event_type: eventType,
        extra_data: extraData,
        path: window.location.pathname,
    };
    return fetch(logUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify(data),
    });
};

/**
 * Error logging function for the UI, current logs to Sentry.
 *
 * @param {Error} error error object that needs to be logged
 */
export const logError = (error) => {
    if (window?.Sentry) {
        window.Sentry.captureException(error);
    }
};
