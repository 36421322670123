<template>
    <BaseNotification v-if="!data.action_state || !data.action_state.submitted" theme="primary" @dismiss="handleDismiss">
        <template #content>
            <div class="overflow-hidden">
                <p class="text-base font-semibold tracking-tight mb-3 text-[#262626]">Let's chat about...</p>
                <div class="grid grid-flow-row md:grid-flow-col gap-3 overflow-y-auto relative">
                    <template v-for="(scenario, idx) in scenarios" :key="page * 4 + idx">
                        <BaseActionCard :show-arrow="false" class="border-2 border-[#E8E8E8] w-full min-w-64" @click="chooseScenario(scenario)">
                            <div class="grow flex flex-col gap-4 justify-between h-full">
                                <span v-if="scenario.estimated_time" class="shrink-0">
                                    <span class="text-xs font-semibold text-[#8C8C8C]">{{ scenario.estimated_time }} min</span>
                                </span>
                                <div class="flex flex-col gap-1 grow">
                                    <div class="text-base font-semibold tracking-tight text-[#262626]">
                                        {{ scenario.title }}
                                    </div>
                                    <p v-if="scenario.description" class="text-sm text-[#262626]">{{ scenario.description }}</p>
                                </div>
                                <div>
                                    <span v-if="scenario.is_user_topic && scenario.created_at" class="shrink-0">
                                        <span class="rounded-[3px] uppercase text-xs p-1 px-2 font-semibold bg-valence-light-blue-50 mr-2">
                                            Saved on {{ formatDate(scenario.created_at) }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </BaseActionCard>
                    </template>
                </div>
            </div>
        </template>
    </BaseNotification>
</template>

<script setup>
import BaseActionCard from "~vue/components/BaseActionCard.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, onMounted } from "vue";

import BaseNotification from "./components/BaseNotification.vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const { data, messageId, lineIdx } = defineProps({
    data: { type: Object, default: () => ({}) },
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
});

const scenarios = computed(() => data.action_params.scenarios);

onMounted(() => {
    logUserInteraction("scenario_presented", { chat_message_id: messageId }, coachingSessionId);
});

const chooseScenario = (scenario) => {
    emitter.emit("choose_scenario", {
        answer: scenario.title,
        script: scenario.script,
        start_url: scenario.start_url,
        message_id: messageId,
        lineIdx: lineIdx,
        action_state: {
            dismissed: false,
            submitted: true,
        },
    });
    logUserInteraction("scenario_chosen", {}, coachingSessionId);
};

const formatDate = (date) => {
    if (!date) {
        return "";
    }
    return DateTime.fromISO(date).toLocaleString({ month: "short", day: "numeric" });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
