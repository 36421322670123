<template>
    <dialog id="action-items-email-dialog" ref="dialogElement" class="bg-white md:p-8 p-4 rounded-xl">
        <div class="flex flex-col gap-4 text-center w-full md:w-[600px]">
            <div class="text-xl font-bold">Your action plan from today&apos;s session</div>
            <textarea
                id="action-items-email-summary"
                v-model="editedSummary"
                class="border rounded-sm border-gray-200 resize-none text-sm p-2 w-full whitespace-pre-line"
                rows="10"
                name="summary"
                placeholder="What do you think would make our conversations more effective?"
                autofocus
                required
                autocomplete="off"
            ></textarea>
            <div v-if="error === ERRORS.VALIDATION_ERROR" class="text-xs text-red-400">Summary cannot be empty.</div>
            <div class="mt-4">
                <BaseButton theme="primary" autofocus @click="send"> Send Summary</BaseButton>
            </div>
            <button type="button" class="text-xs underline text-valence-grey-800/75 hover:text-valence-grey-800/50" @click="dismiss">Dismiss</button>
        </div>
    </dialog>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, ref, useTemplateRef } from "vue";

import BaseButton from "./components/BaseButton.vue";

const ERRORS = {
    VALIDATION_ERROR: "validation_error",
};

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const { summary, messageId, lineIdx, messageActionLine } = defineProps({
    summary: { type: String, default: "" },
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
    messageActionLine: { type: Object, required: true },
});
const emit = defineEmits(["complete"]);

const dialogElement = useTemplateRef("dialogElement");
const editedSummary = ref(summary);
const error = ref(null);

const send = () => {
    if (editedSummary.value.length === 0) {
        error.value = ERRORS.VALIDATION_ERROR;
        return;
    }
    const action_data = {
        subaction: "send_email_summary",
        email_summary: editedSummary.value,
        message_id: messageId,
        lineIdx: lineIdx,
        action_params: {
            ...messageActionLine.action_params,
            email_sent: true,
        },
        action_state: {
            submitted: false,
        },
    };
    emitter.emit("action_items", action_data);
    logUserInteraction("action_items_email_sent", {}, coachingSessionId);
    emit("complete");
};

const dismiss = () => {
    error.value = null;
    dialogElement.value.close();
};
const open = () => dialogElement.value?.showModal();

defineExpose({ open, dismiss });
</script>

<style>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
