=
<template>
    <BaseNotification v-bind="$attrs" theme="primary" @dismiss="handleDismiss">
        <template #content>
            <div class="flex gap-4 items-center">
                <div v-if="line" class="w-5 h-5 flex items-center">
                    <i v-if="line.action_name === ACTION.TIP" class="bi bi-lightning-charge"></i>
                    <i v-else-if="line.action_name === ACTION.SUGGESTED_TOPICS" class="bi bi-star"></i>
                    <i v-else-if="line.action_name === ACTION.ACTION_ITEMS" class="bi bi-check2-square"></i>
                </div>
                <MarkdownText v-if="line.action_name === ACTION.TIP" :text="line.action_params?.content" />
                <p v-else class="font-medium">
                    <template v-if="line.action_name === ACTION.SUGGESTED_TOPICS">
                        I&apos;ve come up with a coaching topic for next session.
                        <button type="button" class="inline text-left hover:text-[#555BA2]/75 underline" @click="focusWidget">Click here to schedule time.</button>
                    </template>
                    <template v-if="line.action_name === ACTION.ACTION_ITEMS">
                        I&apos;ve compiled action items for you.
                        <button type="button" class="inline text-left underline hover:text-[#555BA2]/75" @click="emailDialog.open">Click here to send yourself a summary.</button>
                    </template>
                </p>
            </div>
        </template>
    </BaseNotification>
    <ChatActionItemsEmailDialog
        v-if="!line.action_params.email_sent && line.action_name === ACTION.ACTION_ITEMS"
        ref="emailDialog"
        :summary="line.action_params.email_summary"
        :message-id="messageId"
        :line-idx="lineIdx"
        :message-action-line="line"
    />
</template>

<script setup>
import ChatActionItemsEmailDialog from "~vue/ChatActionItemsEmailDialog.vue";
import { ACTION, dismissAction } from "~vue/chatActions.js";
import { CHAT_EVENT } from "~vue/events.js";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { inject, onMounted, useTemplateRef } from "vue";

import BaseNotification from "./components/BaseNotification.vue";
import MarkdownText from "./MarkdownText.vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const { messageId, lineIdx, line } = defineProps({
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
    line: { type: Object, required: true },
});

const emailDialog = useTemplateRef("emailDialog");

onMounted(() => {
    if (line?.action_name === ACTION.TIP) {
        logUserInteraction(
            "tip_presented",
            {
                chat_message_id: messageId,
                tip_type: line.action_params?.tip_type,
                content: line.action_params?.content,
            },
            coachingSessionId,
        );
    }
});

const focusWidget = () => {
    emitter.emit(CHAT_EVENT.FOCUS_WIDGET, { messageId, lineIdx, actionName: line.action_name });
};

const handleDismiss = () => {
    dismissAction({ emitter, messageId, line, lineIdx, eventName: line.action_name });
};
</script>
