<template>
    <div class="flex flex-col items-center gap-3">
        <div class="overflow-hidden w-36 h-2 bg-white rounded-full mx-auto">
            <div class="progress h-2 rounded-full bg-[#6682FF]" :style="`width: ${percentage}%`"></div>
        </div>
        <Transition name="fade" mode="out-in" appear>
            <div v-if="currentCheckpoint === 0" class="hint">Can you tell me more?</div>
            <div v-else-if="currentCheckpoint === 1" class="hint">Great start! Any specific examples?</div>
            <div v-else-if="currentCheckpoint === 2" class="hint">I&apos;m getting a clearer picture. Anything else?</div>
            <div v-else-if="currentCheckpoint === 3" class="hint">Excellent detail! This helps me understand deeply.</div>
            <div v-else class="h-6"></div>
        </Transition>
    </div>
</template>

<script setup>
import { onUnmounted, ref, watch } from "vue";

const props = defineProps({
    running: Boolean,
});

const CHECKPOINTS = [3000, 10000, 20000, 60000];
const percentage = ref(1);
const startTime = ref(null);
const pauseStartTime = ref(null);
const totalPauseTime = ref(0);
const currentCheckpoint = ref(-1);
let animationFrame = null;

const calculate = (totalTime) => {
    const elapsedTime = totalTime - totalPauseTime.value;
    let activeCheckpoint = -1;

    if (elapsedTime > CHECKPOINTS[0] && elapsedTime < CHECKPOINTS[1]) {
        activeCheckpoint = 0;
    } else if (elapsedTime > CHECKPOINTS[1] && elapsedTime < CHECKPOINTS[2]) {
        activeCheckpoint = 1;
    } else if (elapsedTime > CHECKPOINTS[2] && elapsedTime < CHECKPOINTS[3]) {
        activeCheckpoint = 2;
    } else if (elapsedTime > CHECKPOINTS[3]) {
        activeCheckpoint = 3;
    }

    if (activeCheckpoint !== -1) {
        currentCheckpoint.value = activeCheckpoint;
    }
    /*
     * Animate the context meter progress using an acceleration curve. The progress acceleration decreases
     * as the different checkpoints are crossed. 0-3 seconds fills 15% of the bar,
     * 3-10 seconds fill to 35%, 10-20 to 50%, and 20-60 to 100%.
     */
    let progress;
    if (elapsedTime <= CHECKPOINTS[0]) {
        progress = (elapsedTime / CHECKPOINTS[0]) ** 2 * 15;
    } else if (elapsedTime <= CHECKPOINTS[1]) {
        const t = (elapsedTime - CHECKPOINTS[0]) / (CHECKPOINTS[1] - CHECKPOINTS[0]);
        progress = 15 + t ** 1.5 * 20;
    } else if (elapsedTime <= CHECKPOINTS[2]) {
        const t = (elapsedTime - CHECKPOINTS[1]) / (CHECKPOINTS[2] - CHECKPOINTS[1]);
        progress = 35 + t ** 1.2 * 15;
    } else if (elapsedTime <= CHECKPOINTS[3]) {
        const t = (elapsedTime - CHECKPOINTS[2]) / (CHECKPOINTS[3] - CHECKPOINTS[2]);
        progress = 50 + t ** 0.5 * 50;
    } else {
        progress = 100;
    }
    return Math.min(progress, 100);
};

/*
 * Defer the calculation loop to the browser using
 * requestAnimationFrame.
 */
const updateProgress = () => {
    const currentTime = performance.now();
    const elapsedTime = currentTime - startTime.value;

    percentage.value = calculate(elapsedTime);

    if (percentage.value < 100) {
        animationFrame = requestAnimationFrame(updateProgress);
    }
};

watch(
    () => props.running,
    (value, previous) => {
        if (value && !previous && !startTime.value) {
            startTime.value = performance.now();
        }

        if (value) {
            if (pauseStartTime.value) {
                const pauseDuration = performance.now() - pauseStartTime.value;
                totalPauseTime.value += pauseDuration;
                pauseStartTime.value = null;
            }
            updateProgress();
        } else {
            cancelAnimationFrame(animationFrame);
            pauseStartTime.value = performance.now();
        }
    },
);

onUnmounted(() => {
    if (animationFrame) {
        cancelAnimationFrame(animationFrame);
    }
});
</script>

<style scoped>
.progress {
    transition: width 0.3s ease;
}

.hint {
    @apply font-medium text-base text-[#555BA2];
}
</style>
