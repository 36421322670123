<template>
    <a :href="redirectUrl" @click.prevent="openUrl(redirectUrl)">
        <img v-if="$organizationDetails.value && $organizationDetails.value.logo_url" :src="$organizationDetails.value.logo_url" :alt="`${$organizationDetails.value.name} logo`" />
        <img v-else :src="Logo" alt="Valence logo" />
    </a>
</template>

<script setup>
import Logo from "~img/logo.png";
import { isInIFrame, openUrl } from "~vue/utils";
import { computed } from "vue";

const { url } = defineProps({
    url: { type: String, default: "/" },
});
const redirectUrl = computed(() => {
    return isInIFrame() ? `${url}?client=teams` : `${url}`;
});
</script>
