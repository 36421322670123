<template>
    <BaseNotification v-if="isShowing" :text="message" theme="secondary" @dismiss="isShowing = false" />
</template>

<script setup>
import BaseNotification from "~vue/components/BaseNotification.vue";
import { ref } from "vue";
const { message } = defineProps({
    message: {
        type: String,
        default: "",
    },
});

const isShowing = ref(!!message);
</script>
