export const ACTION = {
    CUSTOMIZABLE_TEXT: "customizable_text",
    FOLLOW_UP: "followup",
    FILE_UPLOAD: "file_upload",
    RATING: "rating",
    ONBOARDING_SUMMARY: "osummary",
    SUGGESTED_TOPICS: "suggested_topics",
    PERSONALIZED_ADVICE: "personalized_advice",
    PERSONALIZED_RATING: "personalized_rating",
    ROLE_PLAY_ADJUST: "role_play_adjust",
    INTERNAL_THINKING: "internal_thinking",
    ACTION_ITEMS: "action_items",
    VALUES_INSIGHT: "values_insight",
    TIP: "tip",
    LLM_EXPLANATION: "llm_explanation",
    SCENARIOS: "scenarios",
    PROFILE_QUESTION: "profile_question",
    ANSWERS: "answers",
    INFERRED_PROFILE_ANSWER: "inferred_profile_answer",
    FOCUS_AREA_PICKER: "focus_area_picker",
    ROLE_PLAY_MONITOR: "role_play_monitor",
};

export const SIDEBAR_WIDGETS = [
    ACTION.CUSTOMIZABLE_TEXT,
    ACTION.FOLLOW_UP,
    ACTION.ONBOARDING_SUMMARY,
    ACTION.SUGGESTED_TOPICS,
    ACTION.PERSONALIZED_RATING,
    ACTION.INTERNAL_THINKING,
    ACTION.ACTION_ITEMS,
    ACTION.VALUES_INSIGHT,
    ACTION.INFERRED_PROFILE_ANSWER,
];

export const CHAT_WIDGETS = [
    ACTION.ROLE_PLAY_ADJUST,
    ACTION.CUSTOMIZABLE_TEXT,
    ACTION.FOLLOW_UP,
    ACTION.ONBOARDING_SUMMARY,
    ACTION.SUGGESTED_TOPICS,
    ACTION.PERSONALIZED_RATING,
    ACTION.INTERNAL_THINKING,
    ACTION.ACTION_ITEMS,
    ACTION.VALUES_INSIGHT,
];

// these are actions that ChatNotificationMessage supports
const CHAT_NOTIFICATION_MESSAGE_SUPPORTED_ACTIONS = [ACTION.ACTION_ITEMS, ACTION.SUGGESTED_TOPICS, ACTION.TIP];

// these are actions that get "buffered", meaning the action will come back on the user message
// but it gets move to the next assistant message and placed below it
export const LOOKBACK_ACTIONS = [ACTION.ACTION_ITEMS];

export const lineIsAction = (line) => {
    return line.type === "action";
};

export const lineIsWidget = (line, chatAction = false) => {
    const widgets = chatAction ? CHAT_WIDGETS : SIDEBAR_WIDGETS;
    return lineIsAction(line) && widgets.includes(line.action_name);
};

export const lineHasWidget = (line, widgetName, chatAction = false) => {
    return lineIsWidget(line, chatAction) && line.action_name === widgetName;
};

export const pluckAction = (chatMessages, actionName, chatAction = false) => {
    for (let message of chatMessages) {
        for (let line of message.lines) {
            if (lineHasWidget(line, actionName, chatAction)) {
                return {
                    line,
                    lineIdx: message.lines.indexOf(line),
                    message,
                };
            }
        }
    }

    return null;
};

export const actionExists = (chatMessages, actionName) => {
    return chatMessages.some((message) => message.lines.some((l) => lineHasWidget(l, actionName)));
};

export const messageHasActions = (message) => message.lines.some((l) => lineIsAction(l));

export const lineHasScenarioActionParams = (line) => lineIsAction(line) && line?.action_params?.scenarios?.length > 0;

const isRolePlayMonitor = (line) => line.action_name === ACTION.ROLE_PLAY_MONITOR;

export const isRolePlayMessage = (message) => {
    return message.lines.some((l) => isRolePlayMonitor(l));
};

export const getRolePlayMonitorParams = (message) => {
    const action = message.lines.find((l) => isRolePlayMonitor(l));
    return action ? action.action_params : null;
};

export const hasRolePlayMonitor = (messages) => {
    return messages.some((m) => isRolePlayMessage(m));
};

export function lineHasNotification(line) {
    return lineIsAction(line) && CHAT_NOTIFICATION_MESSAGE_SUPPORTED_ACTIONS.includes(line.action_name);
}

export const lineIsDismissed = (line) => {
    if (!lineIsAction(line)) {
        return false;
    }
    if (line.action_name === ACTION.ACTION_ITEMS) {
        return line.action_state.dismissed || line.action_params.email_sent;
    }
    return line.action_state.dismissed;
};

export const isLookbackAction = (line) => lineIsAction(line) && LOOKBACK_ACTIONS.includes(line.action_name);
export const isActionableLookbackAction = (line) => isLookbackAction(line) && !lineIsDismissed(line);

export const dismissAction = ({ emitter, eventName, line, messageId, lineIdx }) => {
    if (line) {
        const action_data = {
            message_id: messageId,
            lineIdx: lineIdx,
            action_params: line.action_params,
            action_state: {
                dismissed: true,
            },
        };
        emitter.emit(eventName, action_data);
    }
};

/**
 * Gets the previous non hidden message from the messages array from the opposite role
 *
 * @param {Array} messages all messages that are in the array
 * @param {'user' | 'assistant'} messageId reference to the messageId you want to find the prev message of
 * @return {Object | null} the previous message from the opposite role or null if not found
 */
export const getPreviousMessage = (messages, messageId, role) => {
    const messageIdx = messages.findIndex((m) => m.id === messageId);
    if (messageIdx === -1) {
        return null;
    }
    return messages.slice(0, messageIdx).findLast((m) => m.role === role && !m.hidden);
};
