<template>
    <BaseNotification v-if="_showBanner" :text="notificationMessage" theme="info" @dismiss="dismiss" />
</template>

<script setup>
import { onMounted, ref } from "vue";

import BaseNotification from "./components/BaseNotification.vue";

const notificationMessage = ref("");
const _notificationName = ref("");
const _showBanner = ref(false);

const getGlobalNotification = async () => {
    const response = await fetch("/admin/global-notifications/", {
        method: "GET",
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error(`Error fetching GlobalNotifications: ${response.status}`);
    }

    return await response.json();
};

const dismiss = () => {
    _showBanner.value = false;
    localStorage.setItem(`globalNotificationDismissed ${_notificationName.value}`, true);
};

onMounted(async () => {
    const response = await getGlobalNotification();

    if (!response || response.length === 0) {
        _showBanner.value = false;
        return;
    }

    notificationMessage.value = response[0].message;
    _notificationName.value = response[0].name;
    _showBanner.value = !localStorage.getItem(`globalNotificationDismissed ${_notificationName.value}`);
});
</script>
