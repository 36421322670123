<template>
    <Transition name="fade">
        <div v-if="isShowing" class="relative z-50">
            <div class="absolute transform whitespace-nowrap px-6 py-2 rounded-full border-2 text-[#555BA2] bg-[#F7FAFF] border-[#555BA266]" :style="badgeStyles">
                <slot name="content">
                    <p v-if="text" class="text-base font-medium">{{ text }}</p>
                </slot>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed } from "vue";
const { text, direction, alignment, offsetX, offsetY } = defineProps({
    isShowing: { type: Boolean, default: true },
    text: { type: String, default: undefined },
    // currently supports top and bottom
    direction: { type: String, default: "top" },
    // currently supports start, center, and end
    alignment: { type: String, default: "center" },
    // pixels to offset from the direction
    offsetX: { type: Number, default: 0 },
    offsetY: { type: Number, default: 0 },
});

const badgeStyles = computed(() => {
    const styles = {};
    switch (direction) {
        case "top":
            styles.bottom = `${0 + offsetY}px`;
            break;
        case "bottom":
        default:
            styles.top = `${0 + offsetY}px`;
    }

    switch (alignment) {
        case "center":
            styles.left = "50%";
            styles.transform = "translateX(-50%)";
            break;
        case "start":
            styles.left = `${0 + offsetX}px`;
            styles.right = "none";
            break;
        case "end":
        default:
            styles.left = "none";
            styles.right = `${0 + offsetX}px`;
    }
    return styles;
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
