/**
 *
 * @param {string} name
 * @returns two character string with the first and last initials of the name, Jin Y Choi -> JC
 */
export const getFirstLastInitials = (name) => {
    if (!name) {
        return "";
    }
    const names = name.split(" ").filter((n) => n);
    if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }
    return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
};
