const DEFAULT_TRANSCRIPTION_RETRIES = 2;
const DEFAULT_TRANSCRIPTION_RETRY_DELAY_FACTOR_MS = 3000;
const DEFAULT_SLOW_TRANSCRIPTION_TIMEOUT_SECS = 10;

/**
 * Encodes an audio data blob to base64 for uploading to the transcription API.
 *
 * @param blob {Blob}
 *
 * @returns {Promise<string>}
 */
export const encodeAudio = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        const onLoad = () => {
            reader.removeEventListener("load", onLoad);
            reader.removeEventListener("error", onError);

            const base64Mp3 = reader.result;
            resolve(base64Mp3);
        };
        reader.addEventListener("load", onLoad);

        const onError = (err) => {
            reader.removeEventListener("load", onReaderLoad);
            reader.removeEventListener("error", onReaderError);

            console.error("Failed to encode audio blob", err);
            reject(err);
        };
        reader.addEventListener("error", onError);

        reader.readAsDataURL(blob);
    });
};

const delay = async (delayMs) => {
    return new Promise((resolve) => setTimeout(resolve, delayMs));
};

/**
 * Sends a request to the API to transcribe encoded audio data to text, with an automatic retry
 * mechanism and a callback for slow transcriptions.
 *
 * @param {Object} params
 * @param {string} params.encodedAudio Base-64 encoded MP3 audio content.
 * @param {function} params.sendEvent The injected `$sendEvent` function from the Vue context.
 * @param {number=} params.maxRetries The number of times to retry transcription if the first
 * attempt fails.  Defaults to 2.
 * @param {number=} params.retryDelayFactorMs The number of additional milliseconds to delay before
 * each retry.  Each try will add this number cumulatively to the next delay.  Defaults to 3000 ms.
 * @param {number=} params.slowTimeoutSecs The number of seconds to elapse on each transcription try
 * before the `onSlow` callback is called.  Defaults to 10 seconds.
 * @param {function=} params.onStart Optional callback that will be called at the start of each
 * transcription try.
 * @param {function=} params.onSlow Optional callback that will be called on each transcription try
 * whenever `slowTimeoutSecs` has elapsed and transcription hasn't finished yet.
 * @param {function=} params.onError Optional callback that will be called after each try in the
 * event of a transcription error.
 *
 * @returns {Promise<string>} The transcribed text.
 */
export const transcribeRecording = async ({ encodedAudio, sendEvent, ...options }) => {
    const maxRetries = options.maxRetries ?? DEFAULT_TRANSCRIPTION_RETRIES;
    const retryDelayFactorMs = options.retryDelayFactorMs ?? DEFAULT_TRANSCRIPTION_RETRY_DELAY_FACTOR_MS;
    const slowTimeoutSecs = options.slowTimeoutSecs ?? DEFAULT_SLOW_TRANSCRIPTION_TIMEOUT_SECS;

    let retries = 0;
    let slowTimeout = null;
    while (retries <= maxRetries) {
        // Each retry is delayed a couple of seconds depending on which retry we're at.  First one
        // is immediate, second is a few seconds later, etc.
        await delay(retries * retryDelayFactorMs);

        try {
            if (options.onStart) {
                options.onStart();
            }
            if (options.onSlow) {
                slowTimeout = setTimeout(options.onSlow, slowTimeoutSecs * 1000);
            }
            const data = await sendEvent("transcription_request", { audio_stream: encodedAudio });

            return data.payload.content;
        } catch (err) {
            if (options.onError) {
                options.onError(err);
            }
            if (err.error_code != "no_speech_detected") {
                if (retries < maxRetries) {
                    retries++;
                    continue;
                }
            }
            throw err;
        } finally {
            if (slowTimeout) {
                clearTimeout(slowTimeout);
                slowTimeout = null;
            }
        }
    }

    throw new Error("Transcription retries exhausted");
};
