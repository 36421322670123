<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path
                id="Vector"
                d="M9.53351 12.7798C9.53351 13.5189 8.4668 13.5189 8.4668 12.7798C8.4668 12.0415 9.53351 12.0415 9.53351 12.7798Z"
                fill="#8C8C8C"
                stroke="#8C8C8C"
                stroke-width="0.5"
            />
            <path
                id="Vector_2"
                d="M9.05118 11.3608C8.72584 11.3608 8.46249 11.0581 8.52181 10.7256C8.68849 9.79334 9.21651 8.98017 9.98454 8.5106C10.5352 8.17398 10.9686 7.63579 11.0913 6.9847C11.2479 6.15492 10.9546 5.3369 10.3446 4.82091C9.83254 4.3887 9.18584 4.23148 8.52313 4.379C7.6871 4.56533 7.05506 5.25035 6.90306 6.12447C6.83439 6.5179 6.86172 6.90507 6.98173 7.26456C7.06573 7.516 6.97706 7.79236 6.75705 7.92881C6.4737 8.10404 6.09436 7.97798 5.98436 7.65521C5.72302 6.88638 5.73635 6.02056 6.05236 5.22065C6.39371 4.35624 7.10641 3.68091 7.96186 3.39146C9.03591 3.02851 10.174 3.24877 11.018 3.9622C11.8973 4.70541 12.3387 5.86906 12.162 7.06382C12.0113 8.08547 11.36 8.94373 10.5079 9.47834C10.0272 9.77963 9.69323 10.2964 9.57789 10.8934C9.52589 11.1608 9.31454 11.3616 9.05187 11.3616L9.05118 11.3608Z"
                fill="#8C8C8C"
                stroke="#8C8C8C"
                stroke-width="0.5"
            />
            <path
                id="Vector_3"
                d="M9.00032 17C8.52363 17 8.07628 16.7825 7.77094 16.4029L6.24288 14.4982C6.14154 14.3715 5.9922 14.2987 5.83354 14.2987H4.73349C2.67534 14.2987 1 12.5581 1 10.4199V4.8788C0.999334 2.74054 2.67407 1 4.73281 1H13.2665C15.3247 1 17 2.74061 17 4.8788V10.4199C17 12.5582 15.3246 14.2987 13.2665 14.2987H12.1665C12.0078 14.2987 11.8584 14.3715 11.7565 14.4975L10.2297 16.4022C9.92437 16.7832 9.47633 17 9.00032 17ZM4.73279 2.10826C3.26206 2.10826 2.06601 3.35086 2.06601 4.87883V10.42C2.06601 11.9479 3.26206 13.1905 4.73279 13.1905H5.83284C6.30886 13.1905 6.75688 13.4087 7.06222 13.789L8.58961 15.693C8.72895 15.8662 8.90563 15.8918 8.99962 15.8918C9.09296 15.8918 9.2703 15.8662 9.40963 15.693L10.9364 13.789C11.2411 13.408 11.6897 13.1905 12.1664 13.1905H13.2665C14.7372 13.1905 15.9332 11.9479 15.9332 10.42V4.87883C15.9332 3.35086 14.7372 2.10826 13.2665 2.10826H4.73279Z"
                fill="#8C8C8C"
                stroke="#8C8C8C"
                stroke-width="0.5"
            />
        </g>
    </svg>
</template>
