import { useTimeoutFn } from "@vueuse/core";
import { ref } from "vue";

/**
 * a composable for setting showing state for a given duration then hiding
 *
 * @param {*} initialState - the initial state to show or hide
 * @param {*} duration - when show is triggered, how long to display for
 * @returns
 */
export const useTimedShow = ({ initialState = false, duration = 3000 } = {}) => {
    const isShowing = ref(initialState);
    const { start, stop } = useTimeoutFn(() => {
        isShowing.value = false;
    }, duration);

    const show = () => {
        isShowing.value = true;
        start();
    };

    const hide = () => {
        isShowing.value = false;
        stop();
    };

    return { isShowing, show, hide };
};
