<template>
    <div class="text-center">
        <div class="text-2xl max-w-sm text-balance font-semibold tracking-tighter">
            <span v-if="personalizeGreeting"> {{ personalizeGreeting }}, meet </span>
            <span v-else>Meet</span>
            Nadia, your <br />
            new thought partner.
        </div>
    </div>
    <form class="flex flex-col gap-5 md:gap-12 items-center" @submit.prevent="onSubmit">
        <div class="flex flex-col gap-2">
            <div class="form-control">
                <input v-model="userName" type="text" class="form-control-input" placeholder="Name" />
                <ul v-if="formFieldHasError('name')" class="error-list">
                    <template v-for="(err, i) in errors.form.name" :key="i">
                        <li v-text="err"></li>
                    </template>
                </ul>
            </div>
            <div class="form-control">
                <input v-model="userRole" type="text" class="form-control-input" placeholder="Role" />
                <ul v-if="formFieldHasError('job_title')" class="error-list">
                    <template v-for="(err, i) in errors.form.job_title" :key="i">
                        <li v-text="err"></li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="w-4/5">
            <button type="submit" class="button button-primary" data-cy="submit">Get to know Nadia</button>
        </div>
        <div class="gap-10 flex items-center justify-center">
            <div class="shrink-0">
                <PrivacyDialog />
            </div>
            <select v-model="userLanguage" class="form-control-select">
                <template v-for="opt in languageOptions" :key="opt[0]">
                    <option :value="opt[0]" :selected="opt[0] === userLanguage" v-text="opt[0]"></option>
                </template>
            </select>
        </div>
    </form>
</template>

<script setup>
import debounce from "lodash.debounce";
import { computed, ref, watch } from "vue";

import PrivacyDialog from "./PrivacyDialog.vue";

const props = defineProps({
    defaultLanguage: String,
    languageOptions: Array,
    errors: Object,
});

const emit = defineEmits(["submit"]);

const userName = ref("");
const userRole = ref("");
const userLanguage = ref(props.defaultLanguage);
const personalizeGreeting = ref("");

const hasErrors = computed(() => props.errors !== null && Object.keys(props.errors).length > 0);

watch(
    userName,
    debounce((value) => {
        if (value.length > 1) {
            personalizeGreeting.value = value;
        } else {
            personalizeGreeting.value = "";
        }
    }, 500),
);

function formFieldHasError(field) {
    return hasErrors.value && props.errors.form && Object.hasOwn(props.errors.form, field);
}

function onSubmit() {
    emit("submit", {
        name: userName.value,
        job_title: userRole.value,
        default_language: userLanguage.value,
    });
}
</script>

<style scoped>
.form-control {
    @apply flex flex-col gap-1;
}

.form-control-select {
    @apply bg-transparent shrink-0 inline-block w-auto text-base font-medium tracking-tighter hover:text-[#7C7C7C] text-[#8C8C8C];
}

.form-control-select option {
    @apply text-gray-700;
}

.form-control-input {
    @apply w-full p-3 leading-none outline-[#555BA2] rounded-xl border-2 tracking-tighter text-base font-semibold text-gray-600 border-[#E8E8E8];
}
</style>
