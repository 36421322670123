<template>
    <div class="shrink-0 px-4">
        <ChatRolePlayBehavior :value="currentRolePlayAdjustValue || rolePlayAdjustmentDefault" :options="rolePlayAdjustments" @selection="handleAdjustment" />
    </div>
    <div class="px-4">
        <HorizontalDivider />
    </div>
    <div class="grow flex flex-col max-h-full overflow-hidden">
        <div class="mb-1 font-bold px-4 shrink-0 text-gray-700">Feedback</div>
        <template v-if="reversedRolePlayMessages.length > 0">
            <ul class="grow overflow-y-auto flex flex-col gap-2 px-4">
                <template v-for="message in reversedRolePlayMessages" :key="message.chat_message_id">
                    <Transition name="fade">
                        <template v-if="feedbackForMessage(message.chat_message_id)">
                            <li :ref="(el) => refForLine(el, message.chat_message_id)" class="text-sm">
                                <button
                                    title="View message"
                                    class="text-left rounded-xl hover:opacity-95 hover:bg-gray-50/90 hover:shadow-sm hover:ease-in hover:duration-150 border py-4 pl-4 pr-2 shadow-black/5 border-gray-200 shadow-md ease-in transition-colors duration-500"
                                    :class="focusedFeedback === message.chat_message_id ? 'bg-valence-purple/10' : ''"
                                    type="button"
                                    @click="focusChatMessage(message.chat_message_id)"
                                >
                                    <div
                                        class="inline-block text-xs uppercase py-1 px-2 rounded mb-2"
                                        :class="sentimentForMessage(message.chat_message_id).className"
                                        v-text="sentimentForMessage(message.chat_message_id).text"
                                    ></div>
                                    <div>
                                        {{ feedbackForMessage(message.chat_message_id) }}
                                    </div>
                                </button>
                            </li>
                        </template>
                    </Transition>
                </template>
            </ul>
        </template>
        <template v-else>
            <p class="px-4 text-sm">I&apos;ll share insights into how you&apos;re doing as we go through the exercise.</p>
        </template>
    </div>
</template>

<script setup>
import { ACTION, getRolePlayMonitorParams, isRolePlayMessage, pluckAction } from "~vue/chatActions.js";
import ChatRolePlayBehavior from "~vue/components/ChatRolePlayBehavior.vue";
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import { CHAT_EVENT } from "~vue/events.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

const props = defineProps({
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustmentDefault: {
        type: String,
        default: () => "",
    },
    messages: {
        type: Array,
        default: () => [],
    },
    showWidgetList: {
        type: Boolean,
        default: true,
    },
});

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");
const actionRefs = ref(new Map());
const focusedFeedback = ref(null);

const reversedRolePlayMessages = computed(() => props.messages.filter((m) => isRolePlayMessage(m)).toReversed());
const rolePlayMessageMonitorProps = computed(() =>
    reversedRolePlayMessages.value.reduce((aggregator, current) => {
        const params = getRolePlayMonitorParams(current);
        return {
            ...aggregator,
            [current.chat_message_id]: params,
        };
    }, {}),
);

const lastRolePlayAdjustAction = computed(() => {
    return pluckAction(props.messages.toReversed(), ACTION.ROLE_PLAY_ADJUST, true);
});

const currentRolePlayAdjustValue = computed(() => {
    if (lastRolePlayAdjustAction.value) {
        return lastRolePlayAdjustAction.value.line.action_params.adjustment;
    }

    return undefined;
});

function refForLine(element, messageId) {
    if (element) {
        actionRefs.value.set(messageId, element);
    }
}

function focusChatMessage(chatMessageId) {
    emitter.emit(CHAT_EVENT.FOCUS_CHAT_MESSAGE, { chatMessageId });
}

function focusRolePlayFeedback(messageId) {
    const actionRef = actionRefs.value.get(messageId);
    if (actionRef) {
        actionRef.scrollIntoView({ behavior: "smooth" });
        focusedFeedback.value = messageId;
        setTimeout(() => {
            focusedFeedback.value = null;
        }, 1000);
    }
}

function sentimentForMessage(messageId) {
    const rolePlayMonitorProps = rolePlayMessageMonitorProps.value[messageId];

    if (rolePlayMonitorProps && rolePlayMonitorProps.sentiment) {
        switch (rolePlayMonitorProps.sentiment) {
            case "suggestion":
                return { text: "Suggested improvement", className: "bg-[#EDE0E0]" };
            case "praise":
                return { text: "Good job!", className: "bg-[#E5EDE0]" };
        }
    }

    return { text: null, className: "" };
}

function feedbackForMessage(messageId) {
    const rolePlayMonitorProps = rolePlayMessageMonitorProps.value[messageId];
    return rolePlayMonitorProps && rolePlayMonitorProps.feedback ? rolePlayMonitorProps.feedback : null;
}

// Creates a new role_play_adjust action in the
// last message. If it already exists, then it simply
// updates the adjustment.
function handleAdjustment(adjustment) {
    let lineIdx;
    const lastMessageId = props.messages[props.messages.length - 1].chat_message_id;

    if (lastRolePlayAdjustAction.value && lastRolePlayAdjustAction.value.message.chat_message_id === lastMessageId) {
        lineIdx = lastRolePlayAdjustAction.value.lineIdx;
    }

    emitter.emit(ACTION.ROLE_PLAY_ADJUST, {
        message_id: props.messages[props.messages.length - 1].chat_message_id,
        lineIdx,
        action_state: {
            submitted: true,
        },
        action_params: {
            adjustment,
        },
    });
    logUserInteraction("role_play_adjust_submitted", { adjustment }, coachingSessionId);
}

onMounted(() => {
    emitter.on(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRolePlayFeedback);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, focusRolePlayFeedback);
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
