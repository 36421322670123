<template>
    <div class="flex gap-1">
        <button
            type="button"
            :class="{ 'tooltip tooltip-bottom': !isShowing }"
            class="p-2 opacity-50 hover:opacity-100 active:bg-[#F7FAFF] hover:bg-[#F7FAFF] hover:text-[#555BA2] border-transparent hover:border-[#555BA266] border-2 rounded-full transition-opacity"
            data-tip="Copy to clipboard"
            @click="handleCopyLine"
        >
            <Copy />
            <StatusBadge :is-showing="isShowing" direction="bottom" alignment="center" :offset-y="20" :offset-x="-12">
                <template #content
                    ><div class="text-sm flex gap-2 items-center"><Copy />Copied Text</div></template
                >
            </StatusBadge>
        </button>
        <button
            v-if="showThoughtProcess"
            type="button"
            class="p-2 opacity-50 hover:opacity-100 tooltip tooltip-bottom active:bg-[#F7FAFF] hover:bg-[#F7FAFF] hover:text-[#555BA2] border-transparent hover:border-[#555BA266] border-2 rounded-full transition-opacity"
            data-tip="Explain my thought process"
            @click="$emit('thoughtProcessClicked', message)"
        >
            <HelpBubble />
        </button>
    </div>
</template>

<script setup>
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed } from "vue";

import StatusBadge from "./components/StatusBadge.vue";
import { useTimedShow } from "./composables/useTimedShow";
import Copy from "./icons/Copy.vue";
import HelpBubble from "./icons/HelpBubble.vue";

const { message, lineIdx } = defineProps({
    message: { type: Object, default: () => ({}) },
    lineIdx: { type: Number, required: true },
    showThoughtProcess: { type: Boolean, default: false },
});

defineEmits(["thoughtProcessClicked"]);

const { isShowing, show } = useTimedShow({ initialState: false, duration: 3000 });

const line = computed(() => {
    if (!message || !message.lines) {
        return null;
    }
    const line = message.lines[lineIdx];
    if (!line) {
        return null;
    }
    return line;
});

async function handleCopyLine() {
    if (!line.value) {
        return;
    }

    let plain = line.value.content;
    let html = line.value.content;

    if (line.value.type === "md") {
        html = DOMPurify.sanitize(marked.parse(line.value.content, { headerIds: false, mangle: false }));
    }

    const data = new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([plain], { type: "text/plain" }),
    });
    await navigator.clipboard.write([data]);
    show();
}
</script>
