<template>
    <div>
        <div v-if="!insightWidgetsExist" class="w-full px-5">
            <div class="inline-block text-xs uppercase py-1 px-2 bg-valence-grey-100 rounded mb-2">Insights</div>
            <div class="flex gap-2 mt-2">
                <span class="italic text-sm text-valence-grey-800/75">I'll highlight and pin key insights from our conversations here</span>
            </div>
        </div>
        <template v-for="(message, idx) in messagesReverseChronological" :key="idx">
            <template v-for="(line, lineIdx) in message.lines" :key="lineIdx">
                <Transition name="fade">
                    <template v-if="lineIsWidget(line)">
                        <div
                            :ref="(el) => ($refs[refNameForWidget(message.chat_message_id, line.action_name, lineIdx)] = el)"
                            :class="{ focused: isFocused(message.chat_message_id, line.action_name, lineIdx) }"
                        >
                            <InsightWidget
                                v-if="line.action_name == widgets.INTERNAL_THINKING || line.action_name == widgets.VALUES_INSIGHT"
                                :message="message"
                                :data="line"
                                :message-id="message.chat_message_id"
                                :line-idx="lineIdx"
                                :role="message.role"
                                :action_type="line.action_name"
                            />
                        </div>
                    </template>
                </Transition>
            </template>
        </template>
        <HorizontalDivider />
        <CoachingMode />
        <HorizontalDivider />
        <Transition name="fade">
            <template v-if="hasRolePlayMonitor(messages)">
                <button
                    type="button"
                    class="flex items-center justify-between text-valence-grey-800 leading-none text-base w-full p-4 rounded-xl border hover:bg-white/30 mb-2"
                    @click="expandRolePlayMonitor()"
                >
                    Role play insights
                    <span class="bi bi-chevron-right mr-1"></span>
                </button>
            </template>
        </Transition>
        <div class="flex flex-col gap-2">
            <h3 v-if="visibleWidgetCount > 0" class="font-bold">Notes</h3>
            <template v-for="(message, idx) in messagesReverseChronological" :key="idx">
                <template v-for="(line, lineIdx) in message.lines" :key="lineIdx">
                    <div v-if="lineIsNoteWidget(line)" :ref="(el) => ($refs[refNameForWidget(message.chat_message_id, line.action_name, lineIdx)] = el)">
                        <ChatActionCustomizableText
                            v-if="line.action_name == widgets.CUSTOMIZABLE_TEXT"
                            :data="line"
                            :message-id="message.chat_message_id"
                            :line-idx="lineIdx"
                            :role="message.role"
                        />
                        <ChatActionOnboardingSummary
                            v-else-if="line.action_name == widgets.ONBOARDING_SUMMARY"
                            :data="line"
                            :message-id="message.chat_message_id"
                            :line-idx="lineIdx"
                            :role="message.role"
                        />
                        <ChatActionFollowupInSidebar
                            v-if="line.action_name == widgets.FOLLOW_UP"
                            :data="line"
                            :message-id="message.chat_message_id"
                            :line-idx="lineIdx"
                            :role="message.role"
                        />
                        <ChatActionActionItems v-else-if="line.action_name == widgets.ACTION_ITEMS" :data="line" :message-id="message.chat_message_id" :line-idx="lineIdx" />
                    </div>
                </template>
            </template>
            <ChatActionProfileInferences v-if="showProfileInferences" @insight-num-change="handleInsightNumChange" />
        </div>
        <Transition name="fade">
            <div v-if="showTeamMemberContext" class="mt-8">
                <ChatTeamMemberContext />
            </div>
        </Transition>
        <PastedContent :messages="messages" />
    </div>
</template>

<script setup>
import ChatActionActionItems from "~vue/ChatActionActionItems.vue";
import ChatActionCustomizableText from "~vue/ChatActionCustomizableText.vue";
import ChatActionFollowupInSidebar from "~vue/ChatActionFollowupInSidebar.vue";
import ChatActionOnboardingSummary from "~vue/ChatActionOnboardingSummary.vue";
import ChatActionProfileInferences from "~vue/ChatActionProfileInferences.vue";
import { ACTION, actionExists, hasRolePlayMonitor, isRolePlayMessage, lineHasWidget, lineIsWidget } from "~vue/chatActions.js";
import ChatTeamMemberContext from "~vue/ChatWidgets/ChatTeamMemberContext.vue";
import InsightWidget from "~vue/ChatWidgets/InsightWidget.vue";
import PastedContent from "~vue/ChatWidgets/PastedContent.vue";
import ChatRolePlayInsights from "~vue/components/ChatRolePlayInsights";
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import CoachingMode from "~vue/components/navigation/CoachingMode.vue";
import { CHAT_EVENT } from "~vue/events.js";
import { useChatStore } from "~vue/stores/chatStore.js";
import { computed, inject, onMounted, onUnmounted, ref, watch } from "vue";

const props = defineProps({
    messages: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustments: {
        type: Array,
        default: () => [],
    },
    rolePlayAdjustmentDefault: {
        type: String,
        default: () => "",
    },
    isOpen: Boolean,
});

const showProfileInferences = ref(false);

const emit = defineEmits(["expand"]);

const { emitter } = inject("globalProperties");

const focusedWidget = ref(null);

const widgets = ACTION;

const $refs = ref({});

const visibleWidgetCount = computed(() => {
    return getMessagesWithWidgets().reduce((counter, message) => {
        return counter + message.lines.filter((l) => lineIsWidget(l) && !l.action_state.dismissed).length;
    }, 0);
});

const insightWidgetsExist = computed(() => {
    return actionExists(props.messages, widgets.INTERNAL_THINKING) || actionExists(props.messages, widgets.VALUES_INSIGHT);
});

const messagesReverseChronological = computed(() => {
    return props.messages.slice().reverse();
});

const showTeamMemberContext = computed(() => useChatStore.hasTeamMemberContext);

watch(visibleWidgetCount, (newCount) => {
    emitter.emit(CHAT_EVENT.WIDGET_COUNT_CHANGED, newCount);
});

onMounted(() => {
    emitter.on(CHAT_EVENT.CHAT_MESSAGE, handleChatMessage);
    emitter.on(CHAT_EVENT.FOCUS_WIDGET, handleFocusWidget);
    emitter.on(CHAT_EVENT.INFERENCE_MADE, handleProfileInference);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.CHAT_MESSAGE, handleChatMessage);
    emitter.off(CHAT_EVENT.FOCUS_WIDGET, handleFocusWidget);
    emitter.off(CHAT_EVENT.INFERENCE_MADE, handleProfileInference);
});

function handleInsightNumChange(insightNum) {
    if (insightNum === 0) {
        showProfileInferences.value = false;
    }
}

function handleProfileInference() {
    showProfileInferences.value = true;
}

function isFocused(messageId, actionName, lineIdx) {
    return focusedWidget.value === refNameForWidget(messageId, actionName, lineIdx);
}

function refNameForWidget(messageId, actionName, lineIdx) {
    return `${actionName}_${messageId}_${lineIdx}`;
}

function handleFocusWidget({ actionName, messageId, lineIdx }) {
    if (actionName === ACTION.ROLE_PLAY_MONITOR) {
        expandRolePlayMonitor();
        return;
    }

    const widgetKey = refNameForWidget(messageId, actionName, lineIdx);
    if (widgetKey in $refs.value && $refs.value[widgetKey].length === 1) {
        const effect = () => {
            $refs.value[widgetKey][0].scrollIntoView();
            focusedWidget.value = widgetKey;
            setTimeout(() => {
                focusedWidget.value = null;
            }, 2000);
        };

        if (!props.isOpen.value) {
            /*
             * Parent component may open the sidebar in the same
             * listener, so we focus the widget in the next tick so that
             * the sidebar UI is shown first.
             */
            $nextTick(effect);
        } else {
            effect();
        }
    }
}

function handleChatMessage(socketMessage) {
    const chatMessage = socketMessage.data;

    if (!chatMessage) {
        return;
    }

    if (Array.isArray(chatMessage.lines) && chatMessage.lines.some((l) => lineIsWidget(l))) {
        emitter.emit(CHAT_EVENT.WIDGET_ADDED, chatMessage);
    }

    if (isRolePlayMessage(chatMessage)) {
        expandRolePlayMonitor();
    }
}

function lineIsNoteWidget(line) {
    return lineIsWidget(line) && !lineHasWidget(line, widgets.INTERNAL_THINKING) && !lineHasWidget(line, widgets.VALUES_INSIGHT);
}

function getMessagesWithWidgets() {
    return props.messages.filter((message) => {
        return message.lines.some(lineIsNoteWidget);
    });
}

function expandRolePlayMonitor() {
    emit("expand", {
        title: "Role play insights",
        component: ChatRolePlayInsights,
        props: { rolePlayAdjustments: props.rolePlayAdjustments, rolePlayAdjustmentDefault: props.rolePlayAdjustmentDefault },
    });
}
</script>

<style type="postcss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.pinned-widgets .widget-item {
    @apply border-b border-b-[#DEE5EC];
}

.focused .widget-item {
    @apply bg-valence-violet-50 border-valence-violet-800 border;
}
</style>
