<template>
    <svg viewBox="0 0 1882 1872" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_132)">
            <ellipse cx="831.319" cy="831.931" rx="389.319" ry="389.745" :fill="`url(#paint0_linear_1002_132-${uniqueId})`" />
        </g>
        <g filter="url(#filter1_f_1002_132)">
            <ellipse
                cx="295.667"
                cy="300.526"
                rx="295.667"
                ry="300.526"
                transform="matrix(0.700384 -0.713767 0.713001 0.701163 615.289 800.075)"
                :fill="`url(#paint1_linear_1002_132-${uniqueId})`"
            />
        </g>
        <g filter="url(#filter2_f_1002_132)">
            <ellipse cx="258.018" cy="307.211" rx="258.018" ry="307.211" transform="matrix(0.64617 0.763193 -0.762496 0.646993 1031.25 565.639)" fill="#9CFFEA" />
        </g>
        <defs>
            <filter id="filter0_f_1002_132" x="0.688568" y="0.875092" width="1661.26" height="1662.11" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="220.656" result="effect1_foregroundBlur_1002_132" />
            </filter>
            <filter id="filter1_f_1002_132" x="297.347" y="60.2179" width="1478.6" height="1479.07" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="220.656" result="effect1_foregroundBlur_1002_132" />
            </filter>
            <filter id="filter2_f_1002_132" x="45.7348" y="51.0834" width="1835.98" height="1820.47" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="315.222" result="effect1_foregroundBlur_1002_132" />
            </filter>
            <linearGradient :id="`paint0_linear_1002_132-${uniqueId}`" x1="704.528" y1="571.895" x2="1012.78" y2="917.143" gradientUnits="userSpaceOnUse">
                <stop stop-color="#18BEFD" />
                <stop offset="0.976355" stop-color="#2DECF9" stop-opacity="0.4" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_1002_132-${uniqueId}`" x1="199.376" y1="100.017" x2="437.445" y2="362.634" gradientUnits="userSpaceOnUse">
                <stop stop-color="#18BEFD" />
                <stop offset="0.976355" stop-color="#2DECF9" stop-opacity="0.4" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
