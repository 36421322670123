import { DateTime } from "luxon";

export const convertToDateTime = (date) => {
    if (DateTime.isDateTime(date)) {
        return date;
    } else if (typeof date === "string") {
        return DateTime.fromISO(date);
    } else if (typeof date === "object" && date instanceof Date) {
        return DateTime.fromJSDate(date);
    } else {
        throw new Error(`Could not identify type of date: '${date}'`);
    }
};

export const roundToNearest15 = (date) => {
    const dt = convertToDateTime(date);
    return dt.startOf("hour").plus({ minutes: Math.round(dt.minute / 15) * 15 });
};

export const nextWorkday = (date) => {
    let dt = convertToDateTime(date);
    while (dt.isWeekend) {
        dt = dt.plus({ days: 1 });
    }
    return dt;
};

export function roundToFifteenMinuteIncrement(jsDate) {
    const minutes = jsDate.getMinutes();

    if (minutes < 15) {
        jsDate.setMinutes(15);
    } else if (minutes < 30) {
        jsDate.setMinutes(30);
    } else if (minutes < 45) {
        jsDate.setMinutes(45);
    } else {
        jsDate.setMinutes(0);
        jsDate.setHours(jsDate.getHours() + 1);
    }
}

export function humanizedTimeOfDay(date = new Date()) {
    const dt = convertToDateTime(date);
    const currentHour = dt.hour;
    const AFTERNOON_START = 12;
    const EVENING_START = 17;
    if (currentHour < AFTERNOON_START) {
        return "morning";
    } else if (currentHour < EVENING_START) {
        return "afternoon";
    } else {
        return "evening";
    }
}
