<template>
    <h3 class="font-bold mb-4">Context</h3>
    <div class="flex flex-col gap-2">
        <BaseActionCard v-for="{ name } of teamMembers" :key="name" :title="name" :show-arrow="false" class="!bg-transparent hover:!bg-gray-50/80 border">
            <div>
                <div class="flex gap-4">
                    <span class="rounded-full text-white text-center text-xs font-medium leading-6 bg-valence-grey-300 w-6 h-6"> {{ getFirstLastInitials(name) }}</span>
                    <span class="grow font-normal">{{ name }}</span>
                </div>
                <a class="text-xs cursor-pointer">Click to view profile</a>
            </div>
        </BaseActionCard>
    </div>
</template>

<script setup>
import BaseActionCard from "~vue/components/BaseActionCard.vue";
import { useUserStore } from "~vue/stores/userStore";
import { logUserInteraction } from "~vue/utils/logUtils";
import { getFirstLastInitials } from "~vue/utils/stringUtils";
import { computed, inject, onMounted } from "vue";
const teamMembers = computed(() => useUserStore.teamMembers);

const coachingSessionId = inject("coachingSessionId");

onMounted(() => logUserInteraction("team_member_context_shown", {}, coachingSessionId));
</script>
