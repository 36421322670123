<template>
    <Transition name="fade">
        <PermissionDialog :is-open="showMicPermissionAide" @cancel="handleDialogCancel" />
    </Transition>
    <RecordingOnboardingDialog @dismiss="handleDismissedRecordingSetup" @complete="handleCompleteRecordingSetup" />
    <RecordingTestDialog :event-slug="coachingSessionId" @dismiss="handleTextboxShow" @mic-selected="handleMicSelection" />
    <StatusBadge :is-showing="shouldShowBadge" :text="statusBadgeText" />
    <div class="my-6 flex items-end gap-3 md:gap-6 md:px-0 px-6">
        <!-- Dictation toggle -->
        <button
            :data-tip="useChatStore.dictationOn ? 'Turn off sound' : 'Turn on sound'"
            type="button"
            :class="useChatStore.dictationOn ? 'side-button--active' : ''"
            class="side-button tooltip tooltip-left"
            @click="handleDictationToggle"
        >
            <VolumeCircle background-class="side-button-background" foreground-class="side-button-foreground" />
        </button>

        <!-- Input. Internal content should always occupy a height of 48px in all their states to avoid jitter when changing states, except for text input when the textarea resizes based on content length. -->
        <div
            class="grow flex gap-4 md:gap-8 justify-between px-6 rounded-3xl bg-white border-2 border-[#DFDFDF] min-h-12"
            :class="{ 'py-7 items-end': showTextbox, 'items-center py-6': !showTextbox, '!p-4 md:!p-6': hasFiles }"
        >
            <template v-if="showTextbox">
                <div class="flex flex-col gap-6 grow w-full">
                    <ul v-if="hasFiles" class="shrink-0 flex justify-start gap-4 flex-wrap p-1 max-h-96 overflow-y-auto">
                        <li v-for="file in useChatStore.files" :key="file.name">
                            <FileAttachedTag :file-description="file.description" :file-name="file.name" :remove="() => useChatStore.removeFile(file.name)" />
                        </li>
                    </ul>
                    <textarea
                        ref="textarea"
                        v-model="content"
                        autocomplete="off"
                        :placeholder="textPlaceholder"
                        class="w-full tracking-[-0.96px] pb-2 md:pb-0 grow focus-visible:outline-none leading-normal md:text-2xl font-medium"
                        @keydown="handleKeyDown"
                        @keydown.enter="handleSendMessage"
                        @paste="handlePaste"
                    />
                </div>
                <button title="Submit" :disabled="!content || isDisabled" type="button" class="submit-button" @click="handleSendMessage">
                    <ArrowCircle direction="up" background-class="submit-button-background" foreground-class="submit-button-foreground" />
                </button>
            </template>
            <template v-else>
                <div v-if="isTranscribing" class="flex items-center gap-3 py-3">
                    <span class="text-base font-medium text-gradient tracking-[-0.64px]"> Transcribing... </span>
                    <LoadingSpinner class="h-4 text-[#8C8C8C]" />
                </div>
                <template v-else>
                    <div class="grow w-full">
                        <VoiceLevels
                            :class="{ 'opacity-40 py-5': !isRecording, 'py-4': isRecording }"
                            class="md:py-4"
                            :levels="recordingAudioLevelHistory"
                            :bar-size="isRecording ? BAR_SIZE.SMALL : BAR_SIZE.TINY"
                            :levels-to-display="25"
                            :initial-display="VOICE_LEVEL_INITIAL_DISPLAY.OSCILLATING"
                        />
                    </div>
                    <div class="shrink-0 flex items-center">
                        <button v-if="isRecording" type="button" title="Cancel" class="px-4 text-[#8C8C8C] hover:text-[#5E5E5E]" @click="handleRecordingCancel">
                            <i class="bi bi-x-lg text-sm" />
                        </button>
                        <!-- Using the same button element with different bindings based on state so that the color transitions naturally -->
                        <button
                            type="button"
                            :disabled="isDisabled"
                            :class="isRecording ? 'recorder-button--recording' : 'recorder-button--idle'"
                            class="transition-colors recorder-button"
                            @click="isRecording ? recorder.stop() : handleRecordingStart()"
                        >
                            <div class="h-10 md:h-6 w-10 md:w-6">
                                <StopCircle v-if="isRecording" background-class="md:fill-transparent fill-[#CF1322]" foreground-class="fill-white" />
                                <MicrophoneCircle v-else background-class="fill-[#555BA2] md:fill-transparent" foreground-class="stroke-white" />
                            </div>
                            <span class="md:inline hidden" v-text="isRecording ? 'Stop recording' : 'Start recording'" />
                        </button>
                    </div>
                </template>
            </template>
        </div>

        <!-- Input toggle -->
        <button
            :data-tip="inputToggleTipText"
            :disabled="isRecording || isTranscribing"
            type="button"
            class="side-button tooltip tooltip-right"
            @click="showTextbox = !showTextbox"
        >
            <MicrophoneCircle v-if="showTextbox" background-class="side-button-background" foreground-class="side-button-foreground" />
            <TypeBlockCircle v-else background-class="side-button-background" foreground-class="side-button-foreground--fill" />
        </button>
    </div>
    <audio id="audioPlayer" ref="audioPlayer" class="hidden"></audio>
</template>

<script setup>
import { useMediaQuery, usePermission, useTextareaAutosize } from "@vueuse/core";
import FileAttachedTag from "~vue/components/FileAttachedTag.vue";
import StatusBadge from "~vue/components/StatusBadge.vue";
import VoiceLevels, { BAR_SIZE, INITIAL_DISPLAY as VOICE_LEVEL_INITIAL_DISPLAY } from "~vue/components/VoiceLevels.vue";
import { useTimedShow } from "~vue/composables/useTimedShow";
import { CHAT_EVENT } from "~vue/events.js";
import ArrowCircle from "~vue/icons/ArrowCircle.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import MicrophoneCircle from "~vue/icons/MicrophoneCircle.vue";
import StopCircle from "~vue/icons/StopCircle.vue";
import TypeBlockCircle from "~vue/icons/TypeBlockCircle.vue";
import VolumeCircle from "~vue/icons/VolumeCircle.vue";
import PermissionDialog from "~vue/Onboarding/PermissionDialog.vue";
import RecordingOnboardingDialog from "~vue/RecordingOnboardingDialog.vue";
import RecordingTestDialog from "~vue/RecordingTestDialog.vue";
import { useChatStore } from "~vue/stores/chatStore.js";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import DOMPurify from "dompurify";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { AudioRecorder, msToDisplayTime } from "../../AudioRecorder.js";

const DOCUMENT_LENGTH_THRESHOLD = 1000;

const props = defineProps({
    recordingOnboardingEnabled: Boolean,
    isTranscribing: Boolean,
    isSending: Boolean,
    showSlowTranscription: Boolean,
    defaultTextInput: {
        type: Boolean,
        default: false,
    },
    isDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(["textbox-show", "text-keydown", "text-send", "recording-start", "recording-complete", "recording-error"]);

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const isMdBreakpoint = useMediaQuery("(min-width: 768px)");

const recorder = ref(
    new AudioRecorder({
        levelCheckIntervalMs: 200,
        // Before the AudioRecorder, there was no time limit logic, so I'm setting this to
        // an hour for now until we can discuss what an appropriate time limit would be.
        maxRecordingTimeSecs: 60 * 60,
    }),
);

// template refs
const audioPlayer = ref(null);

// Dictation
const isDictationPlaying = ref(false);
const dictationChunks = ref({});
const nextDictationChunkId = ref({ messageId: null });

// Recording
const isRecording = ref(false);
const isPreparingToRecord = ref(false);
const recordingAudioLevelHistory = ref([]);
const recordingTime = ref("");
const recordingLevel = ref(0);
const showMicPermissionAide = ref(false);

const showTextbox = ref(props.defaultTextInput);
const showRecordingOnboarding = ref(props.recordingOnboardingEnabled);

const micPermission = usePermission("microphone");
const micPermissionGranted = computed(() => micPermission.value === "granted");

const inputToggleTipText = computed(() => {
    if (isRecording.value) {
        return "Stop recording to use text";
    }

    if (showTextbox.value) {
        return "Use voice";
    }

    return "Use text";
});

const { show: showBadge, isShowing: isBadgeShowing } = useTimedShow();

const hasFiles = computed(() => useChatStore.files.length > 0);

const shouldShowBadge = computed(() => {
    return isRecording.value || isBadgeShowing.value;
});

const textPlaceholder = computed(() => {
    if (isMdBreakpoint.value) {
        return "What's happening at work today?";
    }

    return "What's happening?";
});

const statusBadgeText = computed(() => {
    if (isRecording.value) {
        return "Listening...";
    }

    if (showTextbox.value) {
        return "Switched to text";
    }

    return "Switched to voice";
});

const { textarea, input: content } = useTextareaAutosize();

watch(showTextbox, (value) => {
    if (value) {
        logUserInteraction("turned_on_text_input", {}, coachingSessionId);
        nextTick(() => {
            textarea.value.focus();
        });
    }

    showBadge();
});

onMounted(() => {
    emitter.on(CHAT_EVENT.DICTATION_EVENT, handleDictationEvent);
    emitter.on(CHAT_EVENT.SET_MESSAGE_TEXT, handleSetMessageTextEvent);
    emitter.on(CHAT_EVENT.OPEN_TEXT_INPUT, handleTextboxShow);
    recorder.value.on("silence", handleRecorderSilence);
    recorder.value.on("level", handleRecorderLevel);
    recorder.value.on("stop", handleRecorderStopped);
    recorder.value.on("finish", handleRecorderFinished);

    audioPlayer.value?.addEventListener("ended", handleAudioFinished);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.DICTATION_EVENT, handleDictationEvent);
    emitter.off(CHAT_EVENT.SET_MESSAGE_TEXT, handleSetMessageTextEvent);
    emitter.off(CHAT_EVENT.OPEN_TEXT_INPUT, handleTextboxShow);

    recorder.value.off("silence", handleRecorderSilence);
    recorder.value.off("level", handleRecorderLevel);
    recorder.value.off("stop", handleRecorderStopped);
    recorder.value.off("finish", handleRecorderFinished);

    audioPlayer.value?.removeEventListener("ended", handleAudioFinished);
});

const handleSetMessageTextEvent = (text) => (content.value = text);

const getNextDictationChunk = () => {
    if (!useChatStore.dictationOn || isRecording.value) {
        return null;
    }
    if (!nextDictationChunkId.value.messageId || !nextDictationChunkId.value.sequenceNumber) {
        return null;
    }
    const messageChunks = dictationChunks.value[nextDictationChunkId.value.messageId];
    return messageChunks?.find((c) => c.sequenceNumber === nextDictationChunkId.value.sequenceNumber);
};

const deleteDictationChunk = (messageId, sequenceNumber) => {
    if (!dictationChunks.value[messageId]) {
        return;
    }
    dictationChunks.value[messageId] = dictationChunks.value[messageId].filter((c) => c.sequenceNumber !== sequenceNumber);
};

const handleAudioFinished = () => {
    const chunk = getNextDictationChunk();
    if (!chunk) {
        if (isDictationPlaying.value) {
            logUserInteraction("coach_dictation_stop", {}, coachingSessionId);
        }
        isDictationPlaying.value = false;
        return;
    }

    // Queue up the chunk to look for after this one finishes, if any
    nextDictationChunkId.value = { messageId: chunk.messageId, sequenceNumber: chunk.sequenceNumber + 1 };

    // Create a URL for the Blob and set it as the src of the audio element
    audioPlayer.value.src = URL.createObjectURL(chunk.blob);

    // Now that the blob is on the audio player, we can delete this chunk to free up memory
    deleteDictationChunk(chunk.messageId, chunk.sequenceNumber);

    audioPlayer.value
        .play()
        ?.then(() => {
            // Autoplay started.
            if (!isDictationPlaying.value) {
                logUserInteraction("coach_dictation_start", {}, coachingSessionId);
            }
            isDictationPlaying.value = true;
        })
        .catch(() => {
            // Autoplay was prevented because the user hasn't take an action.
            // TODO: consider showing a "play" button so that user can start playback.
            isDictationPlaying.value = false;
            logUserInteraction("coach_dictation_prevented", {}, coachingSessionId);
        });
};

const convertB64Mp3ToAudioBlob = (audioStream) => {
    const byteCharacters = atob(audioStream);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "audio/mp3" });
};

const handleDictationEvent = (data) => {
    if (!useChatStore.dictationOn) {
        // To save memory, don't bother storing dictation chunks if the user is not using dictation
        return;
    }

    const messageId = data.data.message_id;
    const sequenceNumber = data.data.dictation_count;
    const blob = convertB64Mp3ToAudioBlob(data.data.audio_stream);
    const chunk = { messageId, sequenceNumber, blob };

    if (dictationChunks.value[messageId]) {
        dictationChunks.value[messageId].push(chunk);
    } else {
        dictationChunks.value[messageId] = [chunk];
    }

    if (sequenceNumber === 1) {
        // This is the first chunk of what is presumably the newest/latest message, so cancel any
        // currently playing dictations and queue up this one.
        stopDictation();
        nextDictationChunkId.value = { messageId, sequenceNumber };
    }

    if (!isDictationPlaying.value && messageId === nextDictationChunkId.value.messageId && sequenceNumber === nextDictationChunkId.value.sequenceNumber) {
        // Either this is the first chunk of a new message, or we had paused the audio while waiting
        // on this chunk, so start it immediately.
        handleAudioFinished(); // Starts the new chunk
    }
};

const handleTextboxShow = () => {
    if (showRecordingOnboarding.value && !micPermissionGranted.value) {
        logUserInteraction("onboarding_viewed_enable_voice", {}, coachingSessionId);
        emitter.emit(CHAT_EVENT.OPEN_ONBOARDING_DIALOG);
        return;
    }

    showTextbox.value = true;
    emit("textbox-show");
};

const handleSendMessage = (event) => {
    if (!event.shiftKey) {
        event.preventDefault();
    }

    if (props.isSending) {
        return;
    }

    if (!event.shiftKey) {
        const clean = DOMPurify.sanitize(content.value, { FORBID_TAGS: ["a"] });
        emit("text-send", { content: clean, files: useChatStore.files });
        useChatStore.removeAllFiles();
    }
};

const handleCompleteRecordingSetup = () => (showRecordingOnboarding.value = false);
const handleDismissedRecordingSetup = () => {
    logUserInteraction("onboarding_viewed_voice_declined", {}, coachingSessionId);
    showRecordingOnboarding.value = false;
    handleTextboxShow();
};
const handleKeyDown = () => emit("text-keydown");
const handleRecordingCancel = () => {
    recorder.value.stop({ abort: true });
};
const handleRecorderSilence = () => {
    handleRecordingCancel();
    openTestDialog();
};
const handleRecorderLevel = (level) => {
    recordingLevel.value = level * 100;
    recordingAudioLevelHistory.value.push(level);
    recordingTime.value = msToDisplayTime(recorder.value.recordingTimeMs);
};
const handleRecorderStopped = () => {
    recordingAudioLevelHistory.value = [];
    isRecording.value = false;
    recordingTime.value = msToDisplayTime(recorder.value.recordingTimeMs);
    logUserInteraction("user_recording_ended", {}, coachingSessionId);
};
const handleRecorderFinished = ({ blob, recordingTimeMs }) => {
    emit("recording-complete", {
        blob,
        recordingTime: msToDisplayTime(recordingTimeMs),
    });
};
const handleRecordingStart = async () => {
    logUserInteraction("user_recording_start", {}, coachingSessionId);
    if (showRecordingOnboarding.value && !micPermissionGranted.value) {
        logUserInteraction("onboarding_viewed_enable_voice", {}, coachingSessionId);
        emitter.emit(CHAT_EVENT.OPEN_ONBOARDING_DIALOG);
        return;
    }

    stopDictation();
    isPreparingToRecord.value = true;
    recordingTime.value = "";
    recordingLevel.value = 0;

    try {
        await recorder.value.start(async (micPermissionState) => {
            if (micPermissionState !== "granted") {
                showMicPermissionAide.value = true;
            }
        });
        if (showMicPermissionAide.value === true) {
            return;
        }
        isRecording.value = true;
        emit("recording-start");
    } catch (e) {
        switch (e.name) {
            case "NotAllowedError":
            case "OverconstrainedError":
            case "NotFoundError":
                openTestDialog();
                break;
            default:
                emit("recording-error");
        }

        if ("Sentry" in window) {
            window.Sentry.captureException(e);
        }

        logUserInteraction("mic_error", {}, coachingSessionId);
    } finally {
        isPreparingToRecord.value = false;
    }
};
const openTestDialog = () => emitter.emit(CHAT_EVENT.OPEN_MIC_TEST_DIALOG);
const stopDictation = () => {
    audioPlayer.value.pause();
    audioPlayer.value.src = "";
    isDictationPlaying.value = false;
    nextDictationChunkId.value = { messageId: null };
};
const handleMicSelection = (event) => recorder.value.setAudioInputDeviceId(event.deviceId);
// NOTE: For the future "File Uploads" feature see `chat.actions.file_upload`
// 1. UI Component here sends a `file_upload` action code (see `transcription_request` for an example) and gets a `signed_url` in response.
// 2. Client then uploads the file to the `signed_url` and gets the `file_url` or `object_id` in response.
// 3. Once the file is uploaded, the client holds this state and sends a `file_uploaded/object_id` property along with the message when clicking "Send".
const handlePaste = (event) => {
    if (event.clipboardData.getData("text/plain").length > DOCUMENT_LENGTH_THRESHOLD) {
        event.preventDefault();
        const NUM_BYTES = 100;
        const now = new Date();
        useChatStore.addFile({
            name: `pasted-content-${now.toISOString()}.txt`,
            content: event.clipboardData.getData("text/plain"),
            description: event.clipboardData.getData("text/plain").slice(0, NUM_BYTES).replace(/\n/g, " ") + "...",
        });
    }
};

function handleDialogCancel() {
    showMicPermissionAide.value = false;
}

function handleDictationToggle() {
    logUserInteraction(useChatStore.dictationOn ? "turned_dictation_off" : "turned_dictation_on", {}, coachingSessionId);
    useChatStore.toggleDictation();
}
</script>

<style scoped>
[autocomplete="off"] div[data-lastpass-icon-root="true"] {
    display: none;
}

[autocomplete="off"] div[data-lastpass-infield="true"] {
    display: none;
}

textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

textarea::-webkit-scrollbar,
textarea::-webkit-resizer {
    display: none;
}

.side-button {
    @apply mb-6 shrink-0 h-auto w-10;
}

.side-button:disabled {
    @apply opacity-50;
}
:deep() {
    .side-button-foreground {
        @apply stroke-[#8C8C8C];
    }

    .side-button--active .side-button-background {
        @apply fill-white stroke-2 stroke-[#555BA2];
    }

    .side-button--active .side-button-foreground {
        @apply stroke-[#555BA2];
    }

    .side-button-foreground--fill {
        @apply fill-[#8C8C8C];
    }

    .side-button:not(.side-button--active) .side-button-background {
        @apply fill-transparent;
    }

    .side-button:not(.side-button--active):enabled:hover .side-button-foreground {
        @apply md:stroke-[#555BA2];
    }

    .side-button:not(.side-button--active):enabled:hover .side-button-foreground--fill {
        @apply md:fill-[#555BA2];
    }

    .side-button:not(.side-button--active):enabled:hover .side-button-background {
        @apply md:fill-white;
    }

    .submit-button {
        @apply shrink-0 text-[#555BA2] h-10 w-10;
    }

    .submit-button-background {
        @apply fill-[#555BA2];
    }

    .submit-button:hover .submit-button-background {
        @apply md:fill-[#4B508F];
    }

    .submit-button:disabled .submit-button-background,
    .recorder-button--idle:disabled {
        @apply opacity-[.24];
    }

    .submit-button-foreground {
        @apply stroke-white fill-white;
    }

    .submit-button:disabled .submit-button-foreground {
        @apply fill-[#555BA2] stroke-[#555BA2];
    }
}

.recorder-button {
    @apply flex items-center gap-1 text-base font-semibold text-[#FAFAFA] leading-normal tracking-[-0.64px] md:rounded-3xl md:py-3 md:px-6;
}

.recorder-button--idle {
    @apply md:hover:bg-[#4B508F] md:bg-[#555BA2];
}

.recorder-button--recording {
    @apply md:hover:bg-[#a70d19] md:bg-[#CF1322];
}

.text-gradient {
    @apply text-transparent;
    background: linear-gradient(125deg, #ff2891 -22.98%, #003fdc 113.56%);
    background-clip: text;
}
</style>
