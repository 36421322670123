import { createFetch } from "@vueuse/core";

export const useFetch = createFetch({
    options: {
        beforeFetch({ options }) {
            if (options.method !== "GET") {
                options.headers = {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                };
            }
        },
    },
});
