<template>
    <div class="flex flex-col gap-2">
        <template v-if="courses.length">
            <template v-for="course in courses" :key="course.pk">
                <SidebarCard
                    :title="course.title"
                    :description="course.summary"
                    :url="`${course.start_url}?from=journeys_click`"
                    button-title="Start journey"
                    :is-active="course.pk === activeCourseId"
                />
            </template>
        </template>
        <template v-else>
            <div class="flex flex-col gap-2">
                <div class="flex flex-col gap-2">
                    <div class="text-base text-gray-500 text-center mt-4">No journeys available</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import { onMounted, onUnmounted, ref } from "vue";

import SidebarCard from "./SidebarCard.vue";

const activeCourseId = ref(null);
const routeRegex = /courses\/courses\/(\d)/;

function checkActiveCourse(path) {
    const match = path.match(routeRegex);

    if (match && match.length > 1) {
        activeCourseId.value = Number(match[1]);
        return;
    }

    activeCourseId.value = null;
}

const removeRouteCheck = router.on("start", (event) => {
    const currentPath = event.detail.visit.url.pathname;
    checkActiveCourse(currentPath);
});

onMounted(() => {
    checkActiveCourse(window.location.pathname);
});

onUnmounted(() => {
    removeRouteCheck();
});

defineProps({
    courses: {
        type: Array,
        default: () => [],
    },
});
</script>
