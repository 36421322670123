<template>
    <div id="profile-menu-popover" ref="popover" class="inset-[unset] absolute w-[calc(280px)] bottom-[4em] left-[1em] p-4 shadow-md border border-gray-50 rounded-xl" popover="">
        <div class="flex flex-col gap-5">
            <SettingsMenuItems />
        </div>
    </div>
    <button
        popovertarget="profile-menu-popover"
        popovertargetaction="toggle"
        title="View menu"
        type="button"
        class="hidden md:flex shrink-0 p-5 w-full border-t border-t-gray-100 hover:bg-gray-50/80 text-left flex-row items-center gap-3 peer-popover-open:bg-gray-50"
    >
        <div class="w-10 h-10 shrink-0">
            <i class="bi-person-circle text-3xl text-valence-grey"></i>
        </div>
        <div class="text-valence-grey overflow-hidden grow">
            <p class="text-sm font-medium">{{ $userDetails.value.first_name }} {{ $userDetails.value.last_name }}</p>
            <p :title="$userDetails.value.email" class="text-xs text-ellipsis overflow-hidden" v-text="$userDetails.value.email"></p>
        </div>
    </button>
</template>

<script setup>
import { ref } from "vue";

import SettingsMenuItems from "./SettingsMenuItems.vue";

defineProps({
    isOpen: {
        type: Boolean,
        default: () => false,
    },
});

defineExpose({
    hidePopover,
});

const popover = ref(null);

function hidePopover() {
    popover.value.hidePopover();
}
</script>
