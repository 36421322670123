<template>
    <button
        type="button"
        :title="isOpen ? 'Close sidebar' : 'Open sidebar'"
        class="rounded-md p-[5px] transition-color duration-150 ease-in hover:bg-[#C7E5FF30]"
        @click="handleClick"
    >
        <div class="relative">
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-[30px] w-[30px]">
                <path
                    d="M3.86211 2.00391H26.1371C26.1371 2.00391 27.9934 2.00391 27.9934 3.86016V26.1352C27.9934 26.1352 27.9934 27.9914 26.1371 27.9914H3.86211C3.86211 27.9914 2.00586 27.9914 2.00586 26.1352V3.86016C2.00586 3.86016 2.00586 2.00391 3.86211 2.00391Z"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M20.5684 2.00391V27.9914" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span
                v-if="widgetCount > 0"
                class="absolute top-[-8px] right-[-8px] z-1 bg-[#555BA2] text-white w-[18px] h-[18px] text-xs font-semibold rounded-full flex items-center justify-center"
            >
                {{ widgetCount }}
            </span>
        </div>
    </button>
</template>

<script setup>
import { inject, onMounted, onUnmounted, ref } from "vue";

import { CHAT_EVENT } from "./events.js";

const { emitter } = inject("globalProperties");

defineProps({
    isOpen: {
        type: Boolean,
        required: true,
        default: true,
    },
});

const emit = defineEmits(["click"]);

const widgetCount = ref(0);

onMounted(() => {
    emitter.on(CHAT_EVENT.WIDGET_COUNT_CHANGED, handleWidgetCountChanged);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.WIDGET_COUNT_CHANGED, handleWidgetCountChanged);
});

function handleWidgetCountChanged(visibleWidgetCount) {
    widgetCount.value = visibleWidgetCount;
}

function handleClick() {
    emit("click");
}
</script>
