<template>
    <ul class="text-xs">
        <template v-for="(item, idx) in data.action_params.action_items" :key="idx">
            <li class="first:mt-0 mt-2 flex flex-row items-start gap-3">
                <button type="button" :title="item.checked ? 'Completed' : 'Mark complete'" class="shrink-0 text-2xl group" @click="check(idx)">
                    <i v-if="item.checked" class="bi bi-check-circle-fill text-valence-light-blue-400"></i>
                    <span v-else class="relative text-gray-300">
                        <i class="bi bi-circle text-valence-light-blue-200 group-hover:text-valence-light-blue-400"></i>
                        <i class="top-0 left-0 right-0 mx-auto absolute group-hover:block hidden bi bi-circle-fill text-gray-300/15"></i>
                    </span>
                </button>
                <span :class="{ 'line-through': item.checked }" class="grow text-sm mt-1">
                    {{ item.content }}
                </span>
            </li>
        </template>
    </ul>
</template>

<script>
import { logUserInteraction } from "~vue/utils/logUtils";
export default {
    inject: ["coachingSessionId"],
    props: {
        actionItems: {
            type: Array,
            required: true,
        },
        messageId: {
            type: Number,
            required: true,
        },
        lineIdx: {
            type: Number,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        check(actionItemIndex) {
            const item = this.data.action_params.action_items[actionItemIndex];
            item.checked = !item.checked;

            const action_data = {
                subaction: "check_item",
                item_index: actionItemIndex,
                message_id: this.messageId,
                lineIdx: this.lineIdx,
                action_params: this.data.action_params,
                action_state: {
                    submitted: false,
                },
            };
            this.reschedule_override = false;
            this.emitter.emit("action_items", action_data);
            logUserInteraction("action_items_checked", {}, this.coachingSessionId);
        },
    },
};
</script>
