<template>
    <div aria-hidden="true" :class="[propsClass, positioning]" class="bubble bubble--left"></div>
    <div aria-hidden="true" :class="[propsClass, positioning]" class="bubble bubble--right"></div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
    class: { type: String, default: "" },
    direction: { type: String, default: "bottom" },
    directionMd: { type: String, default: "" },
});

const propsClass = computed(() => props.class);

const positioning = computed(() => {
    const classNames = [];
    switch (props.direction) {
        case "top":
            classNames.push("-translate-y-1/3", "top-auto", "bottom-0");
            break;

        default:
        case "bottom":
            classNames.push("translate-y-1/3", "bottom-auto", "top-0");
            break;
    }

    if (props.directionMd) {
        switch (props.directionMd) {
            case "top":
                classNames.push("md:-translate-y-1/3", "md:top-auto", "md:bottom-0");
                break;

            default:
            case "bottom":
                classNames.push("md:translate-y-1/3", "md:bottom-auto", "bottom-0");
        }
    }

    return classNames.join(" ");
});
</script>

<style scoped>
.bubble {
    @apply fixed overflow-hidden h-screen w-screen rounded-full scale-150 -z-10;
}

.bubble--right {
    @apply translate-x-1/3;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 63, 220, 0.12) 0%, rgba(0, 63, 220, 0) 100%);
}

.bubble--left {
    @apply -translate-x-1/3;
    background: radial-gradient(50% 50% at 50% 50%, rgba(254, 40, 144, 0.12) 0%, rgba(254, 40, 144, 0) 100%);
}
</style>
