<template>
    <BaseNotification v-if="!isDismissed" class="!rounded-2xl" theme="primary" @dismiss="handleSkip">
        <template #content="">
            <div class="flex justify-between items-center grow">
                <p class="text-base font-semibold">Let's follow up on this?</p>
                <button type="button" class="font-semibold rounded-full border-2 border-[#E0E9FF] bg-white px-6 py-3 leading-4" @click="handleScheduleFollowup">
                    <i class="bi bi-calendar mr-3"></i>Schedule
                </button>
            </div>
        </template>
    </BaseNotification>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, onMounted } from "vue";

import BaseNotification from "./components/BaseNotification.vue";
import { CHAT_EVENT } from "./events";

const coachingSessionId = inject("coachingSessionId");
const { emitter } = inject("globalProperties");

const { data } = defineProps({
    data: { type: Object, required: true },
});

const isDismissed = computed(() => data.line.action_state.dismissed);

onMounted(() => {
    logUserInteraction("followup_presented", { chat_message_id: data.messageId }, coachingSessionId);
});

const handleScheduleFollowup = () => {
    emitter.emit(CHAT_EVENT.OPEN_EXIT_INTENT_DIALOG);
    logUserInteraction("followup_scheduled", {}, coachingSessionId);
};

const handleSkip = () => {
    if (data.line) {
        const action_data = {
            message_id: data.messageId,
            lineIdx: data.lineIdx,
            action_params: data.line.action_params,
            action_state: {
                dismissed: true, // in the case of event_identifier (followup) actions, I guess it makes no difference?
            },
        };
        emitter.emit("followup_update", action_data);
    }
};
</script>
