<template>
    <CenterBubble />
    <div class="relative flex flex-col md:flex-row grow overflow-hidden max-h-full text-valence-grey-800 antialiased">
        <div id="chat-container" class="relative grow flex flex-col overflow-hidden max-h-full pb-[calc(var(--mobile-nav-total-height))] md:pb-0">
            <DevTools v-if="devtoolsEnabled" :messages="messages" :send-message="sendMessage" :prompt-id="first_prompt_id" />
            <ExitIntentDialog :messages="messages" :has-future-calendar-events="has_future_calendar_events" />
            <SessionSummary ref="sessionSummaryRef" :messages="messages" />
            <ThoughtProcessModal :messages="messages" />
            <div :class="{ 'grow flex': showWidgetSidebar }" class="shrink-0 md:hidden flex flex-col justify-end overflow-hidden max-h-full border-b border-b-gray-200">
                <div class="border-t border-t-gray-200 shrink-0 flex bg-white text-sm font-medium">
                    <button v-if="showWidgetSidebar" class="flex items-center gap-4 px-4 py-2 w-full" type="button" @click="showWidgetSidebar = false">
                        <span role="presentation" class="bi bi-chevron-up"></span>
                        Close
                    </button>
                    <button v-if="!showWidgetSidebar" title="Open menu" class="flex items-center gap-4 px-4 py-2 w-full" type="button" @click="showWidgetSidebar = true">
                        <span role="presentation" class="bi bi-chevron-down"></span>
                        <div class="flex gap-1">Suggestions</div>
                    </button>
                </div>
                <div v-if="showWidgetSidebar" class="overflow-y-auto border-t border-t-gray-200 bg-white grow p-4">
                    <ChatWidgetList :messages="messages" />
                </div>
            </div>
            <ChatMessageList
                ref="chatMessageList"
                :loading-message-stack="loading_message_stack"
                :dismiss-suggested-scenarios="dismissSuggestedScenarios"
                :messages="messages"
                :number-of-messages-at-init="numberOfMessagesAtInit"
                :show-failed-response="show_failed_response"
                :show-start-loading-message="showStartLoadingMessage"
                :show-slow-response="show_slow_response"
                :show-widget-sidebar="showWidgetSidebar"
                :waiting="waiting_for_assistant"
                :user-first-name="user_details.first_name"
                :user-last-name="user_details.last_name"
                @dismiss-failure="show_failed_response = false"
                @loading-complete="showStartLoadingMessage = false"
            />
            <div v-if="chatError" class="w-full md:max-w-screen-md md:mx-auto shrink-0">
                <ChatErrorMessage :error-message="chatError" @dismiss="chatError = null" />
            </div>
            <div v-else-if="show_failed_response" class="w-full md:max-w-screen-md md:mx-auto shrink-0">
                <ChatErrorMessage @dismiss="show_failed_response = false">
                    <p class="text-sm md:text-base font-semibold">
                        Something went wrong. Please <button type="button" class="underline" @click="handleFailedResponseReload">refresh our chat.</button>
                    </p>
                </ChatErrorMessage>
            </div>
            <div class="w-full md:max-w-screen-md md:mx-auto">
                <ChatActionFollowupInChat v-if="showInChatFollowUp" :data="inChatFollowUp" />
            </div>
            <div class="w-screen md:w-full md:max-w-screen-md md:mx-auto">
                <ChatMessageControls
                    :is-sending="waiting_for_assistant"
                    :is-transcribing="waiting_for_transcript"
                    :is-disabled="showStartLoadingMessage"
                    :show-slow-transcription="show_slow_transcription"
                    :recording-onboarding-enabled="recording_onboarding_enabled"
                    @recording-complete="handleRecordingComplete"
                    @recording-error="handleRecordingError"
                    @recording-start="handleRecordingStart"
                    @textbox-show="handleTextboxShown"
                    @text-keydown="handleKeyDown"
                    @text-send="handleSendMessage"
                />
            </div>
            <div class="flex justify-center mb-2 -mt-4">
                <Disclaimer v-if="disclaimer_message" :message="disclaimer_message" />
            </div>
        </div>
        <aside>
            <ChatWidgetSidebar
                :role-play-adjustments="role_play_adjustments"
                :role-play-adjustment-default="role_play_adjustment_default"
                :show-widget-list="showWidgetSidebar"
                :messages="messages"
                @toggle-show-widget-list="handleToggleWidgetSidebar"
            />
        </aside>
    </div>
    <CookieBanner />
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import { useThrottleFn } from "@vueuse/core";
import ChatActionFollowupInChat from "~vue/ChatActionFollowupInChat.vue";
import { ACTION, lineIsWidget, pluckAction } from "~vue/chatActions.js";
import ChatErrorMessage from "~vue/ChatErrorMessage.vue";
import ChatMessageList from "~vue/ChatMessageList.vue";
import ChatWidgetList from "~vue/ChatWidgetList.vue";
import ChatMessageControls from "~vue/ChatWidgets/ChatMessageControls.vue";
import ChatWidgetSidebar from "~vue/ChatWidgetSidebar.vue";
import CenterBubble from "~vue/components/CenterBubble.vue";
import CookieBanner from "~vue/components/CookieBanner.vue";
import { ONBOARDING_TASK } from "~vue/components/OnboardingChecklist.vue";
import DevTools from "~vue/DevTools.vue";
import Disclaimer from "~vue/Disclaimer.vue";
import { CHAT_EVENT } from "~vue/events.js";
import ExitIntentDialog from "~vue/ExitIntentDialog.vue";
import SessionSummary from "~vue/SessionSummary.vue";
import SocketClient from "~vue/SocketClient.js";
import { useChatStore } from "~vue/stores/chatStore.js";
import { useUserStore } from "~vue/stores/userStore.js";
import ThoughtProcessModal from "~vue/ThoughtProcessModal.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils.js";
import { inject, nextTick, onMounted, onUnmounted, provide, ref, useTemplateRef, watch } from "vue";
import { useStorage } from "vue3-storage";

import { encodeAudio, transcribeRecording } from "../../transcription.js";
import { getRandomInt } from "../utils/random.js";

const { emitter, $setEventContext, $sendEvent, $userDetails } = inject("globalProperties");

const SLOW_MESSAGE_TIMEOUT_MS = 2000;
const FAILED_MESSAGE_TIMEOUT_MS = 55000;
const SCROLL_THROTTLE_MS = 400;

const CHAT_ERROR = {
    TRANSCRIPTION_EXHAUSTED: "We're having issues transcribing your audio message. Please try again later.",
    RECORDING_DEFAULT: "We're having issues recording your audio message. Please try again later.",
    SEND_FAILURE: "We're having issues sending your message. Please try again later.",
    NO_SPEECH_DETECTED: "I didn't quite catch that. Please try again.",
};

const AUTO_OPEN_TEXT_INPUT_ERRORS = [CHAT_ERROR.TRANSCRIPTION_EXHAUSTED, CHAT_ERROR.RECORDING_DEFAULT, CHAT_ERROR.SEND_FAILURE];

const {
    role_play_adjustment_default,
    role_play_adjustments,
    event_context,
    ws_url,
    recording_onboarding_enabled,
    loading_message_stack,
    is_start_loading_message_enabled,
    disclaimer_message,
    cs_id,
    has_future_calendar_events,
    has_team_member_profile_context,
    user_team_members,
    first_prompt_id,
    dev_tools_enabled,
} = defineProps({
    role_play_adjustment_default: { type: String, default: "" },
    role_play_adjustments: { type: Array, default: () => [] },
    event_context: { type: Object, default: () => ({}) },
    ws_url: { type: String, default: "" },
    recording_onboarding_enabled: { type: Boolean, default: false },
    loading_message_stack: { type: Array, default: () => [] },
    is_start_loading_message_enabled: { type: Boolean, default: false },
    disclaimer_message: { type: String, default: "" },
    cs_id: { type: String, default: "" },
    has_future_calendar_events: { type: Boolean, default: false },
    has_team_member_profile_context: { type: Boolean, default: false },
    user_team_members: { type: Array, default: () => [] },
    first_prompt_id: { type: Number },
    dev_tools_enabled: { type: Boolean },
    user_details: { type: Object, default: () => ({}) },
});

provide("coachingSessionId", cs_id);

const messages = ref([]);
const waiting_for_transcript = ref(false);
const waiting_for_assistant = ref(false);
const show_slow_response = ref(false);
const show_failed_response = ref(false);
const show_slow_transcription = ref(false);
const has_taken_action = ref(false);
const slow_response_timeout = ref(null);
const failed_response_timeout = ref(null);
const backgrounded_at = ref(null);
const rolePlayAdjust = ref(null);
const dismissSuggestedScenarios = ref(false);
const showStartLoadingMessage = ref(is_start_loading_message_enabled);
const startLoadingMessageTimeout = ref(null);
const firstSocketMessageReceived = ref(false);
const numberOfMessagesAtInit = ref(0);
const showWidgetSidebar = ref(false);
const chatError = ref(null);
const promptSwitchCallCode = ref(null);
const socketClient = ref(null);
const showInChatFollowUp = ref(false);
const inChatFollowUp = ref(null);
const devtoolsEnabled = dev_tools_enabled || import.meta.env.DEV;
const dictated_in_this_message = ref(false);

watch(chatError, (current, old) => {
    if (current && !old && AUTO_OPEN_TEXT_INPUT_ERRORS.includes(current)) {
        emitter.emit(CHAT_EVENT.OPEN_TEXT_INPUT);
    }
});
watch(
    () => has_team_member_profile_context,
    (newValue) => {
        useChatStore.setHasTeamMemberContext(newValue);
    },
    { immediate: true },
);
watch(
    () => user_team_members,
    (teamMembers) => {
        useUserStore.setTeamMembers(teamMembers);
    },
    { immediate: true },
);

const storage = useStorage();

onMounted(() => {
    $setEventContext(event_context);
    if (ws_url) {
        socketClient.value = new SocketClient(ws_url, {
            reconnectEnabled: true,
            reconnectInterval: 2000,
        });
        socketClient.value.connect();
        let firstMessageReceived = false;

        socketClient.value.onMessage = (msg) => {
            const message_data = JSON.parse(msg.data);
            if (!firstMessageReceived) {
                firstMessageReceived = true;
                emitter.emit("first_socket_message");
            }
            if (message_data.type) {
                emitter.emit(message_data.type, message_data);
            }
        };
        socketClient.value.onOpen = () => {
            $sendEvent("initialize_chat", {
                dictation_on: storage.getStorageSync("dictation_on", true),
            });
        };
        socketClient.value.onError = () => {
            logError("Could not connect to websocket.");
        };
    }

    logUserInteraction("first_paint", {}, cs_id);
    emitter.on("chat_message", handleChatMessage);
    emitter.on("transcription_event", handleTranscriptionEvent);
    emitter.on("chat_partial_addition", handleChatPartialAddition);
    emitter.on("initialize_chat", handleChatInit);
    emitter.on("choose_answer", handleChooseAnswer);
    emitter.on("choose_scenario", handleChooseScenario);
    emitter.on("choose_followup", handleChooseFollowup);
    emitter.on("coachable_bullet_points_coach_me", handleCoachableBulletPointsCoachMe);
    emitter.on("coachable_bullet_points_save_for_later", handleCoachableBulletPointsSaveForLater);
    emitter.on("followup_update", handleFollowupChange);
    emitter.on("tip", handleTipAction);
    emitter.on("onboarding_summary", handleOnboardingSummary);
    emitter.on("profile_question", handleProfileQuestion);
    emitter.on("internal_thinking", handleInternalThinking);
    emitter.on("values_insight", handleValuesInsight);
    emitter.on("personalized_advice", handlePersonalizedAdvice);
    emitter.on("personalized_rating", handlePersonalizedRating);
    emitter.on(ACTION.ROLE_PLAY_ADJUST, handleRolePlayAdjust);
    emitter.on("suggested_topics", handleSuggestedTopics);
    emitter.on("action_items", handleActionItems);
    emitter.on("first_socket_message", handleFirstSocketMessage);
    emitter.on(CHAT_EVENT.FOCUS_WIDGET, handleWidgetFocus);
    emitter.on(CHAT_EVENT.MINIMIZE_WIDGETS, handleMinimizeWidgets);
    emitter.on("llm_explanation_feedback", handleLLMExplanationFeedback);
    emitter.on("inferred_profile_answer", handleInferredAnswer);
    emitter.on("onboarding_followup_scheduled", handleOnboardingFollowup);
    emitter.on("profile_notification_changed", handleProfileNotification);
    emitter.on("coaching_mode_updated", handleCoachingModeUpdate);
    emitter.on("user_switched_coaching_mode", handleUserSwitchedCoachingMode);
    emitter.on("wrap_up_chat", handleWrapUpChat);
    // this websocket event is emitted from `return_team_member_context` in `vpoc/apps/events/tasks.py`
    emitter.on("team_member_context", handleTeamMemberContext);

    if ($userDetails.value.email?.endsWith("@valence.co")) {
        document.addEventListener("keydown", handlePromptShortcutKeyDown);
    }
});

onUnmounted(() => {
    $setEventContext({});
    emitter.emit(CHAT_EVENT.LEAVING_CHAT);
    if (socketClient.value) {
        socketClient.value.removeListeners();
        socketClient.value.instance.close();
    }
    emitter.off("user_switched_coaching_mode");
    emitter.off("wrap_up_chat");
});

const handleMinimizeWidgets = ({ exclude }) => {
    $sendEvent(CHAT_EVENT.MINIMIZE_WIDGETS, { exclude_list: exclude });

    for (let message of messages.value) {
        for (let line of message.lines) {
            if (lineIsWidget(line) && !exclude.includes(line.action_name) && !line.action_state.minimized) {
                updateActionState({
                    message_id: message.chat_message_id,
                    lineIdx: message.lines.indexOf(line),
                    action_state: {
                        minimized: true,
                    },
                });
            }
        }
    }
};

const handleWidgetFocus = () => {
    if (!showWidgetSidebar.value) {
        showWidgetSidebar.value = true;
    }
};

const clearLoadingMessageTimeout = () => {
    if (startLoadingMessageTimeout.value !== null) {
        window.clearTimeout(startLoadingMessageTimeout.value);
        startLoadingMessageTimeout.value = null;
    }
};

const handleVisibility = () => {
    if (document.visibilityState === "hidden") {
        logUserInteraction("backgrounded_tab", {}, cs_id);
        backgrounded_at.value = new Date();
    } else {
        logUserInteraction("resumed_tab", {}, cs_id);
        let resumed_at = new Date();
        let min_since_active = (resumed_at - backgrounded_at.value) / 1000 / 60;

        // If the user has been away more than 8 hours, reload the chat.
        if (min_since_active > 8 * 60) {
            document.location.reload();
        }
    }
};

const handleUnload = () => {
    logUserInteraction("chat_unload", {}, cs_id);
};

const handleFirstSocketMessage = () => {
    firstSocketMessageReceived.value = true;
    /*
     * Clear the loading message threshold in case we received a
     * message through the socket before the loading message timeout
     * runs. This avoids showing the loading message unnecessarily.
     */
    clearLoadingMessageTimeout();
};

const handleChatInit = (data) => {
    messages.value = data.data.messages;
    numberOfMessagesAtInit.value = data.data.messages.length;
    scrollToBottom();

    // Initialize role play adjustment if last message has a
    // role_play_adjustment action.
    if (messages.value.length > 0) {
        const lastRolePlayAdjust = pluckAction([messages.value[messages.value.length - 1]], ACTION.ROLE_PLAY_ADJUST, true);
        if (lastRolePlayAdjust && lastRolePlayAdjust?.line.action_params?.adjustment) {
            rolePlayAdjust.value = lastRolePlayAdjust.line.action_params.adjustment;
        }
    }
    checkForInChatFollowUp(messages.value);
};

const handlePromptShortcutKeyDown = (event) => {
    // allows a shortcut for valence users to switch into the role play prompt in demos
    if (event.key === "1" && event.ctrlKey) {
        promptSwitchCallCode.value = "Role_Play";
    }
};

const handleTextboxShown = () => {
    has_taken_action.value = true;
    dismissSuggestedScenarios.value = true;
};

const getMessageById = (message_id, temp_id = undefined) => {
    for (let m of messages.value) {
        // TODO: Clean up the keys here
        if (m.id == message_id || m.message_id == message_id || m.chat_message_id == message_id) {
            return m;
        }
        if (temp_id && (m.temp_id == temp_id || m.message_id == temp_id)) {
            return m;
        }
    }
    return false;
};

const getMessageLine = (message_id, lineIdx) => {
    const message = getMessageById(message_id);
    if (message) {
        return message.lines[lineIdx];
    }
    return false;
};

const handleChatMessage = ({ data }) => {
    // Do whatever UI state needed, add to message queue
    if (waiting_for_assistant.value) {
        clearSlowResponseTimeout();
    }

    const m = getMessageById(data.message_id, data.temp_id);

    if (m) {
        m.lines = data.lines;
        if (data.message_id) {
            m.id = data.message_id;
        }
        if (data.chat_message_id) {
            m.chat_message_id = data.chat_message_id;
        }
    } else {
        messages.value.push(data);
    }

    if (!data.initial && data.role === "assistant") {
        waiting_for_assistant.value = false;
        logUserInteraction("coach_message_ended", { chat_message_id: data.chat_message_id }, cs_id);
        scrollToBottom();
    }

    checkForInChatFollowUp([data]);
};

const handleChatPartialAddition = (data) => {
    waiting_for_assistant.value = true;
    clearSlowResponseTimeout();

    const m = getMessageById(data.data["message_id"]);

    if (m) {
        // Handling partial content
        m.lines = data.data["lines"];
    } else {
        messages.value.push(data.data);
        logUserInteraction("coach_message_start", {}, cs_id);
    }

    /*
     * We may receive many partial additions in quick succession,
     * often times faster than we can notice. We throttle the automatic
     * scrolling accordingly to avoid jitter.
     */
    throttledScrollToBottom();
    startSlowResponseTimeout();
};

const handleTranscriptionEvent = (transcription) => {
    if (!waiting_for_transcript.value) {
        return;
    }

    waiting_for_transcript.value = false;
    show_slow_transcription.value = false;
    dictated_in_this_message.value = true;
    logUserInteraction("transcription_ended", {}, cs_id);
    sendMessage(transcription, { promptSwitchCallCode: promptSwitchCallCode.value, rolePlayAdjust: rolePlayAdjust.value });
    promptSwitchCallCode.value = null;
    rolePlayAdjust.value = null;
};

const updateActionState = (data) => {
    const { action_state, action_params, message_id, lineIdx, ...rest } = data;
    const line = { ...getMessageLine(message_id, lineIdx) };

    if (action_state) {
        line.action_state = {
            ...line.action_state,
            ...action_state,
        };
    }

    if (action_params) {
        line.action_params = {
            ...line.action_params,
            ...action_params,
        };
    }

    for (let x in rest) {
        line[x] = rest[x];
    }

    messages.value = messages.value.map((message) => {
        if (message_id === message.chat_message_id) {
            return {
                ...message,
                lines: message.lines.map((l, idx) => {
                    if (idx === lineIdx) {
                        return line;
                    } else {
                        return l;
                    }
                }),
            };
        } else {
            return message;
        }
    });
};

const handleChooseAnswer = (data) => {
    updateActionState(data);
    $sendEvent("action", { data: { type: "answers", payload: data } });
    sendMessage(data.answer, {});
};

const handleChooseScenario = (data) => {
    updateActionState(data);
    sendMessage(`Can we talk about this? "${data.answer}"`, { skipStream: true });
    $sendEvent("action", { data: { type: "scenarios", payload: data } });
};

const handleFollowupChange = (data) => {
    updateActionState(data);
    $sendEvent("action", { data: { type: "followup", payload: data } });
};

const handleChooseFollowup = (data) => {
    updateActionState(data);
    $sendEvent("action", { data: { type: "followup", payload: data } });
    markOnboardChecklistItemComplete(ONBOARDING_TASK.FOLLOWUP);
};

const handleCoachableBulletPointsCoachMe = (data) => {
    sendMessage(`Can you coach me on this? "${data.action_params.coach_me.content}"`, {});
    $sendEvent("action", { data: { type: data.multiSelect ? "coachable_bullet_points_multiselect" : "coachable_bullet_points", payload: data } });
};

const handleCoachableBulletPointsSaveForLater = (data) => {
    $sendEvent("action", { data: { type: "coachable_bullet_points", payload: data } });
};

const handleTipAction = (data) => {
    updateActionState(data);
    $sendEvent("update_action", { data: { type: "tip", payload: data } });
};

const handleInternalThinking = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "internal_thinking", payload } });
};

const handleValuesInsight = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "values_insight", payload } });
};

const handlePersonalizedAdvice = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "personalized_advice", payload } });
};

const handlePersonalizedRating = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "personalized_rating", payload } });
};

const handleRolePlayAdjust = (payload) => {
    if (payload.action_params?.adjustment) {
        rolePlayAdjust.value = payload.action_params.adjustment;
    }
    updateActionState(payload);
    $sendEvent("action", { data: { type: ACTION.ROLE_PLAY_ADJUST, payload } });
};

const handleSuggestedTopics = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "suggested_topics", payload } });
};

const handleOnboardingSummary = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "osummary", payload } });
};

const handleProfileQuestion = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "profile_question", payload } });
};

const handleActionItems = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "action_items", payload } });
};

const handleLLMExplanationFeedback = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "llm_explanation", payload } });
};

const handleSendMessage = ({ content, files }) => {
    sendMessage(content, { promptSwitchCallCode: promptSwitchCallCode.value, rolePlayAdjust: rolePlayAdjust.value, files });
    promptSwitchCallCode.value = null;
    rolePlayAdjust.value = null;
};

const handleInferredAnswer = (payload) => {
    updateActionState(payload);
    $sendEvent("action", { data: { type: "inferred_profile_answer", payload } });
};

const handleOnboardingFollowup = () => {
    markOnboardChecklistItemComplete(ONBOARDING_TASK.FOLLOWUP);
};

const handleProfileNotification = (payload) => {
    updateActionState(payload);
    // Main Menu actively listens for this event
    document.dispatchEvent(new CustomEvent("valence:profileNotificationChange", { detail: payload }));
};

const handleWrapUpChat = () => {
    logUserInteraction("wrap_up_chat", {}, cs_id);
    emitter.emit(CHAT_EVENT.OPEN_SESSION_SUMMARY);
    emitter.emit(CHAT_EVENT.MINIMIZE_WIDGETS, { exclude: [ACTION.ACTION_ITEMS] });
};

const sendMessage = (content, { promptSwitchCallCode, rolePlayAdjust, files, skipStream } = {}) => {
    if (content.length > 0 || files.length > 0) {
        waiting_for_assistant.value = true;
        has_taken_action.value = true;
        // Temporary message so the backend can send back data about this message
        const temp_id = `${Date.now()}${cs_id}`;
        const message = {
            temp_id: temp_id,
            content,
            role: "user",
            dictation_on: useChatStore.dictationOn,
            coaching_session_id: cs_id,
            dictated: dictated_in_this_message.value,
            skip_stream: skipStream,
            files,
        };
        if (promptSwitchCallCode) {
            message.prompt_switch_call_code = promptSwitchCallCode;
        }
        if (rolePlayAdjust) {
            message.role_play_adjust = rolePlayAdjust;
        }

        $sendEvent("user_chat_message", message)
            .then(() => {
                message.lines = [{ type: "md", content }];
                messages.value.push(message);
                scrollToBottom();
                logUserInteraction(
                    "user_message_sent",
                    {
                        mic: dictated_in_this_message.value,
                        coaching_session_id: cs_id,
                    },
                    cs_id,
                );

                dictated_in_this_message.value = false;
                emitter.emit(CHAT_EVENT.SET_MESSAGE_TEXT, "");

                const userMessagesCount = messages.value.filter((message) => message.role === "user").length;
                if (userMessagesCount === 3) {
                    markOnboardChecklistItemComplete(ONBOARDING_TASK.GUIDANCE);
                }

                if (!skipStream) {
                    startSlowResponseTimeout(4000);
                }
            })
            .catch((err) => {
                console.error(err);
                waiting_for_assistant.value = false;
                chatError.value = CHAT_ERROR.SEND_FAILURE;
                emitter.emit(CHAT_EVENT.SET_MESSAGE_TEXT, content);
            });
    }
};

const handleKeyDown = () => {
    if (chatError.value) {
        chatError.value = null;
    }
};

const handleTranscribeRecording = async ({ blob, recordingTime }) => {
    const eventData = {
        transcribe_session_start: Date.now(),
        blob_size: blob.size,
        recording_time: recordingTime,
        user_agent: navigator.userAgent,
    };

    let encodedAudio;
    try {
        encodedAudio = await encodeAudio(blob);
    } catch (err) {
        logUserInteraction("recording_reader_failed", eventData, cs_id);
        logError("Recording reader failed");
        chatError.value = CHAT_ERROR.RECORDING_DEFAULT;
        return;
    }

    waiting_for_transcript.value = true;

    // Demoing feature!
    // Check if we have any remaining items to go through in our dev tools script
    if (devtoolsEnabled.value) {
        const chatScriptStorageKey = `chat_script_${first_prompt_id}`;
        const script = JSON.parse(window.localStorage.getItem(chatScriptStorageKey, "{}"));
        const messages = script?.messages || [];

        const messagesExist = messages.some((message) => message.message.length > 0);

        // Script index needs to be unique to the coaching session
        const scriptIndexKey = `chat_script_current_index_${cs_id}`;
        const currentScriptIndex = Number(window.localStorage.getItem(scriptIndexKey, 0));

        if (messagesExist && currentScriptIndex < messages.length) {
            const currentMessage = messages[currentScriptIndex].message;
            window.localStorage.setItem(scriptIndexKey, currentScriptIndex + 1);

            // Wait between 300 and 700ms to make it look like we're actually doing some work here
            setTimeout(
                () => {
                    sendMessage(currentMessage);
                    waiting_for_transcript.value = false;
                },
                getRandomInt(300, 700),
            );

            return;
        }
    }

    try {
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
            onStart: () => {
                show_slow_transcription.value = false;
                logUserInteraction("transcription_request_start", eventData, cs_id);
            },
            onSlow: () => {
                show_slow_transcription.value = true;
                logUserInteraction("saw_slow_transcription_message", {}, cs_id);
            },
            onError: () => {
                logUserInteraction("saw_transcription_error_message", {}, cs_id);
                logUserInteraction("transcription_request_failed", eventData, cs_id);
                logError("Transcription request failed");
            },
        });
        logUserInteraction("transcription_request_succeeded", eventData, cs_id);
        handleTranscriptionEvent(transcript);
    } catch (err) {
        if (err.error_code === "no_speech_detected") {
            chatError.value = CHAT_ERROR.NO_SPEECH_DETECTED;
            logUserInteraction("no_speech_detected", eventData, cs_id);
        } else {
            chatError.value = CHAT_ERROR.TRANSCRIPTION_EXHAUSTED;
            logUserInteraction("transcription_request_retries_exhausted", eventData, cs_id);
        }
    } finally {
        waiting_for_transcript.value = false;
        show_slow_transcription.value = false;
    }
};

const handleRecordingComplete = (recording) => {
    markOnboardChecklistItemComplete(ONBOARDING_TASK.MIC);
    handleTranscribeRecording(recording);
};

const handleRecordingError = () => {
    chatError.value = CHAT_ERROR.RECORDING_DEFAULT;
};

const handleRecordingStart = () => {
    chatError.value = null;
    dismissSuggestedScenarios.value = true;
    has_taken_action.value = true;
};

const chatMessageListRef = useTemplateRef("chatMessageList");

const scrollToBottom = () => {
    /*
     * Delegate scroll to the next tick as this function
     * is invoked at the same time that messages state is mutated.
     * Our scrolling logic depends on DOM elements, so we make sure
     * that this runs when Vue has reconciled the DOM state.
     */
    nextTick(() => {
        if (chatMessageListRef.value) {
            chatMessageListRef.value.scrollToLastMessage();
        }
    });
};

const throttledScrollToBottom = useThrottleFn(scrollToBottom, SCROLL_THROTTLE_MS);

const startSlowResponseTimeout = (timeout_ms = SLOW_MESSAGE_TIMEOUT_MS) => {
    clearSlowResponseTimeout();

    show_slow_response.value = false;
    slow_response_timeout.value = setTimeout(() => {
        startFailedResponseTimeout();
        show_slow_response.value = true;
        logUserInteraction("saw_slow_message", {}, cs_id);
    }, timeout_ms);
};

const clearSlowResponseTimeout = () => {
    clearFailedResponseTimeout();
    show_slow_response.value = false;
    clearTimeout(slow_response_timeout.value);
};

const startFailedResponseTimeout = (timeout_ms = FAILED_MESSAGE_TIMEOUT_MS) => {
    clearFailedResponseTimeout();

    failed_response_timeout.value = setTimeout(() => {
        show_failed_response.value = true;
        logUserInteraction("saw_failed_message", {}, cs_id);
    }, timeout_ms);
};

const clearFailedResponseTimeout = () => {
    show_failed_response.value = false;
    clearTimeout(failed_response_timeout.value);
};

const markOnboardChecklistItemComplete = (taskId) => {
    emitter.emit(CHAT_EVENT.MARK_ONBOARD_CHECKLIST, { taskId });
};

const checkForInChatFollowUp = (messages) => {
    if (!Array.isArray(messages)) {
        return;
    }
    for (const message of messages) {
        if (!Array.isArray(message.lines)) {
            continue;
        }
        for (const [lineIdx, line] of message.lines.entries()) {
            if (line.action_name === "followup" && line.action_params && !line.action_params.event_at_confirmed && !line.action_state.dismissed) {
                inChatFollowUp.value = { line, messageId: message.chat_message_id, role: message.role, lineIdx };
                showInChatFollowUp.value = true;
                break;
            } else {
                showInChatFollowUp.value = false;
            }
        }
    }
};

const handleToggleWidgetSidebar = () => {
    showWidgetSidebar.value = !showWidgetSidebar.value;
};

const handleCoachingModeUpdate = (payload) => {
    document.dispatchEvent(new CustomEvent("coaching_mode_updated", { detail: payload }));
};

const handleUserSwitchedCoachingMode = async (payload) => {
    await $sendEvent("action", {
        data: {
            type: "coaching_mode",
            payload: {
                requested_coaching_mode: payload.newCoachingMode,
                switch_message: payload.switchMessage,
                websocket_channel_id: ws_url,
            },
        },
    });
    logUserInteraction(
        "switched_coaching_mode",
        {
            switched_from: payload.oldCoachingMode,
            switched_to: payload.newCoachingMode,
        },
        cs_id,
    );
};

const handleTeamMemberContext = () => {
    useChatStore.setHasTeamMemberContext(true);
};

const handleFailedResponseReload = () => {
    document.location.reload();
};

document.addEventListener("visibilitychange", handleVisibility, false);
window.addEventListener("beforeunload", handleUnload);
</script>
