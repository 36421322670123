<template>
    <Bubbles />
    <div class="pt-16 pb-10 px-6 sm:px-10 h-screen antialiased overflow-y-auto">
        <div class="flex flex-col items-center gap-2 sm:gap-3">
            <div class="w-16 h-16">
                <CoachingModeMarble :is-loading="!hasFetchedData" />
            </div>
            <TransitionGroup name="fade">
                <template v-for="(content, i) of headerPerPage" :key="i">
                    <h3 v-if="currentPageIndex === i" class="font-semibold text-2xl text-[#555BA2] tracking-tighter sm:mb-3 text-center px-4 header">{{ content }}</h3>
                </template>
            </TransitionGroup>
            <TransitionGroup name="fade">
                <div
                    v-if="currentStep !== WRAPPED_STEPS.SUMMARY"
                    class="flex flex-col items-center justify-center text-center rounded-xl p-5 sm:p-10 gap-6 sm:border-2 sm:border-[#F5F5F5] sm:shadow-lg sm:bg-white mx-6 w-full lg:w-fit"
                    :class="{ 'min-h-[600px]': currentStep === WRAPPED_STEPS.SLIDES }"
                >
                    <div v-if="currentStep === WRAPPED_STEPS.SLIDES" class="gap-6 flex flex-col justify-between grow w-full lg:w-fit">
                        <template v-for="(content, i) of wrappedSlides" :key="i">
                            <Transition name="fade">
                                <div v-show="currentPageIndex === i" class="flex grow overflow-x-auto" :class="[content.length === 1 ? 'justify-center' : 'justify-between']">
                                    <div
                                        v-for="({ images, title, text, call_to_action, page }, index) of content"
                                        :key="index"
                                        class="flex flex-col justify-center items-center min-w-80 w-80 gap-4"
                                    >
                                        <div class="flex flex-col justify-center">
                                            <div class="flex grow justify-center items-center">
                                                <img :src="images[index]" class="mb-10" />
                                            </div>
                                            <h3 v-if="title" class="font-semibold text-2xl tracking-tighter mb-3">{{ title }}</h3>
                                            <p v-if="text" class="text-base tracking-tight" :class="[title ? 'font-medium text-[#8C8C8C]' : 'font-semibold']">
                                                {{ text }}
                                            </p>
                                        </div>
                                        <BaseButton
                                            v-if="call_to_action?.url && call_to_action?.title"
                                            theme="primary"
                                            class="whitespace-nowrap"
                                            @click="handleCTAClick(call_to_action.url)"
                                        >
                                            <i class="bi bi-bookmark"></i> {{ call_to_action.title }}
                                        </BaseButton>
                                        <BaseButton v-else-if="hasFetchedData && page === PAGES.FULL_YEAR" theme="primary" class="whitespace-nowrap" @click="handleWrapUp">
                                            Wrap up
                                        </BaseButton>
                                    </div>
                                </div>
                            </Transition>
                        </template>
                        <div v-if="hasFetchedData" class="flex gap-10 items-center justify-center">
                            <button
                                :disabled="currentPageIndex === PAGES.COACHING_SESSIONS"
                                class="rounded-full flex items-center justify-center p-1.5 w-8 h-8 bg-slate-300 disabled:opacity-50"
                                @click="handleCurrentPageChange(currentPageIndex - 1)"
                            >
                                <i class="bi bi-arrow-left text-xl text-[#555BA2] iconBold"></i>
                            </button>
                            <span class="font-semibold text-[#555BA2]">{{ currentPageIndex + 1 }} of {{ wrappedSlides.length }}</span>
                            <button
                                :disabled="currentPageIndex === PAGES.FULL_YEAR || currentPageIndex === wrappedSlides.length - 1"
                                class="rounded-full flex items-center justify-center p-1.5 w-8 h-8 bg-slate-300 disabled:opacity-50"
                                @click="() => handleCurrentPageChange(currentPageIndex + 1)"
                            >
                                <i class="bi bi-arrow-right text-xl text-[#555BA2] iconBold"></i>
                            </button>
                        </div>
                        <div v-else class="h-8 flex justify-center w-full overflow-hidden">
                            <LoadingSpinner />
                        </div>
                    </div>
                    <template v-else-if="currentStep === WRAPPED_STEPS.SCHEDULE">
                        <ScheduleFollowUp :recurring="recurring" :date="date" @recurring-updated="onRecurringUpdate" @date-update="onDateUpdate" />
                        <div class="flex flex-col gap-4">
                            <BaseButton theme="primary" @click="handleSchedule">Sounds good</BaseButton>
                            <BaseButton theme="secondary" @click="handleSkip">Skip</BaseButton>
                        </div>
                    </template>
                </div>
                <template v-else-if="currentStep === WRAPPED_STEPS.SUMMARY">
                    <div
                        v-if="summaryContent"
                        ref="summaryCard"
                        class="mb-5 flex flex-col gap-6 bg-[#F7FAFF] p-6 sm:p-10 rounded-xl shadow-lg relative max-w-[440px] gradientBorder"
                    >
                        <h3 class="col-span-2 font-semibold text-2xl tracking-tighter">2025 <span class="text-[#8C8C8C]">with Nadia</span></h3>
                        <div class="grid grid-cols-2 grid-rows-2 gap-6 text-lef">
                            <div>
                                <p class="font-semibold text-[#8C8C8C]">2025 vibes</p>
                                <h3 v-for="persona of summaryContent.content.persona" :key="persona" class="text-2xl font-semibold tracking-tighter">
                                    {{ persona }}
                                </h3>
                            </div>
                            <div>
                                <p class="font-semibold text-[#8C8C8C]">Superpower</p>
                                <p class="font-semibold text-base tracking-tight">{{ summaryContent.content.superpower }}</p>
                            </div>
                            <div>
                                <p class="font-semibold text-[#8C8C8C]">Wins from 2024</p>
                                <p class="font-semibold text-base tracking-tight">{{ user_conversation_count }} coaching sessions</p>
                                <p class="font-semibold text-base tracking-tight">{{ action_items_count }} action items</p>
                                <p class="font-semibold text-base tracking-tight">{{ new_topics_count }} new topics</p>
                            </div>
                            <div v-if="summaryContent.content.top_insight">
                                <p class="font-semibold text-[#8C8C8C]">Top Insight</p>
                                <p class="font-semibold text-base tracking-tight">{{ summaryContent.content.top_insight }}</p>
                            </div>
                        </div>
                        <img :src="Logo" alt="Valence logo" class="max-w-24" />
                    </div>
                    <div class="flex flex-col sm:flex-row gap-2">
                        <BaseButton theme="primary" @click="downloadImage"><i class="bi bi-download"></i> Download</BaseButton>
                        <BaseButton theme="primary" class="!bg-[#0274B3]" @click="handleLinkedInShare"> <i class="bi bi-linkedin"></i> Share to Linkedin </BaseButton>
                    </div>
                    <BaseButton theme="secondary" @click="handleGoToHome">Go to Home</BaseButton>
                </template>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import Logo from "~img/logo.png";
import etSrc from "~img/wrapped/et.png";
import flagSrc from "~img/wrapped/flag.png";
import homeOfficeSrc from "~img/wrapped/home-office.png";
import laptopSrc from "~img/wrapped/laptop.png";
import purpleSrc from "~img/wrapped/purple.png";
import readingSrc from "~img/wrapped/reading.png";
import rocketSrc from "~img/wrapped/rocket.png";
import scooterSrc from "~img/wrapped/scooter.png";
import selfieSrc from "~img/wrapped/selfie.png";
import { useFetch } from "~vue/api";
import BaseButton from "~vue/components/BaseButton.vue";
import Bubbles from "~vue/components/Bubbles.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import ScheduleFollowUp from "~vue/ScheduleFollowUp.vue";
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { toPng } from "html-to-image";
import { DateTime } from "luxon";
import { computed, inject, onMounted, ref, useTemplateRef, watch } from "vue";

const { $sendEvent } = inject("globalProperties");

const WRAPPED_STEPS = {
    SLIDES: "slides",
    SCHEDULE: "schedule",
    SUMMARY: "summary",
};

const PAGES = {
    COACHING_SESSIONS: 0,
    IMPACT: 1,
    LEARNINGS: 2,
    TOPICS: 3,
    FULL_YEAR: 4,
    SCHEDULE: 5,
    SUMMARY: 6,
};

const { user_conversation_count, user_details } = defineProps({
    user_conversation_count: { type: Number, default: 0 },
    action_items_count: { type: Number, default: 0 },
    new_topics_count: { type: Number, default: 0 },
    user_details: { type: Object, required: true },
});

onMounted(() => {
    logUserInteraction("wrapped_screen_view", { screen: currentPageIndex.value });
});

// hardcoded mapping of images to page number
const getImagesByPage = (page) => {
    switch (+page) {
        case 0:
            return [homeOfficeSrc];
        case 1:
            return [selfieSrc, purpleSrc, etSrc];
        case 2:
            return [purpleSrc, laptopSrc, scooterSrc];
        case 3:
            return [flagSrc, readingSrc, selfieSrc];
        case 4:
            return [rocketSrc];
        default:
            return [];
    }
};

const { isFetching, data } = useFetch("/accounts/wrapped/?year=2024");
const pageData = ref([
    {
        content: {
            title: [`You had ${user_conversation_count} coaching sessions last year!`],
            text: ["Imagine what we can do if we keep this going"],
        },
        page: 0,
        type: "page",
    },
]);
watch(data, (value) => {
    if (value) {
        const newData = JSON.parse(value);
        pageData.value.push(...newData);
    }
});

const hasFetchedData = computed(() => !isFetching.value);
const currentStep = ref(WRAPPED_STEPS.SLIDES);

const userName = computed(() => user_details.first_name);
const headerPerPage = computed(() => [
    userName.value ? `${userName.value}, I've been keeping tabs...` : "I've been keeping tabs...",
    "In 2024, you covered a lot and should feed proud!",
    "You've been quietly collecting wins - let's take a look",
    "In 2025, let's continue to level up",
    "Let's set the vision for your 2025",
    "Time to commit and make it real",
    "Your 2025 vision with Nadia",
]);
const wrappedSlides = computed(() =>
    pageData.value
        .filter(({ type }) => type === "page")
        .map(({ page, content, call_to_action, type }) =>
            content.text
                .filter((e) => e)
                .map((text, index) => ({
                    title: content.title?.[index] ?? content.title,
                    text,
                    images: getImagesByPage(page),
                    call_to_action,
                    type,
                    page,
                })),
        ),
);
const topInsight = computed(() => pageData.value.find(({ page }) => page === PAGES.LEARNINGS)?.content.text[0]);
const summaryContent = computed(() => {
    const summaryData = pageData.value.find(({ type }) => type === "summary");
    if (summaryData?.content) {
        return { ...summaryData, content: { ...summaryData.content, top_insight: topInsight } };
    } else {
        return summaryData;
    }
});

const currentPageIndex = ref(PAGES.COACHING_SESSIONS);
const handleCurrentPageChange = (page) => {
    if (page < 0 || page > wrappedSlides.value.length) {
        return;
    }
    currentPageIndex.value = page;
    logUserInteraction("wrapped_screen_view", { screen: currentPageIndex.value });
};

const handleCTAClick = (url) => {
    logUserInteraction("hold_me_accountable_click", { screen: currentPageIndex.value });
    openUrl(url, { target: "_blank" });
};

const handleWrapUp = () => {
    currentStep.value = WRAPPED_STEPS.SCHEDULE;
    currentPageIndex.value = PAGES.SCHEDULE;
};

const DEFAULT_EVENT_DESCRIPTION = "Let's discuss what's coming up for you this week.";
const recurring = ref(false);
const date = ref(new Date());

const onDateUpdate = (newDate) => {
    date.value = newDate;
};
const onRecurringUpdate = (toggled) => {
    recurring.value = toggled;
};

const handleSchedule = async () => {
    try {
        await $sendEvent("schedule_follow_up", {
            event_description: DEFAULT_EVENT_DESCRIPTION,
            event_at_confirmed: DateTime.fromJSDate(date.value).toISO(),
            recurring: recurring.value,
            type: "followup",
        });
        logUserInteraction("wrapped_checkin_scheduled");
    } catch (e) {
        if ("Sentry" in window) {
            window.Sentry.captureException(e);
        }
    }

    currentStep.value = WRAPPED_STEPS.SUMMARY;
    currentPageIndex.value = PAGES.SUMMARY;
};

const handleSkip = () => {
    currentStep.value = WRAPPED_STEPS.SUMMARY;
    currentPageIndex.value = PAGES.SUMMARY;
};

const summaryCard = useTemplateRef("summaryCard");
const downloadImage = () => {
    if (summaryCard.value) {
        toPng(summaryCard.value, { style: { margin: "0px" } }).then(async (dataUrl) => {
            const a = document.createElement("a");
            a.href = dataUrl;
            a.download = "2024-nadia-summary.png";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
        logUserInteraction("wrapped_shared", { platform: "download" });
    }
};

const handleLinkedInShare = () => {
    // note, the text param in the link contains a bunch of unicode for displaying "Upload Image" in bold (sans font)
    openUrl(
        "https://www.linkedin.com/feed/?shareActive=true&text=Here%20is%20my%20review%20with%20Nadia,%20my%20favorite%20AI.%20%F0%9D%97%A8%F0%9D%97%BD%F0%9D%97%B9%F0%9D%97%BC%F0%9D%97%AE%F0%9D%97%B1%20%F0%9D%97%9C%F0%9D%97%BA%F0%9D%97%AE%F0%9D%97%B4%F0%9D%97%B2",
        { target: "_blank" },
    );
    logUserInteraction("wrapped_shared", { platform: "linkedin" });
};

const startTime = Date.now();
const handleGoToHome = () => {
    openUrl("/");
    logUserInteraction("wrapped_completed", { completion_time: (Date.now() - startTime) / 1000 });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    display: none;
    position: absolute;
}

.gradientBorder::after {
    content: "";
    @apply rounded-xl absolute inset-0 border-2 border-transparent z-0;
    background: linear-gradient(var(--rotate), #ff238f 0%, #555ba2 100%) border-box;
    mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.header {
    min-height: 2lh;
}

.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
