<template>
    <template v-for="item in SETTINGS_MENU" :key="item.id">
        <button
            v-if="item.type === 'pane'"
            type="button"
            class="cursor-pointer text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-5 leading-none"
            @click="$emit('openPane', item)"
        >
            <span class="h-4 w-4"><component :is="item.icon" /></span>
            {{ item.title }}
        </button>
        <a
            v-else-if="item.type === 'url'"
            :href="item.url"
            class="text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-4 leading-none mb-4 md:mb-0"
        >
            <span class="h-4 w-4"><component :is="item.icon" /></span>
            {{ item.title }}
        </a>
    </template>
</template>

<script setup>
import AlertCircle from "~vue/icons/AlertCircle.vue";
import Logout from "~vue/icons/Logout.vue";
import { isInIFrame } from "~vue/utils.js";
import { inject, ref } from "vue";

const globalProperties = inject("globalProperties");

defineProps({
    isOpen: {
        type: Boolean,
        default: () => false,
    },
});

defineExpose({
    hidePopover,
});

defineEmits(["openPane"]);

const SETTINGS_MENU = [
    {
        id: "feedback",
        title: "Report an Issue",
        type: "url",
        url: globalProperties.$feedbackMailto,
        icon: AlertCircle,
    },
    {
        id: "logout",
        title: "Logout",
        type: "url",
        url: "/accounts/logout/",
        icon: Logout,
    },
];

if (isInIFrame()) {
    SETTINGS_MENU.pop();
}

const popover = ref(null);

function hidePopover() {
    popover.value.hidePopover();
}
</script>
