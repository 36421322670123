<template>
    <BaseNotification v-if="!dismissed" theme="primary" @dismiss="handleInferredAnswerEvent(EVENT_TYPES.DISMISS)">
        <template #content>
            <div class="flex gap-4">
                <template v-if="line">
                    <!-- icon -->
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4596_8)">
                            <path d="M8 12V14.6667" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.8267 10.8267L12.7133 12.7133" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.28668 12.7133L5.17335 10.8267" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8H14.6667" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.33334 8H4.00001" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.8267 5.17333L12.7133 3.28667" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.28668 3.28667L5.17335 5.17333" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 1.33334V4" stroke="#555BA2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4596_8">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </template>
                <div class="w-full font-semibold">
                    <template v-if="line">
                        <div class="relative flex flex-col md:flex-row md:items-center md:justify-between">
                            <p class="flex-grow font-semibold text-base">
                                {{ notificationHeader }}
                            </p>
                            <button
                                v-if="!showInferredAnswer"
                                type="button"
                                class="underline md:ml-4 md:flex-shrink-0 pr-4"
                                @click="() => (showInferredAnswer = !showInferredAnswer)"
                            >
                                Review
                            </button>
                        </div>
                        <div class="relative mx-3 rounded-lg leading-none flex flex-col gap-3">
                            <div v-if="showInferredAnswer" class="mt-4">
                                <h3 class="capitalize text-md">{{ formattedKey }}:</h3>
                                <p v-if="!editInferredAnswer" class="mb-4 text-gray-800 leading-normal">{{ inferredAnswer }}</p>
                                <div v-else class="mt-2 rounded-xl py-2 pr-10 pl-4 bg-white flex flex-row gap-4 items-center shadow-sm">
                                    <textarea
                                        v-model="inferredAnswer"
                                        class="grow focus:outline-none leading-normal text-base text-gray-700"
                                        :placeholder="inferredAnswer"
                                        autofocus=""
                                        autocomplete="off"
                                    ></textarea>
                                </div>
                                <div class="flex justify-between mt-4 font-medium">
                                    <button
                                        v-if="!editInferredAnswer"
                                        type="button"
                                        class="px-8 py-2 border border-[#555BA2] text-[#555BA2] rounded-full"
                                        @click="() => (editInferredAnswer = !editInferredAnswer)"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="px-8 py-2 border border-[#555BA2] text-[#555BA2] rounded-full"
                                        @click="handleInferredAnswerEvent(EVENT_TYPES.EDIT)"
                                    >
                                        Save
                                    </button>
                                    <button type="button" class="px-4 py-2 text-[#555BA2] underline" @click="handleInferredAnswerEvent(EVENT_TYPES.DELETE)">Delete</button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </BaseNotification>
</template>

<script setup>
import BaseNotification from "~vue/components/BaseNotification.vue";
import { CHAT_EVENT } from "~vue/events.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, defineProps, inject, onMounted, ref } from "vue";

const EVENT_TYPES = {
    DELETE: "delete",
    EDIT: "edit",
    DISMISS: "dismiss",
};

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const props = defineProps({
    messageId: { type: Number, required: true },
    lineIdx: { type: Number, required: true },
    line: { type: Object, required: true },
    custom: { type: Boolean },
});

// Util
const formatKey = (key) => {
    if (!key) {
        return "";
    }
    return key.replace(/_/g, " ");
};

// Refs
const showInferredAnswer = ref(false);
const editInferredAnswer = ref(false);
const line = ref(props.line);
const dismissed = ref(line.value.action_state?.dismissed);
const inferredAnswer = ref(line.value.action_params?.inferred_answer);

// Computed
const formattedKey = computed(() => {
    return formatKey(line.value.action_params?.key);
});

const notificationHeader = ref(`I just learned about your ${formattedKey.value}, I'll remember this from now on.`);

// Methods
const dismiss = () => {
    dismissed.value = true;
};

const handleInferredAnswerEvent = (eventType) => {
    let actionState = { ...line.value.action_state };
    let actionParams = { ...line.value.action_params, inferred_answer: inferredAnswer.value };
    let subaction = eventType;

    if (eventType === EVENT_TYPES.EDIT) {
        // for visual purposes
        editInferredAnswer.value = false;
        notificationHeader.value = "Updated! This has been saved to your profile.";
        logUserInteraction("inferred_profile_answer_edit", {}, coachingSessionId);
    } else if (eventType === EVENT_TYPES.DELETE) {
        logUserInteraction("inferred_profile_answer_delete", {}, coachingSessionId);
    }

    emitter.emit("inferred_profile_answer", {
        action_state: actionState,
        action_params: actionParams,
        message_id: props.messageId,
        lineIdx: props.lineIdx,
        subaction: subaction,
    });

    setTimeout(() => {
        dismiss();
        emitter.emit("profile_notification_changed", {});
    }, 750);
};

onMounted(() => {
    emitter.emit("profile_notification_changed", {});
    emitter.emit(CHAT_EVENT.INFERENCE_MADE, {});
});
</script>
