<template>
    <div class="flex flex-col gap-5 my-5">
        <p v-if="tool_call.parameters.preamble">{{ tool_call.parameters.preamble }}</p>
        <ul class="flex gap-5 flex-col !ml-0">
            <template v-for="(point, idx) in tool_call.parameters.bullet_points" :key="idx">
                <Transition name="fade" appear>
                    <li :key="idx" class="bg-white p-4 list-none rounded-xl flex flex-col gap-4">
                        <h3 class="text-xl font-bold">{{ point.title }}</h3>
                        <p>{{ point.content }}</p>
                        <div class="flex justify-between">
                            <BaseButton theme="primary" :disabled="coachedOn != null" @click="coachMe(point.content, idx)"> Coach me on this </BaseButton>
                            <button :disabled="savedForLater.includes(idx)" class="italic text-sm text-valence-grey-400 cursor-pointer" @click="saveForLater(point.content, idx)">
                                <span v-if="savedForLater.includes(idx)">Saved for later</span>
                                <span v-else>Save this as a topic to revisit</span>
                                <i class="bi inline-block translate-y-0.5 text-lg ml-2" :class="savedForLater.includes(idx) ? 'bi-bookmark-fill' : 'bi-bookmark'"></i>
                            </button>
                        </div>
                    </li>
                </Transition>
            </template>
        </ul>
        <p v-if="tool_call.parameters.closing">{{ tool_call.parameters.closing }}</p>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import { inject, ref } from "vue";

const coachingSessionId = inject("coachingSessionId");
const { emitter } = inject("globalProperties");

const { tool_call, messageId, lineIdx } = defineProps({
    tool_call: { type: Object, default: () => ({}) },
    messageId: { type: Number, default: 0 },
    lineIdx: { type: Number, default: 0 },
});

const coachedOn = ref(tool_call?.tool_state?.coach_me ?? null);
const savedForLater = ref(tool_call?.tool_state?.saved_topics ?? []);

const coachMe = (content, bullet_index) => {
    coachedOn.value = bullet_index;

    emitter.emit("coachable_bullet_points_coach_me", {
        action_params: { coach_me: { content, bullet_index } },
        message_id: messageId,
        lineIdx,
        coaching_session_id: coachingSessionId,
    });
};

const saveForLater = (content, bullet_index) => {
    savedForLater.value.push(bullet_index);

    emitter.emit("coachable_bullet_points_save_for_later", {
        action_params: { save_for_later: { content, bullet_index } },
        message_id: messageId,
        lineIdx,
        coaching_session_id: coachingSessionId,
    });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1000 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
