<template>
    <div id="chat-container__stream" :class="{ 'hidden md:flex': showWidgetSidebar }" class="grow overflow-y-auto md:flex snap-y scroll-pb-5 md:scroll-pb-10">
        <div class="w-full chat_component rounded-xl round md:max-w-screen-md md:mx-auto">
            <div class="relative messages px-4 sm:mt-8 md:px-8">
                <div class="relative">
                    <div class="blurEffect"></div>
                    <SplashScreen :name="userFirstName" />
                    <Transition name="fade">
                        <div v-if="!showStartLoadingMessage" class="fs-mask" data-cy="chat-stream">
                            <template v-for="(message, idx) in messages" :key="idx">
                                <template v-for="(line, lineIdx) in message.lines" :key="lineIdx">
                                    <div v-if="isUserMessageLineOrFileUpload(line)" class="chat group/chat" :class="message.role === 'user' ? 'chat-end' : 'chat-start'">
                                        <div class="flex gap-2" :class="{ 'flex-row': message.role === 'user', 'flex-col md:flex-row': message.role === 'assistant' }">
                                            <div v-if="message.role === 'assistant'" class="min-w-12 max-w-12 min-h-12 max-h-12 -mb-4 md:mb-0 mt-4 -mr-2">
                                                <CoachingModeMarble />
                                            </div>
                                            <div
                                                v-else-if="message.role === 'user' && line.type !== 'action' && userName"
                                                class="min-w-10 max-w-10 min-h-10 max-h-10 rounded-full font-semibold text-base text-[#555BA2] bg-[#E0E9FF] flex items-center justify-center order-last"
                                            >
                                                {{ getFirstLastInitials(userName) }}
                                            </div>
                                            <div
                                                v-if="line.type != 'action'"
                                                :ref="(el) => (messageRefs[refForMessage(message.chat_message_id)] = el)"
                                                class="max-w-full px-4 prose-p:my-4 relative ease-in-out duration-500 transition-colors"
                                                :class="{
                                                    'text-left rounded-3xl rounded-tr-lg border-2 border-[#E8E8E8]': message.role === 'user',
                                                    'bg-white': message.role === 'user' && message.chat_message_id !== focusedMessage,
                                                    'bg-valence-purple/10': message.chat_message_id === focusedMessage,
                                                }"
                                            >
                                                <article class="prose w-full font-medium text-base md:text-2xl tracking-tight md:tracking-tighter text-left">
                                                    <ChatMessageText
                                                        v-if="line.type === 'md'"
                                                        :message="line.content"
                                                        :animate="shouldAnimateMessageText(message, idx)"
                                                        @finished-rendering="handleLineRendered(message, idx, lineIdx)"
                                                    />
                                                    <div v-if="line.type == 'html'" data-cy="chat-stream_message" v-html="line.content" />
                                                    <ChatMessageReactionBar
                                                        v-if="shouldIncludeReactionBar(message, lineIdx)"
                                                        :message="message"
                                                        :line-idx="lineIdx"
                                                        :show-thought-process="idx === messages.length - 1"
                                                        class="invisible group-hover/chat:visible"
                                                        @thought-process-clicked="handleThoughtProcessClicked"
                                                    />
                                                </article>
                                                <Transition name="fade">
                                                    <template v-if="showRolePlayInsightButton(message)">
                                                        <button
                                                            type="button"
                                                            class="bg-[#F7FAFF]/75 hover:bg-[#F7FAFF] text-sm font-medium mb-2 px-2 py-1 rounded-full text-[#555BA2] border-2 border-[#555BA266]"
                                                            @click="focusRolePlayFeedback(message.chat_message_id)"
                                                        >
                                                            View insight
                                                        </button>
                                                    </template>
                                                </Transition>
                                            </div>
                                        </div>

                                        <div
                                            v-if="isLastIndexOfUserMessage(message.lines, lineIdx) && hasFileActionInLines(message.lines)"
                                            class="flex my-1 gap-2 justify-end row-start-2"
                                        >
                                            <div
                                                v-if="isOnlyFileUploadActionInLines(message.lines) && userName"
                                                class="min-w-10 max-w-10 min-h-10 max-h-10 rounded-full font-semibold text-base text-[#555BA2] bg-[#E0E9FF] flex items-center justify-center order-last"
                                            >
                                                {{ getFirstLastInitials(userName) }}
                                            </div>
                                            <div
                                                class="overflow-auto max-w-[425px] ml-auto flex gap-2"
                                                :class="{
                                                    'mr-0': isOnlyFileUploadActionInLines(message.lines),
                                                    'mr-12': !isOnlyFileUploadActionInLines(message.lines),
                                                }"
                                            >
                                                <FileAttachedTag
                                                    v-for="file in getFileActionInLines(message.lines).action_params"
                                                    :key="file.name"
                                                    :file-name="file.name"
                                                    :file-description="file.description"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <CoachableBulletPoints
                                        v-if="line.type === 'tool' && line.tool_call.tool_name == 'coachable_bullet_points'"
                                        :tool_call="line.tool_call"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                    />
                                    <CoachableBulletPointsMultiselect
                                        v-if="line.type === 'tool' && line.tool_call.tool_name == 'coachable_bullet_points_multiselect'"
                                        :tool_call="line.tool_call"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                    />
                                    <ChatActionAnswers
                                        v-if="shouldShowAnswers(line)"
                                        :data="line"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                        :role="message.role"
                                        class="mb-4"
                                    />
                                    <ChatActionScenarios
                                        v-if="shouldShowSuggestedScenarios(line)"
                                        :data="line"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                        :role="message.role"
                                        class="mb-4"
                                    />
                                    <ChatActionFocusAreas v-if="shouldShowFocusAreas(line)" :data="line" :message-id="message.chat_message_id" :line-idx="lineIdx" class="mb-4" />
                                    <ChatActionScheduledFollowUp
                                        v-if="shouldShowScheduledFollowUp(line)"
                                        :line="line"
                                        :message-id="message.chat_message_id"
                                        :line-idx="lineIdx"
                                        class="mb-4"
                                    />
                                    <Transition name="fade">
                                        <ChatNotificationMessage
                                            v-if="shouldShowNotification(message, line, lineIdx)"
                                            v-bind="getNotificationMessageProps(message, line, lineIdx)"
                                            class="mb-4"
                                        />
                                    </Transition>
                                    <Transition name="fade">
                                        <ChatInferredAnswerNotification
                                            v-if="shouldShowInferenceNotification(line)"
                                            :line="line"
                                            :line-idx="lineIdx"
                                            :message-id="message.chat_message_id"
                                            class="mb-4"
                                        />
                                    </Transition>
                                </template>
                            </template>
                        </div>
                    </Transition>
                    <CoachingModeMarble v-if="shouldShowLoadingMessageMarble" size="small" is-loading />
                    <ChatWaitingMessage v-else-if="showSlowResponse" class="my-4">One moment while I gather my thoughts. </ChatWaitingMessage>
                    <ChatLoadingMessage v-else-if="showStartLoadingMessage" class="my-4" :loading-message-stack="loadingMessageStack" @complete="handleLoadingMessageComplete" />
                    <div ref="bottomAnchor" class="pb-24 md:pb-16" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { getFirstLastInitials } from "~vue/utils/stringUtils";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

import ChatActionAnswers from "./ChatActionAnswers.vue";
import ChatActionFocusAreas from "./ChatActionFocusAreas.vue";
import {
    ACTION,
    getPreviousMessage,
    getRolePlayMonitorParams,
    isActionableLookbackAction,
    isLookbackAction,
    isRolePlayMessage,
    lineHasNotification,
    lineIsAction,
    lineIsDismissed,
} from "./chatActions.js";
import ChatActionScenarios from "./ChatActionScenarios.vue";
import ChatActionScheduledFollowUp from "./ChatActionScheduledFollowUp.vue";
import SplashScreen from "./ChatDetail/SplashScreen.vue";
import ChatInferredAnswerNotification from "./ChatInferredAnswerNotification.vue";
import ChatLoadingMessage from "./ChatLoadingMessage.vue";
import ChatMessageReactionBar from "./ChatMessageReactionBar.vue";
import ChatMessageText from "./ChatMessageText.vue";
import ChatNotificationMessage from "./ChatNotificationMessage.vue";
import ChatWaitingMessage from "./ChatWaitingMessage.vue";
import FileAttachedTag from "./components/FileAttachedTag.vue";
import CoachingModeMarble from "./components/navigation/CoachingModeMarble.vue";
import { CHAT_EVENT } from "./events.js";
import CoachableBulletPoints from "./StructuredOutputs/CoachableBulletPoints.vue";
import CoachableBulletPointsMultiselect from "./StructuredOutputs/CoachableBulletPointsMultiselect.vue";

const coachingSessionId = inject("coachingSessionId");
const { emitter } = inject("globalProperties");
const {
    loadingMessageStack,
    dismissSuggestedScenarios,
    messages,
    numberOfMessagesAtInit,
    showSlowResponse,
    showStartLoadingMessage,
    showWidgetSidebar,
    followUpNotificationData,
    userFirstName,
    userLastName,
} = defineProps({
    loadingMessageStack: { type: Array, default: () => [] },
    dismissSuggestedScenarios: { type: Boolean },
    messages: { type: Array, default: () => [] },
    numberOfMessagesAtInit: { type: Number, default: 0 },
    showSlowResponse: { type: Boolean },
    showStartLoadingMessage: { type: Boolean },
    showWidgetSidebar: { type: Boolean },
    followUpNotificationData: { type: Object, default: () => ({}) },
    userFirstName: { type: String, default: undefined },
    userLastName: { type: String, default: undefined },
});

const emit = defineEmits(["loading-complete"]);

const MIN_MESSAGES_FOR_SUGGESTED_SCENARIOS = 2;

const focusedMessage = ref(null);
const renderedLines = ref(new Set());

watch(
    () => followUpNotificationData,
    () => {
        logUserInteraction("follow_up_notification_presented", {}, coachingSessionId);
    },
);

const shouldShowLoadingMessageMarble = ref(false);

watch(
    () => messages,
    () => {
        if (messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            if (latestMessage.role === "user" && !latestMessage.hidden) {
                shouldShowLoadingMessageMarble.value = true;
            } else if (latestMessage.role === "assistant") {
                shouldShowLoadingMessageMarble.value = false;
            }
        }
    },
);

onMounted(() => {
    emitter.on(CHAT_EVENT.FOCUS_CHAT_MESSAGE, handleFocusChatMessage);
});

onUnmounted(() => {
    emitter.off(CHAT_EVENT.FOCUS_CHAT_MESSAGE, handleFocusChatMessage);
});

const messageRefs = ref({});
const refForMessage = (chatMessageId) => `m-${chatMessageId}`;

const handleFocusChatMessage = ({ chatMessageId }) => {
    const messageRef = messageRefs.value[refForMessage(chatMessageId)];
    if (messageRef && messageRef[0] instanceof HTMLElement) {
        messageRef[0].scrollIntoView({ block: "center", behavior: "smooth" });
        focusedMessage.value = chatMessageId;
        nextTick(() => {
            setTimeout(() => {
                focusedMessage.value = null;
            }, 1000);
        });
    }
};

const showRolePlayInsightButton = (message) => {
    if (!isRolePlayMessage(message)) {
        return false;
    }

    const params = getRolePlayMonitorParams(message);
    return params && !!params.feedback;
};

const focusRolePlayFeedback = (chatMessageId) => {
    // Open the sidebar if it's closed, and focus the feedback
    // on the next tick.
    emitter.emit(CHAT_EVENT.FOCUS_WIDGET, { actionName: ACTION.ROLE_PLAY_MONITOR });
    nextTick(() => {
        emitter.emit(CHAT_EVENT.FOCUS_ROLE_PLAY_MESSAGE_FEEDBACK, chatMessageId);
    });
};

const getMessageIdLineIdxStr = (messageId, lineIdx) => `${messageId}-${lineIdx}`;
const handleLoadingMessageComplete = () => emit("loading-complete");
const handleLineRendered = (message, messageIndex, lineIdx) => {
    renderedLines.value.add(getMessageIdLineIdxStr(message.id, lineIdx));

    /*
     * If current message is being animated, we scroll once more when we've
     * finished rendering it. It is not enough for the parent component to scroll the
     * list when message partials are received as the DOM will not yet have the
     * entire message rendered.
     */
    if (shouldAnimateMessageText(message, messageIndex)) {
        scrollToLastMessage();
    }
};

const handleThoughtProcessClicked = (message) => {
    emitter.emit(CHAT_EVENT.OPEN_THOUGHT_PROCESS_DIALOG, message.id);
};

const shouldAnimateMessageText = (message, messageIndex) => {
    /* Only animate a message from the LLM that is being
     * streamed in real-time.
     */
    if (message.role !== "assistant") {
        return false;
    }

    if (messageIndex < numberOfMessagesAtInit) {
        return false;
    }

    return messageIndex >= messages.length - 1;
};

const shouldShowAnswers = (line) => lineIsAction(line) && line.action_name === ACTION.ANSWERS;
const shouldShowFocusAreas = (line) => lineIsAction(line) && line.action_name === ACTION.FOCUS_AREA_PICKER && !line.action_state.dismissed;
const shouldShowInferenceNotification = (line) => line && line.action_name === ACTION.INFERRED_PROFILE_ANSWER;
const shouldShowSuggestedScenarios = (line) =>
    lineIsAction(line) && line.action_name === ACTION.SCENARIOS && !dismissSuggestedScenarios && messages.length <= MIN_MESSAGES_FOR_SUGGESTED_SCENARIOS;
const shouldShowScheduledFollowUp = (line) =>
    lineIsAction(line) && line.action_name === ACTION.FOLLOW_UP && line.action_params?.event_at_confirmed && line.action_state.submitted && !line.action_state.dismissed;
const shouldIncludeReactionBar = (message, lineIdx) => {
    if (!message.lines) {
        return;
    }
    const line = message.lines[lineIdx];
    if (!line) {
        return;
    }
    const isCoach = message.role === "assistant";
    const isTextLine = line.type === "md" || line.type === "html";
    const isFinishedRendering = renderedLines.value.has(getMessageIdLineIdxStr(message.id, lineIdx));
    return !line.hidden && isCoach && isTextLine && isFinishedRendering;
};
const hasFileActionInLines = (lines) => lines.some((l) => l?.type === "action" && l?.action_name === ACTION.FILE_UPLOAD);
const isUserMessageLineOrFileUpload = (line) => {
    // these are the messages that need to show a user avatar next to it
    return !line.hidden && (line.type !== "action" || line.action_name === ACTION.FILE_UPLOAD) && line.type !== "tool";
};
const isLastIndexOfUserMessage = (lines, idx) => lines.findLastIndex((l) => isUserMessageLineOrFileUpload(l)) === idx;
const getFileActionInLines = (lines) => lines.find((l) => l?.type === "action" && l?.action_name === ACTION.FILE_UPLOAD && l?.action_params.length > 0);
const isOnlyFileUploadActionInLines = (lines) => {
    if (lines.length === 1) {
        const line = lines[0];
        return line.type === "action" && line.action_name === ACTION.FILE_UPLOAD;
    }
    return false;
};
const userName = computed(() => {
    if (userFirstName && userLastName) {
        return `${userFirstName} ${userLastName}`;
    }
    return userFirstName ?? userLastName;
});

const shouldShowNotification = (message, line, lineIdx) => {
    const role = message.role;
    if (role === "assistant") {
        if (lineIdx !== 0) {
            // only show a lookback notification on the first line of the message to prevent duplicates
            return false;
        }
        const prevUserMessage = getPreviousMessage(messages, message.id, "user");
        if (!prevUserMessage) {
            // short circuits if there is no previous user message found
            return false;
        }
        return prevUserMessage.lines.find(isActionableLookbackAction) != null;
    } else if (role === "user") {
        return lineHasNotification(line) && !isLookbackAction(line) && !lineIsDismissed(line);
    }
    return false;
};

// function should only be called if the above shouldShowNotification function returns true
const getNotificationMessageProps = (message, line, lineIdx) => {
    const role = message.role;
    if (role === "assistant") {
        const prevMessage = getPreviousMessage(messages, message.id, "user");
        const prevLineWithLookbackActionIndex = prevMessage.lines.findIndex(isActionableLookbackAction);
        const prevLineWithLookbackAction = prevMessage.lines[prevLineWithLookbackActionIndex];
        return {
            line: prevLineWithLookbackAction,
            lineIdx: prevLineWithLookbackActionIndex,
            messageId: prevMessage.id,
        };
    }

    return {
        line,
        lineIdx,
        messageId: message.id,
    };
};

const bottom = useTemplateRef("bottomAnchor");
const scrollToLastMessage = () => {
    /*
     * We target an empty div at the end of the message list. This
     * div has some padding to add additional space when scrolling.
     * This is done to prevent cutting off the last message right
     * at the edge of the scrollable area.
     *
     * Why not target the last message instead? Besides having to maintain additional logic to
     * retrieve the DOM element of the last message, we want to keep the aforementioned
     * spacing without adding/removing it from individual message elements.
     */
    if (bottom.value) {
        bottom.value.scrollIntoView({ behavior: "smooth", block: "end" });
    }
};
defineExpose({ scrollToLastMessage });
</script>

<style scoped>
.chat_component .chat {
    :deep(article.prose p) {
        word-break: break-word;
    }
}

.chat_component .messages {
    height: 100%;
    max-height: 80vh;
}

.chat_component .text-valence-purple {
    color: #bc63e8;
}

.blurEffect {
    @apply z-10 sticky h-16 top-0 left-0 w-full;
    background: linear-gradient(180deg, #fff, transparent);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
