import { router } from "@inertiajs/vue3";
import DOMPurify from "dompurify";
import { marked } from "marked";

export function openUrl(url, { target } = {}) {
    if (target === "_blank") {
        window.open(url, target);
        return;
    }
    if (isInIFrame()) {
        if (target) {
            window.open(url, target);
        } else {
            window.location.href = url;
        }
    } else {
        router.visit(url);
    }
}

/**
 * checks if a current page is loaded in an iframe
 * used as a quick way to check if we are in a microsoft teams instance
 * if we ever load our app into an iframe for another reason this needs to be reworked
 *
 * @returns {boolean} true if the current page is loaded in an iframe
 */
export const isInIFrame = () => {
    return window.self !== window.top;
};

export function toMarkdown(content) {
    const html = marked(content);
    return DOMPurify.sanitize(html);
}
