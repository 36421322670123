<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.6767 0C13.3104 0 15.7755 1.04684 17.6507 2.92689C19.5681 4.89239 20.5794 7.49881 20.4951 10.3189C20.4319 13.0749 19.252 15.6172 17.2293 17.4759C13.4579 20.8941 7.62156 20.83 3.93436 17.3477C3.02837 16.4931 2.312 15.5104 1.78526 14.3994C1.63777 14.079 1.63777 13.7371 1.78526 13.4167C1.93275 13.0962 2.20665 12.8826 2.5227 12.7971C2.60698 12.7758 2.71232 12.7544 2.7966 12.7544C3.218 12.7544 3.61832 13.0108 3.80795 13.3953C4.31362 14.4635 5.05106 15.3822 5.99919 16.1299C8.75932 18.3091 12.7415 18.2663 15.4595 16.0231C17.2083 14.5703 18.2407 12.4339 18.2828 10.148C18.325 7.862 17.3768 5.68286 15.6912 4.16601C12.8258 1.62367 8.50648 1.60231 5.66208 4.16601C5.55673 4.25146 5.45139 4.35828 5.34604 4.4651L5.34603 4.4651L6.33631 5.46922C6.71556 5.85377 6.52593 6.51606 5.99919 6.64425L1.34279 7.73382C0.858192 7.862 0.394659 7.41335 0.521077 6.90062L1.59563 2.17914C1.72205 1.64504 2.37521 1.45276 2.75446 1.83732L3.76581 2.86279C5.61994 1.00411 8.06402 0 10.6767 0ZM9.20138 5.53347C9.20138 4.91391 9.68598 4.42253 10.297 4.42253C10.908 4.42253 11.4137 4.91391 11.3926 5.53347V9.89175L13.921 11.2591C14.4688 11.5582 14.6795 12.2418 14.3845 12.7759C14.1949 13.1605 13.8156 13.3741 13.4153 13.3741C13.2468 13.3741 13.0782 13.3314 12.9096 13.2459L9.79133 11.5582C9.43314 11.3659 9.20138 10.9813 9.20138 10.5754V5.53347Z"
            fill="black"
        />
    </svg>
</template>
