<template>
    <ChatWidgetFrame theme="blue" title="Action items from conversation" icon="bi-clipboard2" :minimized="data.action_state.minimized" @widget-toggle-visibility="toggleVisibility">
        <ActionItemsList :action-items="data.action_params.action_items" :message-id="messageId" :line-idx="lineIdx" :data="data" />
        <BaseButton v-if="!data.action_params.email_sent" @click="openEmailDialog"> Send me a summary </BaseButton>
        <div v-else class="text-xs text-center">{{ data.action_params.email_success_message }}</div>
    </ChatWidgetFrame>
    <ChatActionItemsEmailDialog
        v-if="!data.action_params.email_sent"
        ref="emailDialog"
        :summary="data.action_params.email_summary"
        :message-id="messageId"
        :line-idx="lineIdx"
        :message-action-line="data"
    />
</template>

<script setup>
import ActionItemsList from "~vue/ActionItemsList.vue";
import ChatActionItemsEmailDialog from "~vue/ChatActionItemsEmailDialog.vue";
import ChatWidgetFrame from "~vue/ChatWidgetFrame.vue";
import BaseButton from "~vue/components/BaseButton.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import { inject, useTemplateRef } from "vue";

const { emitter } = inject("globalProperties");
const coachingSessionId = inject("coachingSessionId");

const { data, messageId, lineIdx } = defineProps({
    data: { type: Object, default: () => ({}) },
    messageId: { type: Number, default: 0 },
    lineIdx: { type: Number, default: 0 },
});

const emailDialog = useTemplateRef("emailDialog");

function openEmailDialog() {
    emailDialog.value.open();
}

function toggleVisibility() {
    emitter.emit("action_items", {
        message_id: messageId,
        lineIdx: lineIdx,
        action_state: {
            minimized: !data.action_state.minimized,
        },
    });
    logUserInteraction("action_items_minimize", { minimized: !data.action_state.minimized }, coachingSessionId);
}
</script>
