<template>
    <Transition name="fade">
        <PermissionDialog :is-open="showMicPermissionAide" @cancel="handleDialogCancel" />
    </Transition>
    <div ref="cardStackRef" class="stacked-grid">
        <div :class="cardClass" class="stacked-grid-item">
            <template v-if="isCustomContent">
                <slot name="custom-content"></slot>
            </template>
            <template v-else-if="isAnswering">
                <div v-if="isQuestionPositionShown" class="text-center tracking-tighter font-semibold text-[#8C8C8C]">{{ currentQuestionPosition }} of {{ questionsCount }}</div>
                <h2 class="text-xl font-regular leading-tight text-[#262626]">{{ currentQuestion.text }}</h2>
                <div v-show="usingText" class="child-focus transition-colors w-full flex items-center gap-3 rounded-xl border-2 border-[#E8E8E8] px-4 py-4 md:px-6 md:py-10">
                    <textarea
                        ref="textareaRef"
                        v-model="text"
                        placeholder="Your answer here..."
                        :disabled="disabled"
                        class="bg-white grow disabled:opacity-75 focus:outline-none text-[#555BA2] text-base md:text-lg font-regular border-0"
                    ></textarea>
                    <button
                        type="button"
                        title="Submit"
                        :disabled="disabled"
                        class="shrink-0 text-xl md:text-3xl text-[#555BA2] hover:text-[#4B508F] disabled:opacity-50"
                        @click="handleText"
                    >
                        <i class="bi bi-arrow-right-circle" />
                    </button>
                </div>
                <Transition name="slide">
                    <VoiceLevels v-if="!usingText && isRecording" class="p-4" :levels="levelHistory" :levels-to-display="LEVELS_ELEMENTS"></VoiceLevels>
                </Transition>
                <div class="mx-auto flex flex-col gap-2">
                    <template v-if="!usingText">
                        <template v-if="isRecording">
                            <button type="button" class="button button-danger" @click="stopRecording">Stop recording</button>
                            <button type="button" class="button button-text" @click="cancelRecording">Cancel</button>
                        </template>
                        <template v-else>
                            <button
                                :disabled="isTranscribing || disabled"
                                type="button"
                                class="button button-primary"
                                @click="startRecording"
                                v-text="isTranscribing ? 'Transcribing...' : 'Share my thoughts'"
                            ></button>
                            <button v-if="supportsVoice && !isTranscribing" type="button" :disabled="disabled" class="button button-text" @click="usingText = true">
                                Switch to text
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <button v-if="supportsVoice" type="button" :disabled="disabled" class="button button-text" @click="usingText = false">Switch to voice</button>
                    </template>
                </div>
            </template>
            <template v-else>
                <div
                    class="md:block text-center font-semibold tracking-tighter text-2xl"
                    :class="[currentQuestion.offTopic ? 'text-[#262626]' : 'text-[#555BA2]', !isWithinAnswerTimeout && 'hidden']"
                >
                    Here&apos;s what I heard
                </div>
                <div
                    :class="!isWithinAnswerTimeout && 'hidden'"
                    class="md:block text-xl font-regular leading-tight text-[#262626] max-h-64 overflow-y-auto whitespace-pre-line"
                    v-text="questionAnswer"
                ></div>
                <div class="mx-auto flex flex-col gap-3">
                    <!-- On mobile, users see their answer for some seconds before the LLM answer is rendered. They can skip ahead using the button below. -->
                    <div :class="!isWithinAnswerTimeout && 'hidden'" class="md:hidden z-0 bg-[#555BA2] rounded-[20px]">
                        <button type="button" class="button button-primary button-timer" @click="emit('view-answer')">View answer</button>
                    </div>
                    <button
                        v-if="!currentQuestion.offTopic"
                        type="button"
                        :disabled="disabled"
                        :class="isWithinAnswerTimeout ? '!hidden md:!flex' : ''"
                        class="md:flex button button-primary"
                        @click="handleNextQuestion"
                        v-text="nextText"
                    ></button>
                    <button type="button" :disabled="disabled" class="button" :class="currentQuestion.offTopic ? 'button-primary' : 'button-text'" @click="handleMoreContext">
                        <i v-if="!usingText" class="bi bi-mic" />Give more context
                    </button>
                </div>
            </template>
        </div>
        <!-- The simulated "stacked cards" are not shown on mobile when the LLM answer is rendered -->
        <div :class="isAnsweredWithoutTimeout && '!hidden md:!flex'" class="md:flex rotate-[6deg] w-full h-full stacked-grid-item stacked-grid-item--question"></div>
        <div :class="isAnsweredWithoutTimeout && '!hidden md:!flex'" class="md:flex rotate-[-4deg] w-full h-full stacked-grid-item stacked-grid-item--question"></div>
    </div>
    <!-- Question progress is hidden on mobile while user is shown their answer before the LLM answer is rendered. Not to be confused with hideSteps, which is to disable the steps altogether.-->
    <div v-if="!hideSteps" :class="isAnsweredWithoutTimeout ? 'hidden' : 'flex'" class="md:flex bg-white md:bg-[#F5F5F5] rounded-full p-4 flex items-center gap-3">
        <template v-for="step in questionsCount * 2" :key="step">
            <Transition mode="out-in" name="expand">
                <div v-if="isAtStep(step)" class="indicator indicator--active"></div>
                <div v-else class="indicator"></div>
            </Transition>
        </template>
    </div>
</template>

<script setup>
import { AudioRecorder } from "/js/AudioRecorder.js";
import { encodeAudio, transcribeRecording } from "/js/transcription.js";
import VoiceLevels from "~vue/components/VoiceLevels.vue";
import { isInIFrame } from "~vue/utils";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, nextTick, ref, useTemplateRef, watch } from "vue";

import PermissionDialog from "./PermissionDialog.vue";

const LEVELS_ELEMENTS = 24;

const props = defineProps({
    answerErrored: { type: Boolean, default: false },
    answerTimeoutMs: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    currentQuestion: { type: Object, required: true },
    currentQuestionPosition: { type: Number, default: 1 },
    questionsCount: { type: Number, default: 1 },
    hideSteps: { type: Boolean, default: false },
    isCustomContent: { type: Boolean, default: false },
    isQuestionPositionShown: { type: Boolean, default: true },
    isWithinAnswerTimeout: { type: Boolean, default: false },
});

const emit = defineEmits([
    "answer",
    "view-answer",
    "next",
    "more-context",
    "recording-start",
    "recording-stop",
    "recording-level",
    "transcribe-start",
    "transcribe-end",
    "transcribe-error",
    "using-text",
    "silence",
]);

const { $sendEvent } = inject("globalProperties");

const STATE = {
    QUESTION: "question",
    RECORDING: "recording",
    TRANSCRIBING: "transcribing",
    ANSWERED: "answered",
};

const supportsVoice = ref(false);
const usingText = ref(false);
const text = ref("");
const textareaRef = useTemplateRef("textareaRef");
const cardStackRef = useTemplateRef("cardStackRef");
const state = ref(STATE.QUESTION);
const showMicPermissionAide = ref(false);
const recorder = ref(null);
const levelHistory = ref([]);
const transcriptionError = ref(null);

const questionAnswer = computed(() => {
    if (props.currentQuestion.offTopic) {
        return "Off-topic conversation.";
    }

    return props.currentQuestion.answer;
});

const nextText = computed(() => {
    if (props.currentQuestionPosition === props.questionsCount) {
        return "Next";
    }
    return "Next question";
});

const makeComputedState = (states) => computed(() => states.includes(state.value));
const isRecording = makeComputedState([STATE.RECORDING]);
const isTranscribing = makeComputedState([STATE.TRANSCRIBING]);
const isAnswering = makeComputedState([STATE.QUESTION, STATE.RECORDING, STATE.TRANSCRIBING]);

const isAnsweredWithoutTimeout = computed(() => !isAnswering.value && !props.isWithinAnswerTimeout);

const cardClass = computed(() => {
    if (isAnswering.value) {
        return "stacked-grid-item--question";
    }

    const classNames = [];

    if (isAnsweredWithoutTimeout.value) {
        classNames.push("stacked-grid-item--answer-mobile");
    }

    if (props.currentQuestion.offTopic) {
        classNames.push("stacked-grid-item--offtopic");
    } else {
        classNames.push("stacked-grid-item--answer");
    }

    return classNames.join(" ");
});

watch(usingText, (value) => {
    if (value && textareaRef.value) {
        emit("using-text");
        nextTick(() => textareaRef.value.focus());
    }
});

watch(
    () => props.currentQuestion,
    () => {
        state.value = STATE.QUESTION;
        text.value = "";

        if (usingText.value) {
            nextTick(() => textareaRef.value?.focus());
        }
    },
);

watch(
    () => props.answerErrored,
    (value) => {
        if (value) {
            handleErrorFallback();
        }
    },
);

watch(transcriptionError, (value) => {
    if (value) {
        handleErrorFallback();
    }
});

function handleErrorFallback() {
    state.value = STATE.QUESTION;
    usingText.value = true;
}

function handleDialogCancel() {
    showMicPermissionAide.value = false;
    usingText.value = true;
    logUserInteraction("use_text", {});
}

function wait(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

async function startRecording({ append = false } = {}) {
    recorder.value = new AudioRecorder({ levelCheckInteralMs: 16 });
    recorder.value.on("level", handleRecorderLevel);
    recorder.value.on("finish", (data) => handleRecorderFinished({ ...data, append }));
    recorder.value.on("start", handleRecorderStart);
    recorder.value.on("stop", handleRecorderStop);
    recorder.value.on("silence", handleSilence);

    try {
        let micPrompt = false;
        await recorder.value.start(async (micPermissionState) => {
            if (micPermissionState === "granted") {
                supportsVoice.value = true;
            } else if (micPermissionState === "prompt" || isInIFrame()) {
                showMicPermissionAide.value = true;
                micPrompt = true;
                await wait(2000);
            }
        });
        if (micPrompt) {
            logUserInteraction("allowed_mic", {});
        } // User allowed mic after prompt if it doesn't error above
    } catch (e) {
        supportsVoice.value = false;
        usingText.value = true;
        logUserInteraction("use_text", {});
    } finally {
        if (showMicPermissionAide.value) {
            showMicPermissionAide.value = false;
        }
    }
}

function stopRecording() {
    recorder.value.stop();
}

function cancelRecording() {
    recorder.value.stop({ abort: true });
    state.value = STATE.QUESTION;
    emit("recording-stop");
}

function handleText() {
    emit("answer", {
        answer: text.value,
        append: false,
        onFinish: () => (state.value = STATE.ANSWERED),
        usingMic: false,
    });
}

function handleRecorderStart() {
    transcriptionError.value = null;
    state.value = STATE.RECORDING;
    emit("recording-start");
}

async function handleRecorderFinished({ blob, append }) {
    recorder.value = null;
    levelHistory.value = [];
    state.value = STATE.TRANSCRIBING;

    const encodedAudio = await encodeAudio(blob);
    emit("transcribe-start");
    try {
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
        });
        /*
         * Persist transcript as text answer in case there is an upstream
         * error when submitting answer.
         */
        text.value = transcript;

        emit("answer", {
            answer: transcript,
            append,
            onFinish: () => (state.value = STATE.ANSWERED),
            usingMic: true,
        });
    } catch {
        const error = new Error("Failed to transcribe onboarding question answer");
        emit("transcribe-error");
        transcriptionError.value = error;
        logError(error);
    } finally {
        emit("transcribe-end");
    }
}

function handleRecorderStop() {
    emit("recording-stop");
}

function handleSilence() {
    emit("silence");
}

function handleNextQuestion() {
    emit("next");
}

function handleMoreContext() {
    emit("more-context");

    if (usingText.value) {
        state.value = STATE.QUESTION;
        textareaRef.value?.focus();
    } else {
        startRecording({ append: true });
    }

    logUserInteraction("give_more_context_clicked", {});
}

function handleRecorderLevel(level) {
    levelHistory.value.push(level);
    emit("recording-level", { level, isBelowSilenceThreshold: recorder.value.isBelowSilenceThreshold(level) });
}

function isAtStep(stepNumber) {
    /*
     * The amount of progress indicators is the total amount
     * of questions multiplied by two, as each question answer has its own
     * step. To derive which step the user's we only have to check whether
     * the state of the card is in the answer state. If so, we can simply
     * multiply the current question position times two. If the card is not
     * in the answer state, we substract 1 for the answer step.
     */

    if (state.value === STATE.ANSWERED) {
        return stepNumber === props.currentQuestionPosition * 2;
    } else {
        return stepNumber === props.currentQuestionPosition * 2 - 1;
    }
}

function handleMicSelection(id) {
    recorder.value?.setAudioInputDeviceId(id);
    startRecording();
}

defineExpose({ cardStackRef, handleMicSelection, cancelRecording });
</script>

<style scoped>
.button-timer {
    @apply !bg-transparent z-20 relative overflow-hidden;
}

.button-timer::before {
    @apply absolute top-0 left-0 w-0 h-full bg-black/40;
    z-index: -1;

    content: "";
    animation-name: fill-horizontal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    /* Make the animation duration slightly shorter than the timeout duration to give the animation
    * a chance to complete */
    animation-duration: v-bind((answerTimeoutMs - 500) + "ms");
}

@keyframes fill-horizontal {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.stacked-grid {
    @apply grid place-items-center max-w-md mx-auto justify-stretch w-full;
    grid-template-areas: "stacked";
}

.stacked-grid-item {
    @apply border-2 px-3 md:px-6 py-5 md:py-10 rounded-2xl flex flex-col gap-3 md:gap-6 transition-all duration-300 ease-in-out text-center w-full;
    grid-area: stacked;
}

.stacked-grid-item:first-child {
    @apply z-20;
}

.stacked-grid-item:not(:last-child) {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.02);
}

.stacked-grid-item--question {
    @apply bg-white border-[#F5F5F5] gap-6;
}

.stacked-grid-item--answer,
.stacked-grid-item--offtopic {
    @apply gap-10;
}

.stacked-grid-item--answer {
    @apply bg-[#F0F5FF] border-[#E0E9FF];
}

@media (max-width: 767px) {
    .stacked-grid-item--answer-mobile {
        @apply !bg-transparent !p-0 !shadow-none !border-0;
    }
}

.stacked-grid-item--offtopic {
    @apply bg-[#F5F5F5] border-[#E8E8E8];
}

.indicator {
    @apply h-1 w-1;
}

.indicator:not(.indicator--active) {
    @apply bg-[#BFBFBF] rounded-full;
}

.indicator--active {
    @apply bg-[#8C8C8C] mx-2 origin-center;
    transform: scaleX(6);
    border-radius: 0.75px;
}

.child-focus:has(textarea:focus) {
    border-color: #555ba2;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: scaleY(0);
}

.expand-enter-active,
.expand-leave-active {
    transition: transform 0.3s ease;
}

.expand-enter-from,
.expand-leave-to {
    transform: scaleX(1);
}
</style>
