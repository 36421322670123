<!-- NOTE: chunks of code here have been commented out to hide the coaching style select until it is ready to ship from the ai quality side -->
<template>
    <div class="flex flex-col items-center justify-end text-center gap-10 antialiased mb-10 md:mb-24 min-h-[45dvh]">
        <div>
            <p class="font-semibold text-[#8C8C8C] text-base md:text-2xl mb-3 tracking-tight">{{ dateToday }}</p>
            <h3 class="text-2xl md:text-5xl font-semibold tracking-tighter">{{ greetMessageWithUser }}</h3>
            <h3 class="text-2xl md:text-5xl font-semibold tracking-tighter text-[#8C8C8C]">How can I help you?</h3>
        </div>
        <!-- <button
            class="flex items-center gap-2 w-fit font-semibold border-2 rounded-full py-3 px-6"
            :class="{ 'bg-white border-[#E8E8E8]': !isShowingDialog, 'bg-[#F7FAFF] border-[#B8C9FF] text-[#555BA2]': isShowingDialog }"
            @click="isShowingDialog = !isShowingDialog"
        >
            <div class="h-6 w-6">
                <CoachingModeMarble />
            </div>
            {{ coachingStyleSelectorText }}
            <i class="bi bi-chevron-up iconBold transition-all" :class="{ 'rotate-180': isShowingDialog }"></i>
        </button>
        <Transition name="slide-down">
            <CoachingModeDialog v-if="isShowingDialog" inline @cancel="isShowingDialog = false" />
        </Transition> -->
    </div>
</template>

<script setup>
import { humanizedTimeOfDay } from "/js/dateUtils";
// import CoachingModeDialog from "~vue/CoachingModeDialog.vue";
// import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
// import { COACHING_STYLES, useChatStore } from "~vue/stores/chatStore";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import {
    computed,
    // ref
} from "vue";

const { name } = defineProps({
    name: { type: String, default: "" },
});

// const isShowingDialog = ref(false);
const dateToday = DateTime.now().toFormat("LLL dd, yyyy");
const currentTimeOfDay = capitalize(humanizedTimeOfDay());
const greetMessageWithUser = computed(() => `Good ${currentTimeOfDay},${name ? ` ${name}` : ""}`);

// coaching style starts as "Coaching Style" and updates to the current coaching mode sets the coaching mode
// const coachingStyleSelectorText = computed(() => COACHING_STYLES[useChatStore.coachingMode]);
</script>

<style scoped>
/* tailwind screen() function for css media queries */
/* @media screen(md) {
    .slide-down-enter-active,
    .slide-down-leave-active {
        transition:
            opacity 0.25s ease-in-out,
            transform 0.25s ease-in-out;
    }

    .slide-down-leave-to,
    .slide-down-enter-from {
        opacity: 0;
        transform: translateY(-10%);
    }
    .slide-down-leave-from,
    .slide-down-enter-to {
        opacity: 1;
        transform: translateY(0);
    }

    .iconBold {
        -webkit-text-stroke: 1px;
    }
} */
</style>
