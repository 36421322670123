<template>
    <Transition name="fade">
        <ChatWaitingMessage v-if="shown">
            <template v-for="(message, idx) in loadingMessageStack" :key="idx">
                <Transition name="fade">
                    <span v-if="run && messageIndex === idx">
                        {{ message }}
                    </span>
                </Transition>
            </template>
        </ChatWaitingMessage>
    </Transition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";

import ChatWaitingMessage from "./ChatWaitingMessage.vue";

// How long to show a message in the cycle for
const MESSAGE_DURATION_MS = 800;

/* How long to hide the text between cycles. Used to
 * animate the transition in-out of each message in the stack.
 * This should be approximately twice the transition length
 * set in the "fade" CSS in the styles at the end of this file.
 */
const TRANSITION_DURATION_MS = 500;

const { loadingMessageStack } = defineProps({
    loadingMessageStack: {
        type: Array,
        default: () => ["One moment while I gather some context..."],
    },
});
const emit = defineEmits(["complete"]);

const shown = ref(false);
const run = ref(true);
const messageIndex = ref(0);
const timeout = ref(null);

onMounted(() => {
    /*
     * We use setTimeout recursively rather than using setInterval to cycle
     * between the messages. The reason for this is that for the fade
     * transition to work as expected, the previous message needs to be unmounted
     * before the next message is mounted. Otherwise, both messages are
     * shown temporarily as the previous message fades out and the next on fades in.
     *
     * We use `run` to control this showing and hiding between messages.
     *
     * The duration of the transition (TRANSITION_DURATION_MS) should be
     * more or less twice the amount of the ms value used in the transition
     * CSS property at the end of this file. This is the duration of the gap
     * in which no message is shown so that the previous and next messages
     * transition correctly.
     *
     * Additionally, when the loading message runs to completion, the
     * entire element is faded out before emitting the completion event.
     *
     * All in all, it looks more or less like this:
     *
     * c | a | b | a | b | a | c
     *
     * Where:
     *  a is "show message at index for X seconds"
     *  b is "not show any message for Y milliseconds"
     *  c is "transition entire element in or out"
     *
     * If we suddenly want to change this feature as to not wait till the
     * load message runs to completion but instead short circuit it, we
     * should still try to do so at the "b" interval and keep "c" to avoid jitter
     * resulting from abruptly switching the UI.
     */
    const start = () => {
        timeout.value = window.setTimeout(() => {
            if (messageIndex.value === loadingMessageStack.length - 1) {
                /*
                 * We've reached the end of the loading message stack. Stop
                 * cycling, hide the element altogether, and emit the
                 * completion event at a timeout so that the element can
                 * fade out gracefully. This makes for seemless
                 * transitioning between the loading state and not-loading
                 * state.
                 */
                window.clearTimeout(timeout.value);
                timeout.value = null;

                // End sequence, fade out the entire element.
                shown.value = false;
                window.setTimeout(complete, TRANSITION_DURATION_MS);
                return;
            }

            /*
             * Stop showing a message for an interval so that the current
             * message fades out. Cycle to the next message and resume at a
             * timeout.
             */
            run.value = false;
            messageIndex.value++;

            window.setTimeout(() => {
                run.value = true;
                start();
            }, TRANSITION_DURATION_MS);
        }, MESSAGE_DURATION_MS);
    };

    // Begin sequence, fade in the entire element.
    shown.value = true;
    window.setTimeout(start, TRANSITION_DURATION_MS);
});
onUnmounted(() => {
    if (timeout.value !== null) {
        window.clearTimeout(timeout.value);
    }
});

const complete = () => emit("complete");
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
