<template>
    <BaseNotification theme="primary" @dismiss="handleDismiss">
        <template #content>
            <div class="overflow-hidden">
                <p class="font-semibold mb-4">Pick up where you left off!</p>
                <div class="w-full flex flex-row items-left gap-4 overflow-y-auto">
                    <div v-for="focusArea in data.action_params.focus_areas" :key="focusArea.title" class="flex flex-col gap-2">
                        <p class="text-left capitalize">{{ focusArea.title }}</p>
                        <p class="font-medium text-left">
                            <span>{{ focusArea.percentage_completed }}% Complete</span>
                        </p>
                        <div>
                            <BaseButton theme="primary" @click="selectFocusArea(focusArea)">Continue</BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </BaseNotification>
</template>

<script setup>
import { dismissAction } from "~vue/chatActions";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseNotification from "~vue/components/BaseNotification.vue";
import { defineProps, inject } from "vue";

import { CHAT_EVENT } from "./events";

const { emitter } = inject("globalProperties");

const { data, lineIdx, messageId } = defineProps({
    data: { type: Object, default: () => ({}) },
    lineIdx: { type: Number, required: true },
    messageId: { type: Number, required: true },
});

const selectFocusArea = (focusArea) => {
    window.location.href = focusArea.url;
};

const handleDismiss = () => {
    dismissAction({ emitter, lineIdx, messageId, line, eventName: CHAT_EVENT.FOCUS_WIDGET });
};
</script>
