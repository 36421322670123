import _ from "lodash";
import mitt from "mitt";

/**
 * Unless otherwise specified in the constructor, this is how frequently to check the current audio
 * level, in millisconds.
 */
const DEFAULT_LEVEL_CHECK_INTERVAL_MS = 100;

/**
 * Unless otherwise specified in the constructor, this the audio level threshold above which a given
 * recording will no longer be considered "silence".  Expressed as a number between 0 and 1.
 */
const DEFAULT_SILENCE_THRESHOLD = 0.1;

/**
 * Unless otherwise specified in the constructor, the 'silence' event will be triggered after this
 * many seconds of silence from the time the recording is started.
 */
const DEFAULT_SILENCE_TIMEOUT_SECS = 5;

/** Minimal percentage of audible audio in recorded sample. 2% can be sufficient signal. A very paused speech will average 5-10%. */
const DEFAULT_AUDIBLE_THRESHOLD_PERCENTAGE = 0.02;

/**
 * Unless otherwise specified in the constructor, recordings will stop automatically after this many
 * seconds have elapsed.
 */
const DEFAULT_MAX_RECORDING_TIME_SECS = 60 * 10;

/** Converts a 0-255 uint8 level centered on 127 to a 0-128 absolute value level. */
const absLevel = (level) => (level & 128 ? (level & 127) + 1 : 127 - level);

/** Convenience wrapper around the browser interface for audio recording. */
export class AudioRecorder {
    /**
     * Constructs a new AudioRecorder instance, which is a convenience wrapper around the browser's
     * audio streaming interface.
     *
     * @param options.audioInputDeviceId The audio device to use for recording.  If unspecified, the
     * default audio device from the browser will be used.
     * @param options.levelCheckIntervalMs The number of milliseconds between volume level checks.
     * Defaults to 100 ms.
     * @param options.maxRecordingTimeSecs The maximum number of seconds to record for.  Defaults to
     * 10 minutes.
     * @param options.silenceThreshold For purposes of detecting "silent" recordings, audio levels
     * below this threshold will be considered silence, on a scale from 0 to 1.  Defaults to 0.1.
     * @param options.silenceTimeoutSecs If this many seconds of silence pass by after the recording
     * starts, the 'silence' event will be triggered.  Defaults to 5 seconds.
     * @param options.audibleThresholdPercentage Minimal percentage of audible in recorded sample
     */
    constructor(options = {}) {
        this._emitter = mitt();
        this._levelCheckIntervalMs = options.levelCheckIntervalMs ?? DEFAULT_LEVEL_CHECK_INTERVAL_MS;
        this._silenceThreshold = options.silenceThreshold ?? DEFAULT_SILENCE_THRESHOLD;
        this._maxRecordingTimeSecs = options.maxRecordingTimeSecs ?? DEFAULT_MAX_RECORDING_TIME_SECS;
        this._audioInputDeviceId = options.audioInputDeviceId ?? null;
        this._silenceTimeoutSecs = options.silenceTimeoutSecs ?? DEFAULT_SILENCE_TIMEOUT_SECS;
        this._audibleThresholdPercentage = options.audibleThresholdPercentage ?? DEFAULT_AUDIBLE_THRESHOLD_PERCENTAGE;

        // It's OK to call this synchronously here because the async bits won't be called on a new
        // instance.
        this._reset();
    }

    async _reset() {
        this._isRecording = false;
        this._isSilent = true;
        this._isAborted = false;
        this._startTime = null;
        this._endTime = null;
        this._currentLevel = 0;
        this._levelsHistory = [];
        this._chunks = [];
        this._lastLevelCheckTime = null;
        this._maxTimeTimeout = null;
        this._levelsArray = null;
        this._mimeType = null;

        this._stream = null;
        this._recorder = null;

        if (this._silenceTimeout) {
            clearTimeout(this._silenceTimeout);
            this._silenceTimeout = null;
        }

        if (this._maxTimeTimeout) {
            clearTimeout(this._maxTimeTimeout);
            this._maxTimeTimeout = null;
        }

        if (this._checkAudioLevelTimeout) {
            clearTimeout(this._checkAudioLevelTimeout);
            this._checkAudioLevelTimeout = null;
        }

        if (this._analyzer) {
            this._analyzer.disconnect();
        }
        this._analyzer = null;

        if (this._source) {
            this._source.disconnect();
        }
        this._source = null;

        if (this._context) {
            await this._context.close();
        }
        this._context = null;
    }

    /**
     * Starts a new recording.
     *
     * Recording may not start immediately.  To ensure audio is actually being recorded, either
     * `await` this function, or subscribe to the `start` event.
     *
     * @param {function} failureCallback(micPermission.state) A callback to be called if the recording fails to start due to microphone permissions. The argument is the state of the microphone permission.
     */
    async start(failureCallback = () => null) {
        if (this._isRecording) {
            console.warn("Called AudioRecorder start() while already recording");
            return;
        }

        if (!navigator.mediaDevices?.getUserMedia) {
            throw new Error("Could not start recording: no user media devices");
        }

        await this._reset();

        try {
            const micPermission = await navigator.permissions.query({ name: "microphone" });
            if (micPermission.state !== "granted") {
                await failureCallback(micPermission.state);
            }
        } catch (e) {
            // Firefox does not support navigator.permissions.query
        }

        try {
            this._stream = await navigator.mediaDevices.getUserMedia({
                audio: this._audioInputDeviceId ? { deviceId: this._audioInputDeviceId } : true,
            });
        } catch (err) {
            console.error("AudioRecorder failed to get media stream", err);
            throw err;
        }

        this._context = new AudioContext();
        this._source = this._context.createMediaStreamSource(this._stream);
        this._analyzer = this._context.createAnalyser();
        this._levelsArray = new Uint8Array(this._analyzer.fftSize);
        this._source.connect(this._analyzer);

        try {
            this._recorder = new MediaRecorder(this._stream);
        } catch (err) {
            console.error("AudioRecorder could not instantiate MediaRecorder", err);
            throw err;
        }

        this._dataAvailableListener = this._recorder.addEventListener("dataavailable", (e) => this._handleDataAvailable(e));
        this._mimeType = this._recorder.mimeType;

        this._isRecording = true;
        this._startTime = new Date();
        this._recorder.start();

        this._lastLevelCheckTime = new Date();
        this._checkAudioLevelTimeout = setTimeout(() => this._checkAudioLevel(), this._levelCheckIntervalMs);
        this._silenceTimeout = setTimeout(() => this._checkSilence(), this._silenceTimeoutSecs * 1000);
        this._maxTimeTimeout = setTimeout(() => this._handleMaxTimeTimeout(), this._maxRecordingTimeSecs * 1000);

        this._emitter.emit("start");
    }

    /**
     * Stops the active recording.  Even though recording has stopped, audio data will not be
     * available right away.  To get the recorded audio, listen for the subsequent `finish` event.
     *
     * @param options.abort If `true`, recorded audio will be discarded and no `finish` event will
     * be triggered.  Defaults to `false`.
     */
    stop(options = {}) {
        if (!this._isRecording) {
            console.warn("Called AudioRecorder stop() while not recording");
            return;
        }

        this._isRecording = false;

        this._isAborted = options.abort ?? false;
        if (this._isAborted) {
            this._startTime = null;

            if (this._dataAvailableListener) {
                this._recorder?.removeEventListener("dataavailable", this._dataAvailableListener);
                this._dataAvailableListener = null;
            }
        }

        if (this._silenceTimeout) {
            clearTimeout(this._silenceTimeout);
            this._silenceTimeout = null;
        }

        if (this._maxTimeTimeout) {
            clearTimeout(this._maxTimeTimeout);
            this._maxTimeTimeout = null;
        }

        if (this._checkAudioLevelTimeout) {
            clearTimeout(this._checkAudioLevelTimeout);
            this._checkAudioLevelTimeout = null;
        }

        if (this._recorder) {
            this._recorder.stop();
            this._recorder.stream.getTracks().forEach((track) => track.stop());
        }

        this._endTime = new Date();

        this._emitter.emit("stop", { isAborted: this._isAborted, isTimeout: !!options.isTimeout });
    }

    async _handleDataAvailable({ data }) {
        if (this._isAborted) {
            return;
        }

        this._chunks.push(data);

        this._emitter.emit("data-available", {
            data,
            mimeType: this._mimeType,
            isFinal: !this._isRecording,
        });

        if (this._isRecording) {
            return;
        }

        this._recorder?.removeEventListener("dataavailable", this._dataAvailableListener);
        this._recorder = null;
        this._stream = null;

        const recording = new Blob(this._chunks, { type: this._mimeType });

        const audioIsAudible = await this._checkAboveAudiblePercentage(recording);
        if (audioIsAudible) {
            this._emitter.emit("finish", { blob: recording, lengthMs: this.recordingTimeMs });
        } else {
            this._emitter.emit("silence");
        }
    }

    _checkAudioLevel() {
        if (!this._isRecording) {
            return;
        }

        this._analyzer.getByteTimeDomainData(this._levelsArray);
        const peakLevel = _.maxBy(this._levelsArray, absLevel);

        this._currentLevel = absLevel(peakLevel) / 128.0;
        this._levelsHistory.push(this._currentLevel);

        if (this._isSilent && this._currentLevel >= this._silenceThreshold) {
            this._isSilent = false;
        }

        const now = new Date();
        const nextCheckDelay = this._levelCheckIntervalMs - (now - this._lastLevelCheckTime - this._levelCheckIntervalMs);
        setTimeout(() => this._checkAudioLevel(), nextCheckDelay < 0 ? 0 : nextCheckDelay);
        this._lastLevelCheckTime = now;

        this._emitter.emit("level", this._currentLevel);
    }

    _checkSilence() {
        if (!this.isRecording) {
            return;
        }

        if (this._isSilent) {
            this._emitter.emit("silence");
        }
    }

    /** Sample whole recording for percentage of audible sounds */
    async _checkAboveAudiblePercentage(audioBlob) {
        try {
            const arrayBuffer = await audioBlob.arrayBuffer();
            const audioBuffer = await this._context.decodeAudioData(arrayBuffer);
            const channelData = audioBuffer.getChannelData(0);

            const audiblePercentage = channelData.reduce((acc, sample) => (Math.abs(sample) > this._silenceThreshold ? acc + 1 : acc), 0) / channelData.length;

            return audiblePercentage >= this._audibleThresholdPercentage;
        } catch (error) {
            console.error("Error analyzing audio:", error);
            throw error;
        }
    }

    _handleMaxTimeTimeout() {
        if (!this.isRecording) {
            return;
        }

        this.stop({ isTimeout: true });
    }

    /**
     * Adds an event handler to this instance for a given event.
     *
     * Events:
     * - `start` - Published when recording is started.
     * - `level` - Published whenever new volume level data is available.  The argument is the
     * current volume level, on a scale from 0 to 1.
     * - `silence` - Published if `silenceTimeoutSecs` seconds have elapsed since the recording
     * started and the recording so far only contains silence.
     * - `data-available` - Published multiple times during a recording whenever a chunk of audio
     * data becomes available.  You can handle this event to receive chunks of audio data as the
     * recording progresses, or you can just wait for the final `finish` event to get everything at
     * once.  The argument is an object with three fields: `data`, `mimeType`, and `isFinal`.  If
     * `isFinal` is `true`, then recording has stopped and there will be no further `data-available`
     * events for this recording.
     * - `stop` - Published when a recording is stopped for any reason.  The argument is an object
     * with two fields: `isAborted` indicates that the recording was aborted, while `isTimeout`
     * indicates that the recording stopped due to a timeout.  Audio data is not immediately
     * available after a recording stops.  If you want the audio data, listen for the subsequent
     * `finish` event.
     * - `finish` - Published when a recording has completed and the audio data is available.  The
     * argument is an object with two properties: `blob` is a `Blob` containing the audio data, and
     * `lengthMs` is the length of the recording in ms.  Note that this event will not be published
     * if the recording was aborted.
     */
    get on() {
        return this._emitter.on;
    }

    /** Removes an event handler for a given even. */
    get off() {
        return this._emitter.off;
    }

    /** True if this instance is actively recording. */
    get isRecording() {
        return this._isRecording;
    }

    /** True if the active (or most recently completed) recording contains only silence. */
    get isSilent() {
        return this._isSilent;
    }

    /** The length of the current or most recent recording, in ms. */
    get recordingTimeMs() {
        if (!this._startTime) {
            return 0;
        }
        if (!this._endTime) {
            return new Date() - this._startTime;
        }
        return this._endTime - this._startTime;
    }

    /** The current audio level, on a scale from 0 to 1. */
    get level() {
        if (!this._isRecording) {
            return 0;
        }
        return this._currentLevel;
    }

    /** An array of previous audio levels, on a scale from 0 to 1. */
    get levelHistory() {
        // Using slice() here returns a shallow copy of the array so that it doesn't change while
        // callers are using it.  Stole this from mitt.  :)
        return this._levelsHistory.slice();
    }

    /** The time-length of each entry in `levelHistory`, in milliseconds. */
    get levelHistoryIntervalMs() {
        return this._levelCheckIntervalMs;
    }

    /** The MIME type of the current or most recent recording. */
    get mimeType() {
        return this._mimeType;
    }

    /**
     * Sets the device ID for audio recording.  This change does not take effect until the next
     * recording is started.
     */
    setAudioInputDeviceId(id) {
        this._audioInputDeviceId = id;
    }

    isBelowSilenceThreshold(level) {
        return level <= this._silenceThreshold;
    }
}

/**
 * Formats a time duration from milliseconds to mm:ss or hh:mm:ss format.
 *
 * @param {number} duration
 * @returns The time duration in mm:ss or hh:mm:ss format.
 */
export function msToDisplayTime(duration) {
    // Split
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    // Zero-pad
    let hours_str = hours < 10 ? "0" + hours : hours;
    let minutes_str = minutes < 10 ? "0" + minutes : minutes;
    let seconds_str = seconds < 10 ? "0" + seconds : seconds;

    if (hours > 0) {
        return hours_str + ":" + minutes_str + ":" + seconds_str;
    }
    return minutes_str + ":" + seconds_str;
}
