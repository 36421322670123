<template>
    <div role="alert" class="rounded-xl py-3 px-6 border-2 antialiased gap-4 relative pr-10" :class="containerClass[theme]">
        <slot name="content">
            <MarkdownText v-if="text" class="text-sm md:text-base font-semibold grow" :text="text" />
        </slot>
        <button type="button" title="Dismiss" class="bi bi-x text-2xl absolute top-2 right-2" @click="handleDismiss"></button>
    </div>
</template>

<script setup>
import MarkdownText from "~vue/MarkdownText.vue";

const { theme, text } = defineProps({
    // primary, secondary, info, error
    theme: { type: String, default: "primary" },
    text: { type: String, default: "" },
});

const containerClass = {
    primary: "border-[#E0E9FF] bg-[#F7FAFF] text-[#555BA2]",
    secondary: "border-[#E5BCEF] bg-[#F6E9FA] text-[#722ED1]",
    info: "border-[#FFE58F] bg-[#FFFBE6] text-[#D48806]",
    // error: "border-[] bg-[] text-[]",
};

// emits the dismiss event, which needs to be listened for and handled by the parent
const handleDismiss = () => {
    emit("dismiss");
};

const emit = defineEmits(["dismiss"]);
</script>
