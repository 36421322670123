<template>
    <BaseNotification theme="primary" @dismiss="handleDismiss">
        <template #content>
            <div class="flex gap-2">
                <i class="bi bi-check-circle text-xl" />
                <div class="text-base font-medium">
                    <span>I&apos;ve emailed you an invite for us to check in&nbsp;</span>
                    <span v-if="line.action_params.recurring" class="font-semibold">every {{ eventDay }}.</span>
                    <span v-else
                        >on <span class="font-semibold">{{ eventDay }}.</span>
                    </span>
                    <span>
                        <span>&nbsp; Ready to go?&nbsp;</span>
                        <button type="button" class="inline text-left hover:text-[#555BA2]/75 underline" @click="emitter.emit('wrap_up_chat')">Wrap up this conversation</button>
                        <span>&nbsp;now.</span>
                    </span>
                </div>
            </div>
        </template>
    </BaseNotification>
</template>

<script setup>
import BaseNotification from "~vue/components/BaseNotification.vue";
import { DateTime } from "luxon";
import { computed, defineProps, inject } from "vue";

import { dismissAction } from "./chatActions";

const { line, lineIdx, messageId } = defineProps({
    line: { type: Object, default: () => ({}) },
    lineIdx: { type: Number, required: true },
    messageId: { type: Number, required: true },
});

const { emitter } = inject("globalProperties");

const eventDay = computed(() => DateTime.fromISO(line.action_params.event_at_confirmed).toLocal().toLocaleString({ weekday: "long" }));
const handleDismiss = () => {
    dismissAction({ emitter, lineIdx, messageId, line, eventName: "followup_update" });
};
</script>
