<template>
    <div v-if="showDisclaimer" class="border-2 border-[#E8E8E8] bg-[#FAFAFA] rounded-xl py-2 px-6">
        <MarkdownText v-if="noModal" class="text-sm font-semibold text-[#8C8C8C]" :text="disclaimerMessage" />
        <p v-else class="text-sm font-semibold text-[#8C8C8C]">
            Remember to adhere to <button type="button" class="underline cursor-pointer" @click="openDialog()">company guidelines</button>
        </p>
    </div>
    <BaseDialog v-if="isDialogOpen" @cancel="closeDialog" @close="closeDialog">
        <div class="p-4 min-w-[300px]">
            <button type="button" class="absolute top-2 right-2 p-3 bi bi-x text-2xl" @click="closeDialog()" />
            <h3 class="text-2xl font-semibold tracking-tighter mb-4">Company Disclaimer</h3>
            <div class="mt-2 text-left">
                <MarkdownText class="text-sm md:text-base" :text="disclaimerMessage" />
            </div>
        </div>
    </BaseDialog>
</template>

<script setup>
import { computed, ref } from "vue";

import BaseDialog from "./components/BaseDialog.vue";
import MarkdownText from "./MarkdownText.vue";

const { message } = defineProps({
    message: { type: String, default: "" },
});
const showDisclaimer = computed(() => !!message);

const disclaimerMessage = computed(() => message?.replace("/no-modal/", "").trim());
// disclaimer message coming from organization settings in admin page can optionally have a modal to display longer messages
// when a modal is not necessary, the message will include a "/no-modal/" in the string
const noModal = computed(() => message?.includes("/no-modal/"));

const isDialogOpen = ref(false);
const openDialog = () => (isDialogOpen.value = true);
const closeDialog = () => (isDialogOpen.value = false);
</script>
