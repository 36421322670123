<template>
    <aside
        :class="[
            isOpen ? 'md:flex md:h-screen md:translate-x-0 md:w-[280px]' : 'md:w-0 md:-translate-x-full',
            'relative bg-white border-r border-r-gray-50 w-full shrink-0 flex flex-col items-start overflow-hidden md:h-screen max-h-screen text-valence-grey-800 transition-all duration-300 ease-in-out delay-100',
        ]"
    >
        <div class="hidden md:flex shrink-0 flex-row items-center justify-between md:p-[unset] md:pt-5 md:px-5 p-5 w-full">
            <span class="max-w-[100px] md:max-w-[150px]">
                <OrganizationLogo />
            </span>
            <div class="flex flex-row items-center gap-4">
                <button class="md:hidden" type="button" title="Menu" data-cy="menu-hamburger-button" @click="$emit('toggle')">
                    <span v-show="isOpen" class="w-5 h-5 text-current bi bi-x-lg"></span>
                    <svg v-show="!isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-5 h-5 stroke-current">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="!$userDetails.value.is_anonymous" :class="!isOpen ? '' : '!md:flex'" class="mt-2 md:mt-10 grow hidden md:flex overflow-y-auto w-full h-full">
            <div v-if="pane" class="overflow-hidden flex flex-col grow">
                <div class="border-b border-b-gray-100 shrink-0 px-5 pb-2">
                    <button type="button" title="Return" class="text-base hover:text-valence-grey-800/80" @click="clearPane">
                        <span class="bi bi-chevron-left mr-1"></span>
                        <span v-text="pane.title"></span>
                    </button>
                </div>
                <div class="grow overflow-hidden flex flex-col bg-[#DFDFDF]/20">
                    <div v-if="loading" class="flex p-4 w-full justify-center">
                        <div class="w-8 h-8 text-gray-400">
                            <LoadingSpinner />
                        </div>
                    </div>
                    <div v-else-if="error">
                        <p class="p-4 text-sm">Failed to load information. Please try again later.</p>
                    </div>
                    <div v-else-if="paneComponent" class="py-4 px-5 flex flex-col grow overflow-y-auto">
                        <component :is="paneComponent.component" v-bind="paneComponent.props" />
                    </div>
                </div>
            </div>
            <!-- Use v-show instead of v-if to keep MainMenu's internal state -->
            <MainMenu v-show="!pane" :enabled-panes="mainMenuPanes" @open-pane="openPane" />
        </div>

        <MobileMainMenu @open-pane="openPane" />
        <SettingsMenu ref="settingsMenuRef" :is-open="isOpen" @open-pane="openPane" />
    </aside>
</template>

<script setup>
import { PANE_DATA } from "~vue/components/navigation/MainMenu.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { useSideBarStore } from "~vue/stores/sideBarStore";
import { computed, onMounted, ref, watch } from "vue";

import OrganizationLogo from "../OrganizationLogo.vue";
import MainMenu, { MAIN_MENU_PANE } from "./MainMenu.vue";
import MobileMainMenu from "./MobileMainMenu.vue";
import SettingsMenu from "./SettingsMenu.vue";

defineProps({
    isOpen: Boolean,
});

defineEmits(["toggle"]);

const disabledPanes = ref([MAIN_MENU_PANE.JOURNEYS]);
const settingsMenuRef = ref(null);
const pane = ref(null);
const paneComponent = ref(null);
const error = ref(null);
const loading = ref(false);

const mainMenuPanes = computed(() =>
    [MAIN_MENU_PANE.NEW_CHAT, MAIN_MENU_PANE.HOME, MAIN_MENU_PANE.HISTORY, MAIN_MENU_PANE.JOURNEYS, MAIN_MENU_PANE.PROFILE].filter((p) => !disabledPanes.value.includes(p)),
);

function clearPane() {
    pane.value = null;
}

const paneKey = computed(() => useSideBarStore.pane);
watch(paneKey, () => {
    if (paneKey.value && PANE_DATA[paneKey.value]) {
        openPane(PANE_DATA[paneKey.value]);
    }
});

async function openPane(p) {
    pane.value = p;

    if (settingsMenuRef.value) {
        settingsMenuRef.value.hidePopover();
    }

    loading.value = true;
    const response = await fetch(`/navigation/?name=${p.id}`);
    const body = await response.json();
    loading.value = false;

    if (!response.ok) {
        error.value = body;
        return;
    }

    paneComponent.value = { component: p.component, props: body };
}

async function validateDisabledPanes() {
    try {
        const panesURI = window.encodeURIComponent(disabledPanes.value);
        const response = await fetch(`/navigation/validate?panes=${panesURI}`);

        if (response.ok) {
            const payload = await response.json();
            disabledPanes.value = disabledPanes.value.filter((p) => payload.disabled_panes.includes(p));
            return;
        }

        throw new Error("Failed to validate disabled navigation panes");
    } catch (e) {
        if ("Sentry" in window) {
            window.Sentry.captureException(e);
        }
    }
}

async function checkHistoryPaneStatus() {
    try {
        const response = await fetch("/navigation/?name=history");
        if (response.ok) {
            const data = await response.json();

            if (data.coaching_sessions.length === 0) {
                if (!disabledPanes.value.includes(MAIN_MENU_PANE.HISTORY)) {
                    disabledPanes.value.push(MAIN_MENU_PANE.HISTORY);
                }
            }
        }
    } catch (error) {
        console.error("Failed to check history pane status:", error);
        if ("Sentry" in window) {
            window.Sentry.captureException(error);
        }
    }
}

onMounted(async () => {
    await checkHistoryPaneStatus();
    await validateDisabledPanes();
});
</script>
